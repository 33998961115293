import { get } from "lodash/fp"
import { t } from "i18next"
import React from "react"
import Select from "app/common/formFields/Select"
import * as enums from "app/utils/enums"

function EnumSelect({ enum: enumName, ...props }) {
  const enumObj = enums[enumName]

  const format = key => ({
    label: t(enumObj[key]),
    value: key
  })

  const options = Object.keys(enumObj).map(format)

  return (
    <Select {...props} format={format} parse={get("value")} options={options} />
  )
}

export default EnumSelect

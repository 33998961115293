export default {
  fields: {
    id: "#",
    analysisType: "Tipo de Análise",
    author: "Autor",
    cardImage: "Imagem do Cartão",
    categories: "Categorias",
    cities: "Cidades",
    events: "Eventos",
    genders: "Gêneros",
    generalTrendType: "Tipo de Direcionamento de Estilo",
    megaTrendType: "Tipo de Previsões de Tendência",
    niches: "Nichos",
    publicationDate: "Data de Publicação",
    season: "Temporada",
    segments: "Segmentos",
    subtitle: "Subtítulo",
    technicalAreaType: "Tipo de Áreas Técnicas",
    title: "Título",
    topics: "Tópicos",
    tradeShowType: "Tipo de Feira",
    trends: "Em alta",
    product: "Produto",
    print: "Estampa"
  },
  createByCopying: "Fazer uma cópia",
  editor: {
    openEditor: "Abrir o Editor de Publicação",
    topToolbar: {
      title: "Título",
      colorChip: "Inserir Color Chip",
      imageGrid: "Inserir Grade de Imagens",
      techPack: "Inserir Tech Pack Download",
      video: "Inserir vídeo"
    },
    inlineToolbar: {
      linkPlaceholder: "Cole ou digite o link..."
    },
    contentTypes: {
      colorChip: {
        dialog: {
          title: "Color Chip"
        },
        fields: {
          description: "Descrição",
          color: "Cor"
        }
      },
      imageGrid: {
        types: {
          grid1x1: "1 imagem",
          grid1x2: "2 imagens",
          grid1x3: "3 imagens",
          grid1x4: "4 imagens",
          specialGrid1: "Grade Especial 1",
          specialGrid2: "Grade Especial 2"
        },
        image: {
          chooseImage: "Escolher imagem",
          changeImage: "Alterar imagem"
        },
        imageIdField: {
          label: "Imagem Id",
          submit: "Selecionar",
          error: "Imagem com ID selecionado não encontrada"
        },
        dialog: {
          selectGridType: {
            title: "Grade de Imagens | Selecionar Tipo"
          },
          imageGrid: {
            title: "Grade de Imagens"
          },
          selectImage: {
            title: "Grade de Imagens | Selecionar Imagem"
          },
          cropImage: {
            title: "Grade de Imagens | Cortar Imagem",
            mirrorHorizontally: "Inverter horizontalmente"
          },
          selectImageFolder: {
            title: "Grade de Imagens | Selecionar Pasta de Imagens",
            showImagesButton: "Imagens"
          }
        }
      },
      techPack: {
        dialog: {
          title: "Tech Pack Download"
        },
        fields: {
          description: "Descrição",
          source: "URL"
        }
      },
      video: {
        dialog: {
          title: "Vídeo"
        },
        fields: {
          url: "URL"
        }
      }
    }
  }
}

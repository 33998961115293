import React, { Component } from "react"
import Cropper from "cropperjs"

class Crop extends Component {
  state = {
    source: null
  }

  static getDerivedStateFromProps({ file }, { prevFile, source }) {
    if (file !== prevFile) {
      if (source) {
        URL.revokeObjectURL(source)
      }

      return {
        prevFile: file,
        source: file && URL.createObjectURL(file)
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.source !== this.state.source) {
      if (this.state.source) {
        this.cropper.replace(this.state.source)
      } else {
        this.cropper.destroy()
        this.cropper = null
      }
    }

    if (prevProps.aspectRatio !== this.props.aspectRatio) {
      this.updateAspectRatio()
    }
  }

  componentWillUnmount() {
    if (this.state.source) {
      URL.revokeObjectURL(this.state.source)
    }

    if (this.cropper) {
      this.cropper.destroy()
    }
  }

  getFile = () => {
    if (this.cropper) {
      return new Promise(resolve => {
        this.cropper
          .getCroppedCanvas({
            fillColor: "white",
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high"
          })
          .toBlob(blob => {
            blob.name = this.props.file.name
            resolve(blob)
          }, "image/jpeg")
      })
    }

    return Promise.reject(new Error("no file set"))
  }

  onCanvas = node => {
    if (this.cropper) {
      return
    }

    this.cropper = new Cropper(node, {
      dragMode: "move"
    })

    this.cropper.replace(this.state.source)
    this.updateAspectRatio()
  }

  updateAspectRatio = () => {
    if (this.cropper) {
      if (this.props.aspectRatio) {
        this.cropper.setAspectRatio(this.props.aspectRatio)
      } else {
        this.cropper.setAspectRatio(null)
      }
    }
  }

  render() {
    return this.state.source ? <canvas ref={this.onCanvas} /> : null
  }
}

export default Crop

import React, { Fragment } from "react"
import { t } from "i18next"

import * as validations from "app/utils/validations"
import DateTimeField from "app/common/formFields/DateTime"
import EntitySelectField from "app/common/formFields/EntitySelect"
import ImageFileField from "app/common/formFields/ImageFile"

function Form() {
  return (
    <Fragment>
      <EntitySelectField
        name="tag"
        label={t("recommendedTags.fields.tag")}
        entity="Tag"
        validate={validations.required()}
      />
      <ImageFileField
        aspectRatio={262 / 201}
        initialSourceFieldName="cardImage.source"
        name="cardImageId"
        label={t("recommendedTags.fields.cardImage")}
        validate={validations.required()}
      />
      <DateTimeField
        name="publicationDate"
        label={t("reports.fields.publicationDate")}
      />
    </Fragment>
  )
}

export default Form

import gql from "graphql-tag"

const publishedImagesCountQuery = gql`
  query PublishedImagesCount($startDate: String!, $endDate: String!) {
    publishedImagesCount(startDate: $startDate, endDate: $endDate) {
      totalCount
      TRADESHOW
      CATWALK
      CLIPPING
      SHOWCASE
      VISUAL_MERCHANDISING
      STREET_STYLE
    }
  }
`

export default publishedImagesCountQuery

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import SwitchField from "app/common/formFields/Switch"
import * as validations from "app/utils/validations"
import TranslationsFields from "app/common/TranslationsFields"
import EntitySelectField from "app/common/formFields/EntitySelect"

function Form() {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("trends.fields.name")}
        validate={validations.required()}
      />
      <SwitchField name="archived" label={t("trends.fields.archived")} />
      <EntitySelectField
        isMulti
        name="regions"
        label={t("trends.fields.regions")}
        entity="Region"
      />
      <TranslationsFields i18nBaseKey="trends.fields.name" />
    </Fragment>
  )
}

export default Form
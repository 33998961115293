export default {
  fields: {
    category: "Categoria",
    season: "Temporada",
    brand: "Marca",
    event: "Evento",
    importedBy: "Importado por",
    importState: {
      importing: "Importando",
      imported: "Importado"
    },
    city: "Cidade",
    quantity: "Quantidade",
    publicationDate: "Data de Publicação"
  },
  listPage: {
    title: "Importação WGSN",
    importLabelButton: "Importar",
    categorize: "Categorizar"
  },
  previewModal: {
    title: "Pré-Visualização de Imagens"
  }
}

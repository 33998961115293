import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "TechnicalAreaType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("technicalAreaType", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("technicalAreaTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createTechnicalAreaType",
    "TechnicalAreaTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTechnicalAreaType",
    "TechnicalAreaTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTechnicalAreaType"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTechnicalAreaTypes")
}

import { t } from "i18next"

export default function getRows(data) {
  function addPercentage(row) {
    const percentage =
      data.totalCount > 0
        ? ((100 / data.totalCount) * row.count).toFixed(0)
        : null

    return {
      ...row,
      percentage
    }
  }

  return [
    {
      color: "#FA6E91",
      label: t("dashboard.publicationsRows.general_trends"),
      count: data.GENERAL_TRENDS
    },
    {
      color: "#55E1DE",
      label: t("dashboard.publicationsRows.mega_trends"),
      count: data.MEGA_TRENDS
    },
    {
      color: "#04BD96",
      label: t("dashboard.publicationsRows.trend_alerts"),
      count: data.TREND_ALERTS
    },
    {
      color: "#9383DC",
      label: t("dashboard.publicationsRows.catwalk_report"),
      count: data.CATWALK_REPORT
    },
    {
      color: "#FFD16B",
      label: t("dashboard.publicationsRows.retail_report"),
      count: data.RETAIL_REPORT
    },
    {
      color: "#6474D4",
      label: t("dashboard.publicationsRows.technical_area_report"),
      count: data.TECHNICAL_AREA_REPORT
    },
    {
      color: "#F9886A",
      label: t("dashboard.publicationsRows.trade_show_report"),
      count: data.TRADE_SHOW_REPORT
    }
  ].map(addPercentage)
}

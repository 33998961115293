import React, { Fragment, Component } from "react"
import ImageDropzone from "./ImageDropzone"
import CropDialog from "./CropDialog"

class ImageFileInput extends Component {
  state = {
    newImageSource: null,
    originalFile: null,
    loading: false
  }

  componentWillUnmount() {
    if (this.state.newImageSource) {
      URL.revokeObjectURL(this.state.newImageSource)
    }
  }

  onImageDrop = ([file]) => {
    this.setState({ originalFile: file })

    if (!this.props.aspectRatio) {
      this.upload(file)
    }
  }

  upload = async file => {
    this.setState({ loading: true, originalFile: null })

    /*
     * We need to block the form submission
     * if the image is still uploading,
     * since we can't set custom metadata
     * with final-form, we can use the field
     * value to signal this
     */
    this.props.input.onChange("UPLOADING")

    if (this.state.newImageSource) {
      URL.revokeObjectURL(this.state.newImageSource)
    }

    this.setState({
      newImageSource: URL.createObjectURL(file)
    })

    const uploadImageFile = await this.props.upload(file)

    this.setState({ loading: false })
    this.props.input.onChange(uploadImageFile.id)
  }

  onCropDialogClose = () => {
    this.setState({
      originalFile: null
    })
  }

  render() {
    const { initialSourceFieldName, aspectRatio, meta, label } = this.props
    const { newImageSource, originalFile, loading } = this.state

    return (
      <Fragment>
        <ImageDropzone
          data-test="dropzone"
          loading={loading}
          label={label}
          meta={meta}
          onDrop={this.onImageDrop}
          initialSourceFieldName={initialSourceFieldName}
          newImageSource={newImageSource}
        />
        {aspectRatio && (
          <CropDialog
            data-test="crop-dialog"
            file={originalFile}
            onCrop={this.upload}
            onClose={this.onCropDialogClose}
            aspectRatio={aspectRatio}
          />
        )}
      </Fragment>
    )
  }
}

export default ImageFileInput

import { t } from "i18next"
import React, { Fragment } from "react"
import { Redirect } from "react-router"
import Button from "app/common/Button"
import Mutation from "app/common/Mutation"
import Snackbar from "app/common/Snackbar"
import ConfirmationDialog from "app/common/ConfirmationDialog"

function SaveButton({ disabled, onClick }) {
  return (
    <Button
      type="submit"
      text={t("updatePage.saveButtonLabel")}
      disabled={disabled}
      primary
      onClick={onClick}
    />
  )
}

function PreviewButton({ disabled, onClick }) {
  return (
    <Button
      data-testid="preview-button"
      type="submit"
      text={t("updatePage.previewButtonLabel")}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

function DestroyButton({ disabled, onClick }) {
  return (
    <Button
      text={t("updatePage.destroyButtonLabel")}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

function Buttons({
  data,
  destroyMutation,
  disabled,
  history,
  returnHref,
  formRef,
  hasSaveAndPreview = false
}) {
  if (destroyMutation) {
    return (
      <Mutation redirectOnError={false} mutation={destroyMutation}>
        {(destroy, { loading, error, called }) => {
          const hasError = Boolean(error)
          const mutationErrored = called && !loading && hasError
          const mutationSucceded = called && !loading && !hasError

          return (
            <Fragment>
              {mutationErrored && (
                <Snackbar message={t("updatePage.destroyErrorMessage")} />
              )}
              {mutationSucceded && (
                <Fragment>
                  <Snackbar message={t("updatePage.destroySuccessMessage")} />
                  <Redirect to={returnHref(data)} />
                </Fragment>
              )}
              <ConfirmationDialog
                onConfirm={() => destroy({ variables: { id: data.id } })}
                message={t("updatePage.destroyConfirmMessage")}
              >
                {confirm => (
                  <DestroyButton
                    onClick={confirm}
                    disabled={disabled || loading}
                  />
                )}
              </ConfirmationDialog>
              {hasSaveAndPreview && (
                <PreviewButton
                  disabled={disabled || loading}
                  onClick={() => formRef.change("preview", data.id)}
                />
              )}
              <SaveButton
                disabled={disabled || loading}
                onClick={() => formRef.change("preview", false)}
              />
            </Fragment>
          )
        }}
      </Mutation>
    )
  }

  return (
    <>
      {hasSaveAndPreview && (
        <PreviewButton
          disabled={disabled}
          onClick={() => formRef.change("preview", data.id)}
        />
      )}
      <SaveButton
        disabled={disabled}
        onClick={() => formRef.change("preview", false)}
      />
    </>
  )
}

export default Buttons

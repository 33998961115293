import * as graphqlUtils from "app/utils/graphql"
import imagesQuery from "./images"

const fields = [
  "createdAt",
  "id",
  "imageCount",
  "publicationDate",
  ["booth", ["id", "name"]],
  ["brand", ["id", "name"]],
  ["catwalkEvent", ["id", "name"]],
  ["city", ["id", "name"]],
  ["createdBy", ["id", "name"]],
  ["gallery", ["id", "title", "type"]],
  ["season", ["id", "name"]],
  ["store", ["id", "name"]],
  ["storeType", ["id", "name"]],
  ["stylist", ["id", "name"]],
  ["tradeShowEvent", ["id", "name"]],
  ["wgsnFolder", ["id"]]
]

export default {
  name: "ImageFolder",
  getQuery: graphqlUtils.getQuery("imageFolder", [
    "id",
    "comments",
    "publicationDate",
    ["booth", ["id", "name"]],
    ["brand", ["id", "name"]],
    ["catwalkEvent", ["id", "name"]],
    ["city", ["id", "name"]],
    ["gallery", ["id", "title", "order", "type"]],
    ["season", ["id", "name"]],
    ["store", ["id", "name"]],
    ["storeType", ["id", "name"]],
    ["stylist", ["id", "name"]],
    ["tradeShowEvent", ["id", "name"]],
    ["tradeShowType", ["id", "name"]]
  ]),
  listQuery: graphqlUtils.listQuery("imageFolders", fields, {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createImageFolder",
    "ImageFolderInput",
    fields
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateImageFolder",
    "ImageFolderInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteImageFolder"),
  imagesQuery
}

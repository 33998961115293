import React, { Component } from "react"
import { t } from "i18next"
import { withStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import FormLabel from "@material-ui/core/FormLabel"
import Button from "@material-ui/core/Button"
import FormHelperText from "@material-ui/core/FormHelperText"
import Form from "app/common/Form"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"

class Content extends Component {
  onSubmit = values => {
    const { mutation, items } = this.props
    return mutation({
      variables: {
        ids: items.map(it => it.id),
        name: values.name
      }
    })
      .then(() => null)
      .catch(error => error)
  }

  render() {
    const { classes, items, errorMessage, labelKey } = this.props

    return (
      <Form onSubmit={this.onSubmit}>
        {() => (
          <div className={classes.content}>
            <div className={classes.inputGroup}>
              <FormLabel data-test="input-label">
                {t("mergeTags.modal.inputLabel")}
              </FormLabel>
              <TextField name="name" validate={validations.required()} />
              {errorMessage && (
                <FormHelperText error>{errorMessage}</FormHelperText>
              )}
              <div className={classes.buttonControl}>
                <Button type="submit" color="primary" variant="contained">
                  {t("mergeTags.modal.submitButton")}
                </Button>
              </div>
            </div>
            <FormLabel data-test="list-label">
              {t("mergeTags.modal.listLabel")}
            </FormLabel>
            <List>
              {items.map(it => (
                <ListItem className={classes.item} key={it.id}>
                  {it[labelKey]}
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </Form>
    )
  }
}

const styles = theme => ({
  container: {
    marginBottom: 20
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  inputGroup: {
    flexGrow: 1,
    marginBottom: 20
  },
  input: {
    width: "100%",
    fontSize: 18
  },
  buttonControl: {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: 20
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center"
  },
  item: {
    paddingLeft: 0
  }
})

export default withStyles(styles)(Content)

import { t } from "i18next"
import React from "react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import { withStyles } from "@material-ui/core/styles"
import styled from "app/utils/styled"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  image: {
    height: "100%",
    width: "100%"
  }
})

const Wrapper = styled("div", theme => ({
  userSelect: "none",
  padding: 10
}))

function Item({ item, onSelect, onLoad, classes }) {
  function handleSelect() {
    onSelect(item)
  }

  return (
    <Wrapper>
      <Card>
        <img
          className={classes.image}
          data-test="image"
          srcSet={imageSrcToNew(item.imageFile.source, "small")}
          alt={item.title}
          onLoad={onLoad}
        />
        <CardActions>
          <Button color="inherit" onClick={handleSelect}>
            {t("common.select")}
          </Button>
        </CardActions>
      </Card>
    </Wrapper>
  )
}

export default withStyles(styles)(Item)

import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Stylist",
  pathPreffix: "stylists",
  i18nKey: "stylists",
  listColumns: [
    { header: t("stylists.fields.order"), path: "order" },
    { header: t("stylists.fields.name"), path: "name" }
  ],
  upsertForm: Form,
  upsert: {
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("stylists.upsertPages.duplicateNameErrorMessage")
        : t("stylists.upsertPages.genericErrorMessage")
  },
  hasSearch: true,
  hasMerge: true
})

export { ListPage, CreatePage, UpdatePage }

import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { UploadConsumer } from "./UploadContext"

const styles = theme => ({
  root: {
    userSelect: "none",
    padding: 10,
    display: "flex",
    boxSizing: "border-box"
  },
  techPackWrapper: {
    boxShadow: theme.shadows[1],
    background: theme.palette.background.default,
    position: "relative"
  },
  techPack: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  loadingIndicator: {
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)"
  }
})

export class UploadItem extends Component {
  render() {
    const { classes, index, size } = this.props

    return (
      <UploadConsumer>
        {({ files, loadingMap }) => {
          const file = files[index]
          const loading = loadingMap[index]

          return (
            <div className={classes.root} style={{ height: size, width: size }}>
              <div className={classes.techPackWrapper}>
                {loading && (
                  <CircularProgress
                    data-test="loading-indicator"
                    className={classes.loadingIndicator}
                  />
                )}
                <img
                  data-test="techPack"
                  alt=""
                  src={file.preview}
                  className={classes.techPack}
                  style={{ opacity: loading ? 0.3 : 1 }}
                />
              </div>
            </div>
          )
        }}
      </UploadConsumer>
    )
  }
}

export default withStyles(styles)(UploadItem)

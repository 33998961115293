export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Regiões"
  },
  upsertPages: {
    createTitle: "Criar Região",
    updateTitle: "Alterar Região",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta região"
  }
}

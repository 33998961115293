import { t } from "i18next"
import React, { Fragment } from "react"
import { find } from "lodash/fp"
import { relationIdField } from "app/utils/forms"
import BaseField from "app/common/formFields/Base"
import TextField from "app/common/formFields/Text"
import EntitySelectField from "app/common/formFields/EntitySelect"
import DateTimeField from "app/common/formFields/DateTime"
import SetField from "app/common/formFields/Set"

const fields = {
  season: {
    component: EntitySelectField,
    name: "season",
    label: t("techPackFolders.fields.season"),
    entity: "Season"
  },
  city: {
    component: EntitySelectField,
    name: "city",
    label: t("techPackFolders.fields.city"),
    entity: "City"
  },
  brand: {
    component: EntitySelectField,
    name: "brand",
    label: t("techPackFolders.fields.brand"),
    entity: "Brand"
  },
  stylist: {
    component: EntitySelectField,
    name: "stylist",
    label: t("techPackFolders.fields.stylist"),
    entity: "Stylist"
  },
  catwalkEvent: {
    component: EntitySelectField,
    name: "catwalkEvent",
    label: t("techPackFolders.fields.catwalkEvent"),
    entity: "CatwalkEvent"
  },
  tradeShowType: {
    component: EntitySelectField,
    name: "tradeShowType",
    label: t("techPackFolders.fields.tradeShowType"),
    entity: "TradeShowType"
  },
  booth: {
    component: EntitySelectField,
    name: "booth",
    label: t("techPackFolders.fields.booth"),
    entity: "Booth"
  },
  tradeShowEvent: {
    component: EntitySelectField,
    name: "tradeShowEvent",
    label: t("techPackFolders.fields.tradeShowEvent"),
    entity: "TradeShowEvent"
  },
  storeType: {
    component: EntitySelectField,
    name: "storeType",
    label: t("techPackFolders.fields.storeType"),
    entity: "StoreType"
  }
}

const fieldsByGalleryType = {
  CLIPPING: [fields.season, fields.brand],
  CATWALK: [fields.season, fields.catwalkEvent, fields.stylist],
  SHOWCASE: [fields.season, fields.city, fields.storeType],
  STREET_STYLE: [fields.city],
  TRADESHOW: [
    fields.season,
    fields.tradeShowEvent,
    fields.tradeShowType,
    fields.booth
  ],
  VISUAL_MERCHANDISING: [fields.city]
}

function renderField({ component: Component, ...props }) {
  return <Component {...props} key={props.name} />
}

function Form() {
  return (
    <Fragment>
      <DateTimeField
        name="publicationDate"
        label={t("techPackFolders.fields.publicationDate")}
      />
      <EntitySelectField
        name="gallery"
        label={t("techPackFolders.fields.gallery")}
        entity="Gallery"
      />
      <BaseField name="gallery" subscription={{ value: true }}>
        {({ input: { value } }) => {
          if (value && value.type) {
            const galleryFields = fieldsByGalleryType[value.type]
            return (
              <Fragment>
                {Object.keys(fields).map(fieldName => {
                  const field = find({ name: fieldName }, galleryFields)
                  if (field) {
                    return renderField(field)
                  }

                  return (
                    <Fragment key={fieldName}>
                      <SetField name={fieldName} value={null} />
                      <SetField
                        name={relationIdField(fieldName)}
                        value={null}
                      />
                    </Fragment>
                  )
                })}
              </Fragment>
            )
          }
          return null
        }}
      </BaseField>
      <TextField
        multiline
        rows={4}
        name="comments"
        label={t("techPackFolders.fields.comments")}
        maxLength={400}
      />
    </Fragment>
  )
}

export default Form

import { t } from "i18next"
import { compose } from "lodash/fp"
import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { withField } from "app/utils/forms"
import AppBar from "app/common/AppBar"
import NoScroll from "app/common/NoScroll"

const styles = {
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  content: {
    width: "80%",
    margin: "0 auto",
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  }
}

const gridComponents = {
  GRID_1x1: require("./DefaultGrid").default,
  GRID_1x2: require("./DefaultGrid").default,
  GRID_1x2_LANDSCAPE: require("./DefaultGrid").default,
  GRID_1x3: require("./DefaultGrid").default,
  GRID_1x4: require("./DefaultGrid").default,
  GRID_1x4_PORTRAIT: require("./DefaultGrid").default,
  SPECIAL_GRID_1: require("./SpecialGrids").SpecialGrid1,
  SPECIAL_GRID_2: require("./SpecialGrids").SpecialGrid2
}

function ImageGrid({ classes, setView, gridType, onCancel }) {
  const GridComponent = gridComponents[gridType.value]

  function onEdit({ imageIndex, imageFolderId }) {
    setView("SelectImageFolder", { imageIndex, imageFolderId })
  }

  return (
    <div className={classes.root}>
      <AppBar
        title={t(
          "reports.editor.contentTypes.imageGrid.dialog.imageGrid.title"
        )}
        rightArea={
          <Fragment>
            <Button onClick={onCancel} color="inherit">
              {t("common.cancel")}
            </Button>
            <Button type="submit" color="inherit">
              {t("common.save")}
            </Button>
          </Fragment>
        }
      />
      <div className={classes.content}>
        <NoScroll>
          <GridComponent gridType={gridType.value} onEdit={onEdit} />
        </NoScroll>
      </div>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withField("gridType")
)(ImageGrid)

import { t } from "i18next"
import React from "react"
import cx from "classnames"
import { map, identity } from "lodash/fp"
import { withStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select, { Creatable, Async } from "react-select"
import BaseField from "app/common/formFields/Base"
import theme from "app/theme"

const selectComponents = {
  default: Select,
  creatable: Creatable,
  async: Async
}

const styles = {
  root: {
    display: "flex",
    paddingTop: 16,
    marginBottom: 16
  }
}

function defaultNoOptionsMessage({ inputValue }) {
  if (inputValue) {
    return t("common.selectFieldNoOptionsMessage")
  }

  return t("common.selectFieldStartTyping")
}

function renderSelectField({
  input: { value, ...input },
  meta,
  label,
  classes,
  type = "default",
  placeholder = t("common.selectFieldPlaceholder"),
  noOptionsMessage = defaultNoOptionsMessage,
  loadingMessage = t("common.selectFieldLoading"),
  ...props
}) {
  const Component = selectComponents[type]
  const error = meta.touched && meta.error

  return (
    <FormControl error={Boolean(error)} className={classes.root}>
      <InputLabel htmlFor={props.name} shrink>
        {label}
      </InputLabel>
      <Component
        {...props}
        {...input}
        value={value || null}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={() => loadingMessage}
        placeholder={placeholder}
        className={cx({ "Select--has-error": error })}
        styles={{
          menu: provided => ({ ...provided, zIndex: theme.zIndex.reactSelect })
        }}
        isClearable
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

function SelectField({
  format = identity,
  parse = identity,
  isMulti,
  ...props
}) {
  return (
    <BaseField
      {...props}
      format={isMulti ? map(format) : format}
      parse={isMulti ? map(parse) : parse}
      isMulti={isMulti}
      render={renderSelectField}
    />
  )
}

export default withStyles(styles)(SelectField)

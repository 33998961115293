import { t } from "i18next"
import { omit, identity } from "lodash/fp"
import React from "react"
import Query from "app/common/Query"
import FormPage from "app/common/FormPage"
import Buttons from "app/common/CreatePage/Buttons"
import { getQueryKey } from "app/utils/graphql"

function CreateByCopy({
  getQuery,
  createMutation,
  id,
  parseInput = identity,
  parseOutput = identity,
  successMessage = t("createPage.defaultSuccessMessage"),
  errorMessage = t("createPage.defaultErrorMessage"),
  hasSaveAndPreview = false,
  entitySlug = "",
  returnHref,
  ...props
}) {
  const queryKey = getQueryKey(getQuery)

  return (
    <Query query={getQuery} variables={{ id }}>
      {({ data, loading }) => {
        if (loading) {
          return null
        }

        const record = data && parseInput(data[queryKey])

        if (!record) {
          throw new Error("Record not found")
        }

        return (
          <FormPage
            {...props}
            mutation={createMutation}
            parseVariables={values => ({ id, input: parseOutput(values) })}
            initialValues={omit(["id", "__typename"], record)}
            successMessage={successMessage}
            errorMessage={errorMessage}
            returnHref={returnHref}
            hasSaveAndPreview={hasSaveAndPreview}
            entitySlug={entitySlug}
            buttons={<Buttons />}
          />
        )
      }}
    </Query>
  )
}

export default CreateByCopy

import { withProps } from "recompose"
import * as pages from "app/pages/users/index.page"

const ListPage = withProps(({ match }) => {
  const accountId = match.params.accountId
  return {
    variables: { accountId: parseInt(accountId) },
    createHref: `/accounts/${accountId}/users/create`,
    buildUpdateHref: node => `/accounts/${accountId}/users/${node.id}/update`
  }
})(pages.ListPage)

const CreatePage = withProps(({ match }) => {
  const accountId = match.params.accountId
  return {
    accountId,
    returnHref: () => `/accounts/${accountId}/users`
  }
})(pages.CreatePage)

const UpdatePage = withProps(({ match }) => {
  const accountId = match.params.accountId
  return {
    returnHref: () => `/accounts/${accountId}/users`
  }
})(pages.UpdatePage)

export { ListPage, CreatePage, UpdatePage }

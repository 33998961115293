import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "TrendV2",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("trend", [
    "id",
    "name",
    "archived",
    ["regions", ["id", "name"]],
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery(
    "trends",
    ["id", "name", "archived", "version", ["regions", ["id", "name"]]],
    {
      search: "String",
      version: "TrendVersion = v2",
      isArchived: "Boolean",
      regionIds: "[Int!]"
    }
  ),
  listQueryVariables: { version: "v2" },
  entitySelectQueryVariables: { isArchived: false },
  createMutation: graphqlUtils.createMutation("createTrend", "TrendInput"),
  updateMutation: graphqlUtils.updateMutation("updateTrend", "TrendInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteTrend"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTrends")
}
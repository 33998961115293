export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Tipos de Loja"
  },
  upsertPages: {
    createTitle: "Criar Tipo de Loja",
    updateTitle: "Alterar Tipo de Loja",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este Tipo de Loja"
  }
}

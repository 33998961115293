import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { TechPackClassification } from "app/entities"

function DestroyClassificationsButton({ ids, children }) {
  return (
    <Mutation mutation={TechPackClassification.bulkDestroyMutation}>
      {destroy => {
        return ids.length ? (
          <Fragment>
            <ConfirmationDialog
              onConfirm={() => destroy({ variables: { ids } })}
              message={t(
                "techPackClassifications.destroyClassificationsMessage"
              )}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        ) : null
      }}
    </Mutation>
  )
}

export default DestroyClassificationsButton

import { t } from "i18next"
import cx from "classnames"
import React, { Component } from "react"
import { compose } from "lodash/fp"
import { withProps } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import deleteIcon from "app/icons/delete-icon.svg"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import Checkbox from "app/common/Checkbox"
import { withConsumer as withImagesConsumer } from "../context"
import DestroyImageButton from "../DestroyImageButton"
import ImageClassification from "../ImageClassification"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  root: {
    userSelect: "none",
    padding: 10
  },
  card: {
    transition: "transform 120ms linear, background 120ms linear",
    boxShadow: "none",
    borderRadius: 2,
    "&:hover .deleteIcon": {
      opacity: 1,
      pointerEvents: "auto",
      cursor: "pointer"
    },
    width: 300,
    "&:hover .expandIcon": {
      opacity: 1,
      pointerEvents: "auto",
      cursor: "pointer"
    },
    "&:hover .overlay": {
      opacity: 0.25,
      transition: "opacity 120ms linear"
    }
  },
  cardActive: {
    boxShadow: "0px 0px 8px 8px #c6e5fc",
    transform: "scale(0.98)"
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    transition: "color 120ms linear, opacity 200ms linear",
    pointerEvents: "none"
  },
  content: {
    padding:
      "0 !important" /* the card receives an padding-bottom added on the fly */
  },
  deleteIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    transition: "opacity 200ms linear",
    opacity: 0,
    pointerEvents: "none"
  },
  expandIcon: {
    color: "#FFFFFF",
    position: "absolute",
    bottom: 10,
    right: 10,
    transition: "opacity 200ms linear",
    opacity: 0,
    pointerEvents: "none"
  },
  image: {
    height: "100%",
    width: "100%"
  },
  imageContent: {
    position: "relative"
  },
  overlay: {
    backgroundColor: "#000000",
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%"
  },
  loadingWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10
  },
  hideLoadingWrapper: {
    display: "none"
  }
})

class Item extends Component {
  state = {
    isImageLoading: true
  }

  onClassificationSelected = imageClassification => {
    this.props.onClassificationSelected(this.props.item.id, imageClassification)
  }

  onImageLoad = () =>
    this.setState({ isImageLoading: false }, this.props.updateLayout)

  render() {
    const { classes, item, onImageDeleted } = this.props

    return (
      /* eslint-disable jsx-a11y/click-events-have-key-events */
      /* eslint-disable jsx-a11y/mouse-events-have-key-events */
      /* eslint-disable jsx-a11y/no-static-element-interactions */
      <MultiSelectConsumer>
        {({ items: selectedItems, toggleItem }) => {
          const selected = selectedItems.some(it => it.id === item.id)

          return (
            <div className={classes.root}>
              <Card
                className={cx({
                  [classes.card]: true,
                  [classes.cardActive]: selected
                })}
                onClick={() => toggleItem(item)}
                onDrop={this.onDrop}
                onDragOver={this.onDragOver}
              >
                {/* eslint-enable jsx-a11y/click-events-have-key-events */
                /* eslint-enable jsx-a11y/mouse-events-have-key-events */
                /* eslint-enable jsx-a11y/no-static-element-interactions */}
                <CardContent className={classes.content}>
                  <div
                    className={classes.imageContent}
                    style={{
                      width: "300px"
                    }}
                  >
                    <div
                      className={
                        this.state.isImageLoading
                          ? classes.loadingWrapper
                          : classes.hideLoadingWrapper
                      }
                    >
                      <CircularProgress />
                    </div>
                    <img
                      srcSet={imageSrcToNew(item.imageFile.source, "small")}
                      alt=""
                      className={classes.image}
                      onLoad={this.onImageLoad}
                    />
                    <div className={cx("overlay", classes.overlay)} />
                    <Checkbox
                      className={classes.checkIcon}
                      checked={selected}
                    />
                    <DestroyImageButton
                      ids={[item.id]}
                      onImageDeleted={onImageDeleted}
                    >
                      {/* eslint-disable jsx-a11y/click-events-have-key-events */
                      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                      {confirm => (
                        <img
                          className={cx("deleteIcon", classes.deleteIcon)}
                          src={deleteIcon}
                          alt={t("imageClassifications.destroyButtonLabel")}
                          onClick={confirm}
                        />
                      )}

                      {/* eslint-enable jsx-a11y/click-events-have-key-events */
                      /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
                    </DestroyImageButton>
                    <a
                      href={imageSrcToNew(item.imageFile.source, "large")}
                      className={cx("expandIcon", classes.expandIcon)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                    >
                      <FullscreenIcon />
                    </a>
                  </div>
                  {item.imageClassifications.length > 0 ? (
                    <ul>
                      {item.imageClassifications.map(
                        (classification, index) => (
                          <ImageClassification
                            key={classification.id}
                            index={index}
                            classification={classification}
                            onClassificationSelected={
                              this.onClassificationSelected
                            }
                            selected
                          />
                        )
                      )}
                    </ul>
                  ) : null}
                </CardContent>
              </Card>
            </div>
          )
        }}
      </MultiSelectConsumer>
    )
  }
}

export default compose(
  withStyles(styles),
  withImagesConsumer,
  withProps(props => ({
    ...props.context,
    itemCount: props.context.images.length
  }))
)(Item)

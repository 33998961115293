import React, { Component } from "react"
import Mutation from "app/common/Mutation"
import { ImageFile } from "app/entities"

class UploadImage extends Component {
  state = {
    loading: false
  }

  makeUploadImage = (upload, createImageFile) => async file => {
    this.setState({ loading: true })

    const {
      data: {
        imageFileUploadUrl: { key, url, headers }
      }
    } = await upload({
      variables: {
        input: { filename: file.name || "temp", contentType: file.type }
      }
    })

    const fetchHeaders = {}
    headers.forEach(header => (fetchHeaders[header.key] = header.value))

    await fetch(url, {
      method: "PUT",
      body: file,
      headers: fetchHeaders
    })

    const { data } = await createImageFile({
      variables: { input: { key } }
    })

    this.setState({ loading: false })

    return data.createImageFile
  }

  render() {
    return (
      <Mutation mutation={ImageFile.uploadUrl}>
        {upload => (
          <Mutation mutation={ImageFile.createImageFile}>
            {createImageFile =>
              this.props.children(
                this.makeUploadImage(upload, createImageFile),
                { loading: this.state.loading }
              )
            }
          </Mutation>
        )}
      </Mutation>
    )
  }
}

export default UploadImage

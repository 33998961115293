import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Stylist",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("stylist", [
    "id",
    "name",
    "order",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("stylists", ["id", "name", "order"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createStylist", "StylistInput"),
  updateMutation: graphqlUtils.updateMutation("updateStylist", "StylistInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteStylist"),
  mergeMutation: graphqlUtils.mergeMutation("mergeStylists")
}

import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

const styles = {
  button: {
    borderRadius: 0,
    backgroundColor: "#575757",
    color: "#ffffff",
    padding: "15px 35px",
    "&:hover": {
      backgroundColor: "#575757D0"
    },
    "&:disabled": {
      color: "#aaa"
    }
  }
}

function ActionButton({ children, className, classes, ...props }) {
  return (
    <div className={className}>
      <Button className={classes.button} {...props}>
        {children}
      </Button>
    </div>
  )
}

export default withStyles(styles)(ActionButton)

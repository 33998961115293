import React, { Fragment, Component } from "react"
import Dialog from "@material-ui/core/Dialog"
import Form from "app/common/Form"
import styled from "app/utils/styled"

const StyledForm = styled(Form, {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1
})

const views = {
  SelectGridType: require("./SelectGridType").default,
  ImageGrid: require("./ImageGrid").default,
  SelectImage: require("./SelectImage").default,
  CropSelectedImage: require("./CropSelectedImage").default,
  SelectImageFolder: require("./SelectImageFolder").default
}

let lastSelectedImageFolder = null

function shouldRedirectToLastImageFolder({ requestedView, currentView }) {
  return (
    requestedView === "SelectImageFolder" &&
    lastSelectedImageFolder &&
    currentView !== "SelectImage"
  )
}

class ImageGridDialog extends Component {
  state = {
    view: this.props.initialValues.empty
      ? { name: "SelectGridType" }
      : { name: "ImageGrid" }
  }

  setView = (name, props) => {
    if (props && props.imageFolderId) {
      lastSelectedImageFolder = props.imageFolderId
    }

    const view = shouldRedirectToLastImageFolder({
      requestedView: name,
      currentView: this.state.view.name
    })
      ? {
          name: "SelectImage",
          props: {
            ...props,
            imageFolderId: lastSelectedImageFolder
          }
        }
      : { name, props }

    this.setState({ view })
  }

  render() {
    const { open, initialValues, onSubmit, onCancel } = this.props
    const { name: viewName, props: viewProps = {} } = this.state.view
    const View = views[viewName]

    return (
      <Dialog open={open} disableEscapeKeyDown fullScreen>
        <StyledForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          subscription={{}}
        >
          {state => (
            <Fragment>
              <View {...viewProps} setView={this.setView} onCancel={onCancel} />
            </Fragment>
          )}
        </StyledForm>
      </Dialog>
    )
  }
}

export default ImageGridDialog

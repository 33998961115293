import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MUICheckbox from "@material-ui/core/Checkbox"

const styles = theme => ({
  checkbox: {
    backgroundColor: theme.palette.common.white,
    height: 18,
    width: 18,
    borderRadius: "10%",
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    }
  },
  label: {
    marginLeft: 10
  }
})

function Checkbox({ classes, label, className, ...props }) {
  return (
    <div className={className}>
      <MUICheckbox
        color="primary"
        classes={{ root: classes.checkbox }}
        {...props}
      />
      {label && <span className={classes.label}>{label}</span>}
    </div>
  )
}

export default withStyles(styles)(Checkbox)

import { t } from "i18next"
import React from "react"
import cx from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"

const styles = theme => ({
  root: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  placeholderIcon: {
    color: theme.palette.primary.light,
    fontSize: 120,
    marginBottom: theme.spacing.unit * 1
  }
})

function UploadPlaceholder({ classes, className }) {
  return (
    <div className={cx(classes.root, className)}>
      <CloudUploadIcon
        data-test="cloud-upload"
        className={classes.placeholderIcon}
      />
      <Typography>{t("imageFolders.manage.uploadPlaceholder")}</Typography>
    </div>
  )
}

export default withStyles(styles)(UploadPlaceholder)

import { t } from "i18next"
import React, { Component, Fragment } from "react"
import { ApolloConsumer } from "react-apollo"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import NumericField from "app/common/formFields/Numeric"
import { Image } from "app/entities"
import Form from "app/common/Form"
import Snackbar, { SnackbarProvider } from "app/common/Snackbar"

const styles = theme => ({
  content: {
    alignItems: "flex-end",
    display: "flex",
    margin: theme.spacing.unit * 3
  },
  input: {
    height: "100%"
  }
})

class ImageIdField extends Component {
  state = {
    error: false
  }

  handleSubmit = client => async ({ imageId }) => {
    const { imageIndex, setView } = this.props

    try {
      const { data } = await client.query({
        query: Image.getImageFileId,
        variables: { id: imageId }
      })

      setView("CropSelectedImage", {
        imageIndex,
        selectedImage: { ...data.image }
      })
    } catch (error) {
      this.setState(_ => ({ error: true }))
    }
  }

  render() {
    const { classes } = this.props

    return (
      <ApolloConsumer data-test="apollo">
        {client => (
          <div className={classes.content}>
            <Form
              data-test="form"
              onSubmit={this.handleSubmit(client)}
              error={this.state.error}
            >
              {_ => (
                <Fragment>
                  <NumericField
                    data-test="field"
                    className={classes.input}
                    name="imageId"
                    label={t(
                      "reports.editor.contentTypes.imageGrid.imageIdField.label"
                    )}
                  />
                  <Button type="submit" data-test="button" color="primary">
                    {t(
                      "reports.editor.contentTypes.imageGrid.imageIdField.submit"
                    )}
                  </Button>
                </Fragment>
              )}
            </Form>
            {this.state.error && (
              <SnackbarProvider>
                <Snackbar
                  message={t(
                    "reports.editor.contentTypes.imageGrid.imageIdField.error"
                  )}
                />
              </SnackbarProvider>
            )}
          </div>
        )}
      </ApolloConsumer>
    )
  }
}

export default withStyles(styles)(ImageIdField)

import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "User",
  getQuery: graphqlUtils.getQuery("user", [
    "id",
    "name",
    "email",
    "admin",
    "active",
    "clientVersion",
    ["account", ["id", "name"]]
  ]),
  listQuery: require("./list").default,
  createMutation: graphqlUtils.createMutation("createUser", "CreateUserInput"),
  updateMutation: graphqlUtils.updateMutation("updateUser", "UpdateUserInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteUser")
}

import { t } from "i18next"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import Button from "app/common/Button"
import PageHeader from "app/common/PageHeader"
import RemoteTable from "app/common/RemoteTable"
import MultiSelectProvider from "app/common/MultiSelectProvider"
import SearchableRemoteTable from "app/common/SearchableRemoteTable"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import MergeTagsButton from "../MergeableListPage/MergeTagsButton"
import CheckToMerge from "../MergeableListPage/CheckToMerge"

function MergeableListPage({
  title,
  query,
  mergeMutation,
  variables,
  columns,
  defaultCellStyle,
  createHref,
  buildUpdateHref,
  hasSearch = false,
  onError,
  labelKey
}) {
  const TableComponent = hasSearch ? SearchableRemoteTable : RemoteTable

  return (
    <MultiSelectProvider>
      <PageHeader
        data-test="page-header"
        title={title}
        buttons={
          <Fragment>
            <MergeTagsButton
              mutation={mergeMutation}
              buildUpdateHref={buildUpdateHref}
              onError={onError}
              labelKey={labelKey}
            />
            <Button
              component={Link}
              to={createHref}
              text={t("listPage.createButtonLabel")}
            />
          </Fragment>
        }
      />
      <TableComponent
        data-test="base-list-page"
        query={query}
        variables={variables}
        defaultCellStyle={defaultCellStyle}
        columns={[
          {
            header: "",
            component: ({ value }) => <CheckToMerge item={value} />
          },
          ...columns,
          {
            columnProps: { numeric: true },
            header: "",
            component: ({ value }) => (
              <IconButton
                component={Link}
                to={buildUpdateHref(value)}
                data-test="update-link"
              >
                <EditIcon />
              </IconButton>
            )
          }
        ]}
      />
    </MultiSelectProvider>
  )
}

export default MergeableListPage

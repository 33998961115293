import { t } from "i18next"
import React, { Fragment } from "react"
import {
  compose,
  flatten,
  map,
  pipe,
  uniq,
  differenceBy,
  isEmpty
} from "lodash/fp"
import { withProps } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Button from "app/common/Button"
import Checkbox from "app/common/Checkbox"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import { withConsumer as withImagesConsumer } from "../context"
import DestroyImageButton from "../DestroyImageButton"
import DestroyClassificationsButton from "../DestroyClassificationsButton"
import DestroyFolderClassificationsButton from "../DestroyFolderClassificationsButton"
import CleanImageClassificationRegionsButton from "../CleanImageClassificationRegionsButton"

const styles = {
  checkAll: {
    color: "#747474"
  },
  deletedClassifications: {
    backgroundColor: "transparent",
    border: 0,
    color: "#747474",
    cursor: "pointer",
    fontSize: 13,
    textDecoration: "underline",
    marginLeft: 55
  },
  uncheckSelected: {
    backgroundColor: "transparent",
    border: 0,
    color: "#747474",
    cursor: "pointer",
    fontSize: 13,
    textDecoration: "underline",
    marginLeft: 55
  },
  topbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  topbarActions: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  }
}

export function collapseImageClassificationId(selectedItems) {
  return pipe(
    map("imageClassifications"),
    flatten,
    map("id"),
    uniq
  )(selectedItems)
}

export function allImagesSelect(items, selectedItems) {
  if (isEmpty(items)) {
    return false
  }

  return differenceBy("id", items, selectedItems).length === 0
}

function Topbar({
  images,
  toggleDrawer,
  classes,
  onImageDeleted,
  height,
  padding,
  queryVariables: { imageFolderId },
  refetchImages
}) {
  return (
    <MultiSelectConsumer>
      {({ items: selectedItems, clearItems, clearAll, addMany }) => {
        const allChecked = allImagesSelect(images, selectedItems)
        const selectPage = () => addMany(images)
        const uncheckPage = () => clearItems(images)

        return (
          <div className={classes.topbar} style={{ height, padding }}>
            <div className={classes.topbarActions}>
              <Checkbox
                className={classes.checkAll}
                label={t("imageClassifications.selectAllImages", {
                  length: images.length
                })}
                onChange={allChecked ? uncheckPage : selectPage}
                checked={allChecked}
              />
              {selectedItems.length > 0 ? (
                <Fragment>
                  <button
                    className={classes.uncheckSelected}
                    onClick={clearAll}
                  >
                    {t("imageClassifications.unselectAllImages", {
                      length: selectedItems.length
                    })}
                  </button>
                  <DestroyClassificationsButton
                    ids={collapseImageClassificationId(selectedItems)}
                  >
                    {confirm => (
                      <button
                        className={classes.deletedClassifications}
                        onClick={confirm}
                      >
                        {t("imageClassifications.removeSelected")}
                      </button>
                    )}
                  </DestroyClassificationsButton>
                </Fragment>
              ) : (
                <Fragment>
                  <DestroyFolderClassificationsButton
                    imageFolderId={imageFolderId}
                    onDestroy={refetchImages}
                  >
                    {confirm => (
                      <button
                        className={classes.deletedClassifications}
                        onClick={confirm}
                      >
                        {t("imageClassifications.removeFromWholeFolder")}
                      </button>
                    )}
                  </DestroyFolderClassificationsButton>
                </Fragment>
              )}
            </div>
            {selectedItems.length > 0 ? (
              <div className={classes.topbarActions}>
                <DestroyImageButton
                  ids={map("id", selectedItems)}
                  onImageDeleted={onImageDeleted}
                >
                  {confirm => (
                    <Button
                      text={
                        selectedItems.length > 1
                          ? t("imageClassifications.destroyImagesButtonLabel")
                          : t("imageClassifications.destroyImageButtonLabel")
                      }
                      onClick={confirm}
                    />
                  )}
                </DestroyImageButton>
                <CleanImageClassificationRegionsButton
                  ids={map("id", selectedItems)}
                  onUpdate={refetchImages}
                >
                  {confirm => (
                    <Button
                      text={t("imageClassifications.cleanRegionsButtonLabel")}
                      onClick={confirm}
                    />
                  )}
                </CleanImageClassificationRegionsButton>
                <Button
                  text={t("imageClassifications.classify")}
                  onClick={toggleDrawer(true)}
                />
              </div>
            ) : (
              <Button
                text={t("imageClassifications.classifyWholeFolder")}
                onClick={toggleDrawer(true, { classifyingAllFolder: true })}
              />
            )}
          </div>
        )
      }}
    </MultiSelectConsumer>
  )
}

export default compose(
  withStyles(styles),
  withImagesConsumer,
  withProps(props => ({ ...props.context }))
)(Topbar)

import React from "react"
import { t } from "i18next"
import Button from "@material-ui/core/Button"
import ContentCopyIcon from "@material-ui/icons/ContentCopy"
import Tooltip from "@material-ui/core/Tooltip"
import { Link } from "react-router-dom"
import DateTimePrinter from "app/common/DateTimePrinter"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"
import entityInfo from "./entityInfo"
import { parseInput, parseOutput } from "./helpers"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  ...entityInfo,
  listColumns: [
    { header: t("reports.fields.id"), path: "id" },
    { header: t("reports.fields.title"), path: "title" },
    { header: t("reports.fields.author"), path: "user.name" },
    {
      header: t("reports.fields.publicationDate"),
      path: "publicationDate",
      component: DateTimePrinter,
      style: {
        width: 115
      }
    },
    {
      header: "",
      path: "id",
      type: "createByCopying",
      style: {
        width: 24,
        padding: 0,
        textAlign: "right"
      },
      component: ({ value: id }) => (
        <Button
          color="primary"
          component={Link}
          to={`/${entityInfo.pathPreffix}/createByCopyingOf/${id}`}
        >
          <Tooltip title={t("reports.createByCopying")}>
            <ContentCopyIcon />
          </Tooltip>
        </Button>
      )
    }
  ],
  upsertForm: Form,
  update: {
    parseInput
  },
  upsert: {
    errorMessage: t("trendReports.upsertPages.genericErrorMessage"),
    parseOutput,
    hasSaveAndPreview: true,
    entitySlug: entityInfo.slug
  },
  hasSearch: true
})

export { ListPage, CreatePage, UpdatePage }

export default {
  fields: {
    tag: "Tag",
    name: "Nome",
    type: "Tipo",
    cardImage: "Imagem",
    publicationDate: "Data de Publicação"
  },
  listPage: {
    title: "Tags Recomendadas"
  },
  upsertPages: {
    createTitle: "Criar Tag",
    updateTitle: "Alterar Tag",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta Tag"
  }
}

import { t } from "i18next"
import React from "react"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import TranslationsFields from "app/common/TranslationsFields"

function Form() {
  return (
    <>
      <TextField
        name="name"
        label={t("tradeShowTypes.fields.title")}
        validate={validations.required()}
      />
      <TranslationsFields i18nBaseKey="tradeShowTypes.fields.name" />
    </>
  )
}

export default Form

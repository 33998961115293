import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "DailyContent",
  getQuery: graphqlUtils.getQuery("dailyContent", [
    "id",
    "publicationDate",
    "title",
    "source",
    "sourceUrl",
    "imageFileId",
    "imageFile { source sizes { small } }"
  ]),
  listQuery: graphqlUtils.listQuery("dailyContents", [
    "id",
    "title",
    "publicationDate"
  ]),
  createMutation: graphqlUtils.createMutation(
    "createDailyContent",
    "DailyContentInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateDailyContent",
    "DailyContentInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteDailyContent")
}

import { t } from "i18next"
import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import CropImage from "app/common/CropImage"
import { UploadProvider } from "./UploadContext"
import UploadItem from "./Item"

export const CELL_SIZE = 184
export const COLUMN_COUNT = 3

const styles = {
  dialogContent: { height: "80vh" },
  autoSizer: { width: "100%" },
  collection: { outline: "none", margin: "0 auto" }
}

class UploadDialog extends Component {
  columnYMap = []

  cellRenderer = ({ index, style }) => {
    return (
      <div style={style} key={index}>
        <UploadItem data-test="upload-item" index={index} size={CELL_SIZE} />
      </div>
    )
  }

  cellSizeAndPositionGetter = ({ index }) => {
    const columnPosition = index % (COLUMN_COUNT || 1)
    const x = columnPosition * CELL_SIZE
    const y = this.columnYMap[columnPosition] || 0

    this.columnYMap[columnPosition] = y + CELL_SIZE

    return {
      height: CELL_SIZE,
      width: CELL_SIZE,
      x,
      y
    }
  }

  render() {
    const {
      classes,
      open,
      droppedFile,
      onCancel,
      onCompleteTechPackUpload,
      onExited,
      techPackFolderId,
      onTechPackAdded
    } = this.props

    return (
      <UploadProvider
        onConfigureFile={() => {
          return this.cropImageRef.getFile()
        }}
        techPackFolderId={techPackFolderId}
        onCompleteTechPackUpload={onCompleteTechPackUpload}
        onTechPackAdded={onTechPackAdded}
      >
        {({ upload, loading, complete }) => (
          <Dialog
            data-test="dialog"
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="lg"
            onExited={onExited}
          >
            <DialogContent className={classes.dialogContent}>
              {droppedFile && (
                <CropImage
                  file={droppedFile}
                  ref={ref => {
                    this.cropImageRef = ref
                  }}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                data-test="upload-button"
                disabled={loading || complete}
                onClick={upload}
                color="primary"
              >
                {t("common.upload")}
              </Button>
              <Button
                data-test="cancel-button"
                disabled={loading || complete}
                onClick={onCancel}
                color="primary"
              >
                {t("common.cancel")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </UploadProvider>
    )
  }
}

export default withStyles(styles)(UploadDialog)

import gql from "graphql-tag"

export default gql`
  query images($page: Int!, $pageSize: Int, $imageFolderId: Int) {
    images(page: $page, pageSize: $pageSize, imageFolderId: $imageFolderId) {
      totalCount
      pageInfo {
        pageCount
      }
      edges {
        node {
          id
          title
          imageFileId
          imageFile {
            source
            sizes {
              small
            }
          }
          imageClassifications {
            id
            market
            product {
              id
              name
            }
            gender {
              id
              name
            }
            regions {
              id
              name
            }
            segment {
              id
              name
            }
            category {
              id
              name
            }
            color {
              id
              name
            }
            material {
              id
              name
            }
            printType {
              id
              name
            }
            trimmingType {
              id
              name
            }
            brand {
              id
              name
            }
            topic {
              id
              name
            }
            trend {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default {
  listPage: {
    title: "Publicações de Desfile"
  },
  upsertPages: {
    createTitle: "Criar Publicação de Desfile",
    updateTitle: "Alterar Publicação de Desfile",
    genericErrorMessage: "Houve um problema ao salvar este publicação"
  }
}

import React from "react"
import { t } from "i18next"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import DownloadButton from "./DownloadButton"

function TaxonomyView({ classes }) {
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid xs item>
        {t("dashboard.taxonomy.description")}
      </Grid>

      <Grid xs={2} className={classes.buttonWrapper} item>
        <DownloadButton />
      </Grid>
    </Grid>
  )
}

export default withStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    margin: "10px 0",
    padding: "24px"
  },
  buttonWrapper: {
    textAlign: "right"
  }
}))(TaxonomyView)

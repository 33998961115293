import gql from "graphql-tag"
import * as graphqlUtils from "app/utils/graphql"

const fields = [
  "id",
  [
    "techPack",
    [
      "id",
      [
        "techPackClassifications",
        [
          "id",
          "market",
          ["product", ["id", "name"]],
          ["gender", ["id", "name"]],
          ["regions", ["id", "name"]],
          ["segment", ["id", "name"]],
          ["category", ["id", "name"]],
          ["color", ["id", "name"]],
          ["material", ["id", "name"]],
          ["printType", ["id", "name"]],
          ["trimmingType", ["id", "name"]],
          ["brand", ["id", "name"]],
          ["topic", ["id", "name"]],
          ["trend", ["id", "name"]]
        ]
      ]
    ]
  ]
]

export default {
  name: "TechPackClassification",
  createMutation: graphqlUtils.createMutation(
    "createTechPackClassification",
    "TechPackClassificationInput",
    fields
  ),
  bulkCreateMutation: gql`
    mutation BulkCreateTechPackClassification($ids: [Int!]!, $input: BulkTechPackClassificationInput!) {
      bulkCreateTechPackClassification(ids: $ids, input: $input) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  updateMutation: graphqlUtils.updateMutation(
    "updateTechPackClassification",
    "TechPackClassificationInput",
    fields
  ),
  destroyMutation: graphqlUtils.destroyMutation(
    "deleteTechPackClassification",
    fields
  ),
  bulkDestroyMutation: gql`
    mutation BulkDeleteTechPackClassification($ids: [Int!]!) {
      bulkDeleteTechPackClassification(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  bulkCleanRegionsMutation: gql`
    mutation BulkCleanTechPackClassificationRegions($ids: [Int!]!) {
      bulkCleanTechPackClassificationRegions(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  folderDeleteTechPackClassification: gql`
    mutation FolderDeleteTechPackClassification($techPackFolderId: Int!) {
      folderDeleteTechPackClassification(techPackFolderId: $techPackFolderId)
    }
  `,
  folderCreateTechPackClassification: gql`
    mutation FolderCreateTechPackClassification(
      $techPackFolderId: Int!
      $input: BulkTechPackClassificationInput!
    ) {
      folderCreateTechPackClassification(
        techPackFolderId: $techPackFolderId
        input: $input
      )
    }
  `
}

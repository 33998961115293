import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Segment",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("segment", [
    "id",
    "name",
    "order",
    "isPage",
    "parent { id name }",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery(
    "segments",
    ["id", "name", "order", "isPage", "parent { id name }"],
    {
      search: "String"
    }
  ),
  createMutation: graphqlUtils.createMutation("createSegment", "SegmentInput"),
  updateMutation: graphqlUtils.updateMutation("updateSegment", "SegmentInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteSegment"),
  mergeMutation: graphqlUtils.mergeMutation("mergeSegments")
}

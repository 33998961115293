import { t } from "i18next"
import React, { Fragment } from "react"
import EntitySelectField from "app/common/formFields/EntitySelect"
import TextField from "app/common/formFields/Text"
import NumericField from "app/common/formFields/Numeric"
import SwitchField from "app/common/formFields/Switch"
import TagField from "app/common/formFields/Tag"
import * as validations from "app/utils/validations"
import TranslationsFields from "app/common/TranslationsFields"

function Form({
  match: {
    params: { id }
  }
}) {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("segments.fields.name")}
        validate={validations.required()}
      />
      <NumericField name="order" label={t("segments.fields.order")} />
      <SwitchField name="isPage" label={t("segments.fields.isPage")} />
      <EntitySelectField
        idToHide={Number(id)}
        name="parent"
        label={t("segments.fields.parent")}
        entity="Segment"
      />
      <TagField
        name="wgsnNames"
        label={t("common.formFields.wgsnNames")}
        isMulti
      />
      <TranslationsFields i18nBaseKey="segments.fields.name" />
    </Fragment>
  )
}

export default Form

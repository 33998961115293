export default {
  fields: {
    name: "Nome",
    country: "País"
  },
  listPage: {
    title: "Cidades"
  },
  upsertPages: {
    createTitle: "Criar Cidade",
    updateTitle: "Alterar Cidade",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este cidade"
  }
}

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Brand",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("brand", [
    "id",
    "name",
    "order",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("brands", ["id", "name", "order"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createBrand", "BrandInput"),
  updateMutation: graphqlUtils.updateMutation("updateBrand", "BrandInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteBrand"),
  mergeMutation: graphqlUtils.mergeMutation("mergeBrands")
}

export default {
  fields: {
    name: "Nome",
    publicationDate: "Data de Publicação"
  },
  listPage: {
    title: "Temporadas"
  },
  upsertPages: {
    createTitle: "Criar Temporada",
    updateTitle: "Alterar Temporada",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta temporada"
  }
}

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Material",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("material", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("materials", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createMaterial",
    "MaterialInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateMaterial",
    "MaterialInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteMaterial"),
  mergeMutation: graphqlUtils.mergeMutation("mergeMaterials")
}

/*
 * Calculate the contrast of a color to determine the
 * appropriate opposing text color.
 *
 * white - if background is a dark shade color
 * text - if background is a light shade color
 */
export function getBestTextColor(hex) {
  let r
  let g
  let b

  if (hex.length === 4) {
    r = `${hex.substr(1, 1)}${hex.substr(1, 1)}`
    g = `${hex.substr(2, 1)}${hex.substr(2, 1)}`
    b = `${hex.substr(3, 1)}${hex.substr(3, 1)}`
  } else if (hex.length === 7) {
    r = hex.substr(1, 2)
    g = hex.substr(3, 2)
    b = hex.substr(5, 2)
  } else {
    return "white"
  }

  /*
   * Calculate contrast of the color
   * https://en.wikipedia.org/wiki/Grayscale
   */
  const contrast =
    (Math.round(parseInt(r, 16) * 299) +
      Math.round(parseInt(g, 16) * 587) +
      Math.round(parseInt(b, 16) * 114)) /
    1000

  return contrast >= 200 ? "text" : "white"
}

import { t } from "i18next"
import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Form from "app/common/Form"
import Mutation from "app/common/Mutation"
import Snackbar from "app/common/Snackbar"
import { ImageClassification } from "app/entities"
import ImageClassificationForm, { cleanItem } from "../ImageClassificationForm"
import Checkbox from "app/common/Checkbox"

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 50
  },
  title: {
    color: "#303030",
    fontSize: 18
  },
  closeIcon: {
    cursor: "pointer"
  },
  form: {
    margin: 50,
    width: 400
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  submit: {
    backgroundColor: "#575757",
    color: "#ffffff",
    padding: "15px 35px",
    "&:hover": {
      backgroundColor: "#575757D0"
    }
  },
  checkApplyToAllFolder: {
    fontWeight: 600,
    marginBottom: 30
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 39
  }
}

class AddImageClassificationForm extends Component {
  state = {
    applyToAllFolder: this.props.applyToAllFolder
  }

  submit = (createMutation, getVariables, afterMutation) => async input => {
    return createMutation({
      variables: getVariables(input)
    })
      .then(() => {
        afterMutation()
        return null
      })
      .catch(error => error)
  }

  getMutationProps() {
    return this.state.applyToAllFolder
      ? [
          ImageClassification.folderCreateImageClassification,
          input => ({
            imageFolderId: this.props.imageFolderId,
            input: cleanItem(input)
          }),
          () => {
            this.props.refetchImages()
            this.props.closeAction()
          }
        ]
      : [
          ImageClassification.bulkCreateMutation,
          input => ({
            ids: this.props.selectedIds,
            input: cleanItem(input)
          }),
          this.props.closeAction
        ]
  }

  render() {
    const { classes, closeAction } = this.props
    const [mutation, getVariables, afterMutation] = this.getMutationProps()

    return (
      <Mutation mutation={mutation}>
        {createMutation => {
          return (
            <Form
              onSubmit={this.submit(
                createMutation,
                getVariables,
                afterMutation
              )}
              className={classes.form}
            >
              {({ submitSucceeded, values }) => (
                <Fragment>
                  {submitSucceeded && (
                    <Snackbar
                      data-test="success-message"
                      message={t("imageClassifications.successMessage")}
                    />
                  )}
                  <div className={classes.headerContainer}>
                    <span className={classes.title}>
                      {t("imageClassifications.classifyImageFormTitle")}
                    </span>
                    <CloseIcon
                      className={classes.closeIcon}
                      onClick={closeAction}
                    />
                  </div>
                  <ImageClassificationForm />
                  <div className={classes.footer}>
                    <Checkbox
                      className={classes.checkApplyToAllFolder}
                      label={t("imageClassifications.applyToAllFolder")}
                      disabled={this.props.applyToAllFolder}
                      onChange={() =>
                        this.setState(prevState => ({
                          applyToAllFolder: !prevState.applyToAllFolder
                        }))
                      }
                      checked={this.state.applyToAllFolder}
                    />
                    <div className={classes.buttons}>
                      <Button color="inherit" onClick={closeAction}>
                        {t("imageClassifications.cancelButtonLabel")}
                      </Button>
                      <Button
                        type="submit"
                        color="inherit"
                        variant="contained"
                        className={classes.submit}
                        disabled={
                          Object.values(values).filter(Boolean).length === 0
                        }
                      >
                        {t("imageClassifications.saveButtonLabel")}
                      </Button>
                    </div>
                  </div>
                </Fragment>
              )}
            </Form>
          )
        }}
      </Mutation>
    )
  }
}

export default withStyles(styles)(AddImageClassificationForm)

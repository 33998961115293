import React, { createContext, Component } from "react"
import UploadTechPack from "app/common/UploadTechPack"
import Mutation from "app/common/Mutation"
import { TechPack } from "app/entities"

const { Provider, Consumer } = createContext({})

class UploadProvider extends Component {
  state = {
    loading: false,
    loadingMap: {},
    complete: false
  }

  makeUploadFile = async file => {
    const createTechPackFile = await this.props.uploadTechPack(file)

    return this.props.createTechPack({
      variables: {
        input: {
          techPackFileId: createTechPackFile.id,
          techPackFolderId: Number(this.props.techPackFolderId)
        }
      }
    })
  }

  uploadFile = async () => {
    this.setState({ loading: true })

    const file = await this.props.onConfigureFile()

    await this.makeUploadFile(file)

    this.setState({
      loading: false,
      complete: true
    })

    this.props.onCompleteTechPackUpload()
  }

  render() {
    const { children, files } = this.props

    const value = {
      ...this.state,
      upload: this.uploadFile,
      files
    }

    return <Provider value={value}>{children(value)}</Provider>
  }
}

function withMutations(Component) {
  return props => (
    <UploadTechPack>
      {uploadTechPack => (
        <Mutation
          mutation={TechPack.createMutation}
          update={props.onTechPackAdded}
        >
          {createTechPack => (
            <Component
              uploadTechPack={uploadTechPack}
              createTechPack={createTechPack}
              {...props}
            />
          )}
        </Mutation>
      )}
    </UploadTechPack>
  )
}

const UploadProviderWithMutations = withMutations(UploadProvider)

export {
  UploadProviderWithMutations as UploadProvider,
  Consumer as UploadConsumer
}

export default {
  galleryType: {
    tradeshow: "Feira",
    catwalk: "Desfile",
    clipping: "Clipping",
    showcase: "Nas Lojas",
    visualMerchandising: "Visual Merchandising",
    streetStyle: "Uso nas ruas",
    print: "Estamparia",
    trimming: "Aviamentos"
  },
  trendAnalysisType: {
    megaTrends: "Previsões de Tendências",
    generalTrends: "Tendências Gerais",
    trendAlerts: "Alerta de Tendências"
  },
  marketType: {
    innovative: "Innovative",
    comercial: "Mass"
  },
  clientVersion: {
    all: "TODAS",
    v1: "USE FASHION",
    v2: "START BY WGSN"
  }
}

export default {
  fields: {
    name: "Nome",
    email: "Email",
    password: "Senha",
    active: "Ativo?"
  },
  listPage: {
    title: "Administradores"
  },
  upsertPages: {
    createTitle: "Criar Administrador",
    updateTitle: "Alterar Administrador",
    duplicateEmailErrorMessage: "Este email já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este administrador"
  }
}

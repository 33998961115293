import { t } from "i18next"
import { omit } from "lodash/fp"
import RoundImage from "app/common/RoundImage"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Author",
  pathPreffix: "authors",
  i18nKey: "authors",
  listColumns: [
    {
      header: t("authors.fields.avatar"),
      path: "avatar.source",
      component: RoundImage
    },
    { header: t("authors.fields.name"), path: "name" },
    { header: t("authors.fields.role"), path: "role" }
  ],
  upsertForm: Form,
  upsert: {
    parseOutput: omit("avatar"),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("authors.upsertPages.duplicateNameErrorMessage")
        : t("authors.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "PrintType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("printType", [
    "id",
    "name",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("printTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createPrintType",
    "PrintTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updatePrintType",
    "PrintTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deletePrintType"),
  mergeMutation: graphqlUtils.mergeMutation("mergePrintTypes")
}

export default {
  listPage: {
    title: "Publicações de Feira"
  },
  upsertPages: {
    createTitle: "Criar Publicação de Feira",
    updateTitle: "Alterar Publicação de Feira",
    genericErrorMessage: "Houve um problema ao salvar este publicação"
  }
}

import { t } from "i18next"
import Form from "./Form"
import createCRUD from "app/utils/createCRUD"
import RoundedBorderImage from "app/common/RoundedBorderImage"
import DateTimePrinter from "app/common/DateTimePrinter"
import { parseInput, parseOutput } from "./helpers"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "RecommendedTag",
  pathPreffix: "recommendedTags",
  i18nKey: "recommendedTags",
  listColumns: [
    {
      header: t("recommendedTags.fields.cardImage"),
      path: "cardImage.source",
      component: RoundedBorderImage
    },
    { header: t("recommendedTags.fields.name"), path: "tagName" },
    { header: t("recommendedTags.fields.type"), path: "tagType" },
    {
      header: t("recommendedTags.fields.publicationDate"),
      path: "publicationDate",
      component: DateTimePrinter
    }
  ],
  upsertForm: Form,
  update: {
    parseInput
  },
  upsert: {
    parseOutput,
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("recommendedTags.upsertPages.duplicateNameErrorMessage")
        : t("recommendedTags.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

import React from "react"
import { t } from "i18next"
import { omit } from "lodash/fp"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "User",
  i18nKey: "users",
  pathPreffix: "users",
  listColumns: [
    { header: t("users.fields.id"), path: "id" },
    { header: t("users.fields.name"), path: "name" },
    { header: t("users.fields.email"), path: "email" },
    {
      header: t("users.fields.active"),
      path: "active",
      component: ({ value }) => (
        <input disabled type="checkbox" checked={value} />
      )
    },
    {
      header: t("users.fields.account"),
      path: "account",
      component: ({ value: { id, name } }) => (
        <Button color="primary" component={Link} to={`/accounts/${id}/update`}>
          {name}
        </Button>
      )
    }
  ],
  hasSearch: true,
  upsertForm: Form,
  create: {
    initialValues: {
      admin: false,
      active: false,
      clientVersion: "all"
    }
  },
  upsert: {
    parseOutput: omit(["account"]),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("users.upsertPages.duplicateEmailErrorMessage")
        : t("users.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Nichos"
  },
  upsertPages: {
    createTitle: "Criar Nicho",
    updateTitle: "Alterar Nicho",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este Nicho"
  }
}

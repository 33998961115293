import { get } from "lodash/fp"

function pickBrandBasedOnGalleryType(node) {
  const galleryType = get("gallery.type", node)
  switch (galleryType) {
    case "CLIPPING":
      return get("brand.name", node) || get("store.name", node)
    case "CATWALK":
      return get("stylist.name", node)
    case "TRADESHOW":
      return get("booth.name", node)
    default:
      return null
  }
}

export default function RenderBrand({ value: node }) {
  return pickBrandBasedOnGalleryType(node) || null
}

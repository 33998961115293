export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Categorias"
  },
  upsertPages: {
    createTitle: "Criar Categoria",
    updateTitle: "Alterar Categoria",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta Categoria"
  }
}

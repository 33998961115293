import { Base64 } from "js-base64"
import { pipe, map, compact, invoke } from "lodash/fp"
import { stateToHTML } from "draft-js-export-html"
import { htmlToDOM } from "app/utils/dom"
import serializeSpecialContent from "./serializeSpecialContent"

function lineBreak() {
  return {
    contentType: "TEXT",
    body: "<p><br></p>"
  }
}

function handleText(child) {
  return {
    contentType: "TEXT",
    body: child.outerHTML
  }
}

function handleSectionTitle(child) {
  if (child.outerHTML === "<div></div>") {
    return null
  }

  return {
    contentType: "SECTION_TITLE",
    title: child.textContent
  }
}

function handleTechPack({ description, source }) {
  return {
    contentType: "TECH_PACK",
    description,
    source
  }
}

function handleColorChip({ description, color }) {
  return {
    contentType: "COLOR_CHIP",
    description,
    color
  }
}

function handleVideo({ url, duration }) {
  return {
    contentType: "VIDEO",
    url,
    duration: Number(duration)
  }
}

function handleImageGrid({ gridType, images }) {
  return {
    contentType: "IMAGE_GRID",
    gridType,
    images
  }
}

function handleSpecialConent(data) {
  switch (data.type) {
    case "COLOR_CHIP":
      return handleColorChip(data)
    case "IMAGE_GRID":
      return handleImageGrid(data)
    case "TECH_PACK":
      return handleTechPack(data)
    case "VIDEO":
      return handleVideo(data)
    default:
      return null
  }
}

function handleDOM(child) {
  if (child.getAttribute("data-custom-block") === "true") {
    return handleSpecialConent(JSON.parse(Base64.decode(child.innerHTML)))
  }

  if (child.innerHTML === "<br>") {
    return lineBreak()
  }

  if (child.innerHTML === "") {
    return null
  }

  if (
    child.tagName === "P" ||
    child.tagName === "UL" ||
    child.tagName === "OL"
  ) {
    return handleText(child)
  }

  if (child.tagName === "H2") {
    return handleSectionTitle(child)
  }

  return null
}

const transformEditorStateIntoContent = pipe(
  invoke("getCurrentContent"),
  contentState =>
    stateToHTML(contentState, {
      blockRenderers: {
        atomic: (block, ...args) =>
          serializeSpecialContent(block.getData().toJS())
      },
      entityStyleFn: entity => {
        const entityType = entity.get("type").toLowerCase()
        if (entityType === "link") {
          const data = entity.getData()
          return {
            element: "a",
            attributes: {
              href: data.url,
              target: "_blank",
              rel: "noopener noreferrer"
            }
          }
        }
      }
    }),
  htmlToDOM,
  map(handleDOM),
  compact
)

export default transformEditorStateIntoContent

import React, { Component, createContext } from "react"
import { compose, get, pipe, map } from "lodash/fp"
import { withRouter } from "react-router-dom"
import Query from "app/common/Query"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import techPacksQuery from "app/entities/techPackFolder/techPacks"
import { withConsumer } from "app/utils/context"

const { Provider, Consumer } = createContext()

const PAGE_SIZE = 20

export class TechPacksProvider extends Component {
  state = {
    page: 1
  }

  onPageChange = page => this.setState({ page })

  onTechPackDeleted = (techPacks, pageCount, refetch) => (
    cache,
    { data: { bulkDeleteTechPack } }
  ) => {
    this.props.multiSelect.clearItems(bulkDeleteTechPack)

    if (
      techPacks.length === bulkDeleteTechPack.length &&
      this.state.page === pageCount &&
      this.state.page > 1
    ) {
      this.setState({ page: this.state.page - 1 })
    } else {
      refetch()
    }
  }

  onTechPackAdded = refetch => () => {
    refetch()
    this.props.multiSelect.clearAll()
  }

  render() {
    const { page } = this.state
    const variables = {
      page: page,
      pageSize: PAGE_SIZE,
      techPackFolderId: Number(this.props.techPackFolderId)
    }

    return (
      <Query query={techPacksQuery} variables={variables}>
        {({ data, loading, refetch }) => {
          const pageCount = get("techPacks.pageInfo.pageCount", data) || 1
          const techPacks = pipe(
            get("techPacks.edges"),
            map("node")
          )(data)

          return !techPacks ? null : (
            <Provider
              value={{
                techPacks,
                pageCount,
                queryVariables: variables,
                refetchTechPacks: refetch,
                onPageChange: this.onPageChange,
                onTechPackDeleted: this.onTechPackDeleted(
                  techPacks,
                  pageCount,
                  refetch
                ),
                onTechPackAdded: this.onTechPackAdded(refetch)
              }}
            >
              {this.props.children}
            </Provider>
          )
        }}
      </Query>
    )
  }
}

function withProvider(Component) {
  /*
    This query should be run with fetchPolicy="no-cache" but apollo 2.3 has a bug in no cache
    https://github.com/apollographql/apollo-client/issues/3452
    The bugs were already fixed in apollo 2.4.0.
    When apollo client 2.4.0 is released, this query can be changed
    and the Component could be reverted back to use getDerivedStateFromProps
  */
  return props => (
    <MultiSelectConsumer>
      {context => (
        <TechPacksProvider
          techPackFolderId={props.match.params.id}
          multiSelect={context}
        >
          <Component {...props} />
        </TechPacksProvider>
      )}
    </MultiSelectConsumer>
  )
}

const enhancedWithProvider = compose(
  withRouter,
  withProvider
)

const enhancedWithConsumer = withConsumer(Consumer)

export {
  enhancedWithProvider as withProvider,
  enhancedWithConsumer as withConsumer
}

import React from "react"
import { withStyles } from "@material-ui/core/styles"
import FixedRatio from "app/common/FixedRatio"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  root: {
    textAlign: "center"
  },
  image: {
    display: "block",
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    height: "100%"
  }
})

function Image({ classes, image, width, height }) {
  return (
    <div className={classes.root}>
      <FixedRatio ratio={height / width}>
        <img
          alt="no description"
          srcSet={imageSrcToNew(image.imageFile.source, "large")}
          className={classes.image}
        />
      </FixedRatio>
      <div>{image.caption}</div>
    </div>
  )
}

export default withStyles(styles)(Image)

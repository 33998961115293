import { t } from "i18next"
import React, { Component, Fragment } from "react"
import { compose } from "lodash/fp"
import { withProps } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import ArrowBack from "@material-ui/icons/ArrowBack"
import AppBar from "app/common/AppBar"
import { withURLSearch } from "app/common/URLSearch"
import { withConsumer as withTechPacksConsumer } from "../context"
import DestroyTechPackButton from "../DestroyTechPackButton"
import Content from "./Content"

const styles = {
  arrowBack: {
    cursor: "pointer",
    marginRight: 20
  }
}

class TechPackDialog extends Component {
  closeDialog = () => {
    this.props.setSearch({
      itemId: null,
      classificationId: null
    })
  }

  selectItem = index => {
    this.props.setSearch({
      itemId: this.props.techPacks[index].id,
      classificationId: null
    })
  }

  onClassificationSelected = classification => {
    this.props.setSearch({
      classificationId: classification.id
    })
  }

  onSubmit = () => {
    this.props.setSearch({
      classificationId: null
    })
  }

  unselectedClassification = () => {
    this.props.setSearch({
      classificationId: null
    })
  }

  onDelete = ids => {
    this.props.onTechPackDelete(ids)
    this.closeDialog()
  }

  render() {
    const {
      techPacks,
      classes,
      search: { itemId, classificationId },
      onTechPackDeleted
    } = this.props

    if (!itemId) {
      return null
    }

    const item = techPacks.find(techPack => techPack.id === Number(itemId))
    const classification = item
      ? item.techPackClassifications.find(
          classification => classification.id === Number(classificationId)
        )
      : null

    return item ? (
      <Dialog open onClose={this.closeDialog} fullScreen>
        <AppBar
          title={t("techPackClassifications.title")}
          beforeTitle={
            <ArrowBack
              className={classes.arrowBack}
              onClick={this.closeDialog}
            />
          }
          rightArea={
            <Fragment>
              {item && (
                <DestroyTechPackButton
                  ids={[item.id]}
                  onTechPackDeleted={onTechPackDeleted}
                >
                  {confirm => (
                    <Button color="inherit" onClick={confirm}>
                      {t("techPackClassifications.destroyTechPackButtonLabel")}
                    </Button>
                  )}
                </DestroyTechPackButton>
              )}
            </Fragment>
          }
        />
        {item && (
          <Content
            closeDialog={this.closeDialog}
            items={techPacks}
            item={item}
            classification={classification}
            onClassificationSelected={this.onClassificationSelected}
            unselectedClassification={this.unselectedClassification}
            onSubmit={this.onSubmit}
            selectItem={this.selectItem}
          />
        )}
      </Dialog>
    ) : null
  }
}

export default compose(
  withStyles(styles),
  withTechPacksConsumer,
  withURLSearch,
  withProps(props => ({ ...props.context }))
)(TechPackDialog)

import React, { useState, Fragment } from "react"
import { t } from "i18next"
import { withApollo } from "react-apollo"
import saveAs from "save-as"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "app/common/Button"
import Snackbar from "app/common/Snackbar"
import getTaxonomyCSV from "../getTaxonomyCSV"

function DownloadButton({ client }) {
  const [isWorking, setIsWorking] = useState(false)
  const [hasError, setHasError] = useState(false)

  function onClick() {
    setIsWorking(true)
    setHasError(false)
    client
      .query({ query: getTaxonomyCSV })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }
        return data.taxonomyCSV
      })
      .then(fetch)
      .then(response => response.blob())
      .then(blob => saveAs(blob, "taxonomy.csv"))
      .then(() => setIsWorking(false))
      .catch(() => {
        setIsWorking(false)
        setHasError(true)
      })
  }

  return isWorking ? (
    <CircularProgress />
  ) : (
    <Fragment>
      {hasError && <Snackbar message={t("dashboard.taxonomy.downloadError")} />}
      <Button
        onClick={onClick}
        color="primary"
        text={t("dashboard.taxonomy.action")}
      />
    </Fragment>
  )
}

export default withApollo(DownloadButton)

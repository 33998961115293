import { pipe, get, omit } from "lodash/fp"
import transformContentIntoContentInput from "app/utils/transformContentIntoContentInput"
import { normalizeObject } from "app/utils/forms"

export const parseInput = normalizeObject({
  analysisType: get("analysisType.key")
})

export const parseOutput = pipe(
  omit([
    "publicationType",
    "cardImage",
    "season",
    "genders",
    "segments",
    "categories",
    "niches",
    "topics",
    "trends",
    "products",
    "printTypes",
    "megaTrendType",
    "generalTrendType"
  ]),
  transformContentIntoContentInput
)

import React, { Fragment, Component } from "react"
import FileDropzone from "./FileDropzone"

class FileInput extends Component {
  state = {
    newFileSource: null,
    originalFile: null,
    loading: false
  }

  componentWillUnmount() {
    if (this.state.newFileSource) {
      URL.revokeObjectURL(this.state.newFileSource)
    }
  }

  onFileDrop = ([file]) => {
    this.setState({ originalFile: file })
    this.upload(file)
  }

  upload = async file => {
    this.setState({ loading: true, originalFile: null })

    /*
     * We need to block the form submission
     * if the files is still uploading,
     * since we can't set custom metadata
     * with final-form, we can use the field
     * value to signal this
     */
    this.props.input.onChange("UPLOADING")

    if (this.state.newFileSource) {
      URL.revokeObjectURL(this.state.newFileSource)
    }

    this.setState({
      newFileSource: URL.createObjectURL(file)
    })

    const uploadFile = await this.props.upload(file)

    this.setState({ loading: false })
    this.props.input.onChange(uploadFile.id)
  }

  render() {
    const { initialSourceFieldName, meta, label, accept } = this.props
    const { newFileSource, loading } = this.state

    return (
      <Fragment>
        <FileDropzone
          data-test="dropzone"
          accept={accept}
          loading={loading}
          label={label}
          meta={meta}
          onDrop={this.onFileDrop}
          initialSourceFieldName={initialSourceFieldName}
          newFileSource={newFileSource}
        />
      </Fragment>
    )
  }
}

export default FileInput

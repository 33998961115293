import { t } from "i18next"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import usePantone from "./PantoneProvider"

const Backdrop = styled.div`
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 111;
  background-color: #2c2c2c;
`

const Wrapper = styled.div`
  top: 0;
  right: 0;
  width: 46rem;
  height: 100%;
  position: fixed;
  padding: 12.3rem 3.2rem;
  padding-top: 7rem;
  z-index: 111;
  background-color: #fff;
`

const CloseButton = styled.div`
  cursor: pointer;
  top: 4rem;
  right: 4rem;
  width: 2rem;
  position: absolute;
  color: #ababab;
`

const ColorsWrapper = styled.div`
  display: grid;
  grid-gap: 0.3rem;
  margin-top: 4.3rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1.6rem;
  height: calc(100% + 6.4rem);
  grid-template-columns: repeat(10, 1fr);
`

const Color = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
`

const Tooltip = styled.div`
  z-index: 1;
  display: none;
  width: 12.1rem;
  margin-top: 2rem;
  margin-left: 2rem;
  position: relative;
  background-color: black;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.75);

  ${Color}:hover & {
    display: block;
  }
`

const TooltipColor = styled.div`
  height: 12.1rem;
`

const TooltipInfo = styled.div`
  padding: 0.8rem;
  font-size: 1.2rem;
  line-height: 1.4em;
  color: #333;
  background-color: #fff;
`

const Input = styled.input`
  background-color: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: #2c2c2c;
  font-size: 1.4rem;
  height: 3.5rem;
  width: 100%;
`

const WrapperFilter = styled.div`
  margin-top: 1.5rem;
`

function filterPantone(pantones, filter) {
  return pantones.filter(
    r => r.code.search(filter) !== -1 || r.name.search(filter) !== -1
  )
}

function ColorDrawer({ currentColor, onClose, onChange }) {
  const [filter, setFilter] = useState(null)
  const { data } = usePantone()
  const dataFiltered = filter ? filterPantone(data, filter) : data

  useEffect(() => {
    const html = document.querySelector("html")
    html.style.overflowY = currentColor ? "hidden" : "auto"
  }, [currentColor])

  if (!currentColor) {
    return null
  }

  const handleColorSelect = color => () => {
    onChange(`colorWays${currentColor}`, color)
    onClose()
  }

  const handleFilter = e => {
    setFilter(e.target.value)
  }

  return (
    <>
      <Backdrop onClick={onClose} />
      <Wrapper>
        <CloseButton onClick={onClose}>
          {t("techPackDetail.colorDrawer.close")}
        </CloseButton>
        <div>{t("techPackDetail.colorDrawer.title", { currentColor })}</div>
        <WrapperFilter>
          <Input
            placeholder={t("techPackDetail.colorDrawer.filter")}
            onChange={handleFilter}
          />
        </WrapperFilter>
        <ColorsWrapper>
          {dataFiltered.map(color => (
            <Color
              key={color.code}
              onClick={handleColorSelect(color.hex)}
              style={{ backgroundColor: color.hex }}
            >
              <Tooltip>
                <TooltipColor style={{ backgroundColor: color.hex }} />
                <TooltipInfo>
                  <b>{color.code}</b>
                  <div>{color.name}</div>
                </TooltipInfo>
              </Tooltip>
            </Color>
          ))}
        </ColorsWrapper>
      </Wrapper>
    </>
  )
}

export default ColorDrawer

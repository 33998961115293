import { t } from "i18next"
import { omit } from "lodash/fp"
import DatePrinter from "app/common/DatePrinter"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "DailyContent",
  pathPreffix: "dailyContents",
  i18nKey: "dailyContent",
  listColumns: [
    {
      header: t("dailyContent.fields.publicationDate"),
      path: "publicationDate",
      component: DatePrinter
    },
    { header: t("dailyContent.fields.title"), path: "title" }
  ],
  upsertForm: Form,
  update: {
    parseOutput: omit("imageFile")
  },
  upsert: {
    errorMessage: () => t("dailyContent.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

import React, { Fragment, Component } from "react"
import IconButton from "@material-ui/core/IconButton"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Modal from "app/common/Modal"

class PreviewImagesModal extends Component {
  state = {
    isOpen: false
  }

  handleOpen = () => this.setState({ isOpen: true })
  handleClose = () => this.setState({ isOpen: false })

  render() {
    return (
      <Fragment>
        <IconButton color="inherit" onClick={this.handleOpen}>
          <VisibilityIcon />
        </IconButton>
        <Modal isOpen={this.state.isOpen}>
          {this.props.children({ closeModal: this.handleClose })}
        </Modal>
      </Fragment>
    )
  }
}

export default PreviewImagesModal

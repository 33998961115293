import { t } from "i18next"
import { omit } from "lodash/fp"
import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import FormLabel from "@material-ui/core/FormLabel"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "app/common/formFields/Text"
import FinalForm from "app/common/Form"
import Mutation from "app/common/Mutation"
import Query from "app/common/Query"
import ActionButton from "app/common/ActionButton"
import { TechPack } from "app/entities"
import NumericField from "app/common/formFields/Numeric"
import FileField from "app/common/formFields/File"
import ImageFileField from "app/common/formFields/ImageFile"
import UploadTechPackBackFile from "app/common/UploadTechPackBackFile"
import UploadTechPackTechnicalFile from "app/common/UploadTechPackTechnicalFile"
import * as validations from "app/utils/validations"
import { PantoneProvider } from "./PantoneProvider"
import ColorDrawer from "./ColorDrawer"

const styles = theme => ({
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 39
  },
  loading: {
    textAlign: "center"
  },
  buttonProgress: {
    color: theme.palette.common.white,
    marginLeft: 10
  },
  contentWrapper: {
    maxWidth: 900,
    width: "100%",
    margin: "0 auto"
  }
})

export const cleanItem = omit([
  "title",
  "techPackFolder",
  "techPackFile",
  "techPackBackFile",
  "techPackTechnicalFile",
  "techPackClassifications",
  "__typename"
])

class DetailDialog extends Component {
  state = {
    isTechPackLoading: true,
    selectedColor: null
  }

  handleClose = e => {
    if (this.props.onClose) {
      this.props.onClose(e)
    }
  }

  handleSubmit = mutation => async ({ id, ...input }, { reset }) => {
    const techPackFolderId = input.techPackFolder.id
    const inputData = cleanItem(input)
    inputData.price = inputData.price ? String(inputData.price) : null
    inputData.complete = true
    inputData.techPackFolderId = techPackFolderId
    try {
      await mutation({ variables: { id, input: inputData } })
      if (this.props.onSubmit) {
        this.props.onSubmit()
      }
      reset()
      return null
    } catch (error) {
      return error
    }
  }

  handleDrawerClose = () => {
    this.setState({
      selectedColor: null
    })
  }

  handleChangeColorDrawer = (field, color) => {
    this.form.change(field, color)
  }

  handleColorEdit = colorIndex => () => {
    this.setState({ selectedColor: colorIndex })
  }

  render() {
    const { classes, item, open } = this.props

    return (
      <PantoneProvider>
        <Dialog
          onClick={e => {
            e.stopPropagation()
          }}
          fullScreen={true}
          open={open}
          onClose={this.handleClose}
        >
          <ColorDrawer
            currentColor={this.state.selectedColor}
            onClose={this.handleDrawerClose}
            onChange={this.handleChangeColorDrawer}
          />

          <div className={classes.contentWrapper}>
            <DialogTitle>{t("techPackDetail.title")}</DialogTitle>
            <DialogContent>
              <Query query={TechPack.getQuery} variables={{ id: item.id }}>
                {({ data, loading, refetch }) => {
                  if (loading) {
                    return (
                      <div className={classes.loading}>
                        {t("techPackDetail.loadingDetail")}
                      </div>
                    )
                  }
                  let { techPack } = data
                  techPack = Object.assign(techPack, this.state.colors)
                  return (
                    <Mutation mutation={TechPack.updateMutation}>
                      {(mutation, { loading }) => {
                        return (
                          <FinalForm
                            initialValues={techPack}
                            onSubmit={this.handleSubmit(mutation)}
                          >
                            {({ values, form }) => {
                              this.form = form
                              return (
                                <Fragment>
                                  <FormLabel data-test="input-label-description">
                                    {t(
                                      "techPackDetail.form.fields.description"
                                    )}
                                  </FormLabel>
                                  <TextField
                                    name="description"
                                    validate={validations.required()}
                                  />
                                  <FormLabel data-test="input-label-styleNumber">
                                    {t(
                                      "techPackDetail.form.fields.styleNumber"
                                    )}
                                  </FormLabel>
                                  <TextField name="styleNumber" />
                                  <FormLabel data-test="input-label-sampleSize">
                                    {t("techPackDetail.form.fields.sampleSize")}
                                  </FormLabel>
                                  <TextField name="sampleSize" />
                                  <FormLabel data-test="input-label-sizeRange">
                                    {t("techPackDetail.form.fields.sizeRange")}
                                  </FormLabel>
                                  <TextField name="sizeRange" />
                                  <FormLabel data-test="input-label-colorWays1">
                                    {t("techPackDetail.form.fields.colorWays1")}
                                  </FormLabel>
                                  <TextField
                                    name="colorWays1"
                                    onClick={this.handleColorEdit(1)}
                                  />
                                  <FormLabel data-test="input-label-colorWays2">
                                    {t("techPackDetail.form.fields.colorWays2")}
                                  </FormLabel>
                                  <TextField
                                    name="colorWays2"
                                    onClick={this.handleColorEdit(2)}
                                  />
                                  <FormLabel data-test="input-label-colorWays3">
                                    {t("techPackDetail.form.fields.colorWays3")}
                                  </FormLabel>
                                  <TextField
                                    name="colorWays3"
                                    onClick={this.handleColorEdit(3)}
                                  />
                                  <FormLabel data-test="input-label-fabricSuggestion">
                                    {t(
                                      "techPackDetail.form.fields.fabricSuggestion"
                                    )}
                                  </FormLabel>
                                  <TextField name="fabricSuggestion" />
                                  <FormLabel data-test="input-label-trims">
                                    {t("techPackDetail.form.fields.trims")}
                                  </FormLabel>
                                  <TextField name="trims" />
                                  <FormLabel data-test="input-label-notes">
                                    {t("techPackDetail.form.fields.notes")}
                                  </FormLabel>
                                  <TextField name="notes" />
                                  <FormLabel data-test="input-label-price">
                                    {t("techPackDetail.form.fields.price")}
                                  </FormLabel>
                                  <NumericField name="price" />
                                  <div>
                                    <FileField
                                      accept=".ai"
                                      uploadComponent={
                                        UploadTechPackTechnicalFile
                                      }
                                      initialSourceFieldName="techPackTechnicalFile.source"
                                      name="techPackTechnicalFileId"
                                      label={t(
                                        "techPackDetail.form.fields.techPackTechnicalFile"
                                      )}
                                    />
                                  </div>
                                  <ImageFileField
                                    hasImageDropzone={false}
                                    uploadComponent={UploadTechPackBackFile}
                                    aspectRatio={456 / 660}
                                    initialSourceFieldName="techPackBackFile.source"
                                    name="techPackBackFileId"
                                    label={t(
                                      "techPackDetail.form.fields.techPackBackFile"
                                    )}
                                  />
                                  <div className={classes.buttons}>
                                    <Button
                                      color="inherit"
                                      onClick={e => {
                                        this.handleClose(e)
                                        form.reset()
                                      }}
                                    >
                                      {t(
                                        "techPackDetail.form.buttons.cancelButtonLabel"
                                      )}
                                    </Button>
                                    <ActionButton
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      disabled={loading}
                                    >
                                      {t(
                                        "techPackDetail.form.buttons.saveButtonLabel"
                                      )}
                                      {loading && (
                                        <CircularProgress
                                          size={24}
                                          className={classes.buttonProgress}
                                        />
                                      )}
                                    </ActionButton>
                                  </div>
                                </Fragment>
                              )
                            }}
                          </FinalForm>
                        )
                      }}
                    </Mutation>
                  )
                }}
              </Query>
            </DialogContent>
          </div>
        </Dialog>
      </PantoneProvider>
    )
  }
}

export default withStyles(styles)(DetailDialog)

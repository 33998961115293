import { t } from "i18next"
import { omit } from "lodash/fp"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "City",
  pathPreffix: "cities",
  i18nKey: "cities",
  listColumns: [
    { header: t("cities.fields.name"), path: "name" },
    { header: t("cities.fields.country"), path: "country.name" }
  ],
  upsertForm: Form,
  upsert: {
    parseOutput: omit("country"),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("cities.upsertPages.duplicateNameErrorMessage")
        : t("cities.upsertPages.genericErrorMessage")
  },
  hasSearch: true,
  hasMerge: true
})

export { ListPage, CreatePage, UpdatePage }

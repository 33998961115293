import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { TechPackClassification } from "app/entities"

function CleanTechPackClassificationRegionsButton({ ids, children, onUpdate }) {
  return (
    <Mutation
      mutation={TechPackClassification.bulkCleanRegionsMutation}
      update={onUpdate}
    >
      {destroy => {
        return ids.length ? (
          <Fragment>
            <ConfirmationDialog
              onConfirm={() => destroy({ variables: { ids } })}
              message={t("techPackClassifications.cleanRegionsConfirmMessage")}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        ) : null
      }}
    </Mutation>
  )
}

export default CleanTechPackClassificationRegionsButton

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Tipos de Previsões de Tendências"
  },
  upsertPages: {
    createTitle: "Criar Tipo de Previsão de Tendência",
    updateTitle: "Alterar Tipo de Previsão de Tendência",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage:
      "Houve um problema ao salvar este tipo de Previsão de Tendência"
  }
}

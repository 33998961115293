import React from "react"
import cx from "classnames"
import { withStyles } from "@material-ui/core/styles"

const styles = {
  root: {
    overflow: "hidden",
    height: 0,
    width: "100%",
    position: "relative"
  },
  inner: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
}

/*
 * The ratio should be the height
 * divided by the width
 */
function FixedRatio({ classes, className, ratio, children }) {
  return (
    <div
      className={cx(classes.root, className)}
      style={{ paddingTop: `${ratio * 100}%` }}
    >
      <div className={classes.inner}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(FixedRatio)

import { t } from "i18next"
import React, { Fragment } from "react"
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin"
import createLinkPlugin from "draft-js-anchor-plugin"
import {
  ItalicButton,
  BoldButton,
  UnorderedListButton,
  OrderedListButton,
  createBlockStyleButton
} from "draft-js-buttons"
import TitleIcon from "@material-ui/icons/Title"
import generateClassNames from "app/utils/generateClassNames"
import theme from "app/theme"

const buttonStyles = generateClassNames({
  buttonWrapper: {
    display: "inline-block"
  },
  button: {
    cursor: "pointer",
    background: theme.palette.background.dark,
    color: theme.palette.text.primary,
    fontSize: 18,
    border: 0,
    paddingTop: 5,
    verticalAlign: "bottom",
    height: 45,
    width: 36,
    "& svg": {
      fill: "#fff"
    },
    "&:hover, &:focus": {
      background: theme.palette.background.darkHover
    }
  },
  active: {
    color: theme.palette.primary.activeOnDarkBg,
    "& svg": {
      fill: theme.palette.primary.activeOnDarkBg
    }
  }
})

const toolbarStyles = generateClassNames({
  toolbar: {
    position: "absolute",
    border: `1px solid ${theme.palette.grey[800]}`,
    borderRadius: "4px",
    boxShadow: theme.shadows[4],
    zIndex: theme.zIndex.tooltip,
    boxSizing: "border-box",
    overflow: "hidden",
    display: "flex",
    "&:after, &:before": {
      top: "100%",
      left: "50%",
      border: "solid transparent",
      content: " ",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none"
    },
    "&:after": {
      borderColor: theme.palette.common.white,
      borderWidth: "4px",
      marginLeft: "-4px"
    },
    "&:before": {
      borderColor: theme.palette.common.white,
      borderTopColor: theme.palette.grey[900],
      borderWidth: "6px",
      marginLeft: "-6px"
    }
  }
})

const linkStyles = generateClassNames({
  input: {
    width: 220,
    height: 45,
    boxShadow: theme.shadows[4],
    padding: "0 12px",
    fontSize: 13,
    background: theme.palette.background.dark,
    border: "none",
    borderRadius: 4,
    color: theme.palette.common.white,
    "&:focus": {
      outline: "none"
    },
    "&::placeholder": {
      color: theme.palette.grey[500]
    }
  },
  inputInvalid: {
    color: theme.palette.error.main
  },
  link: {
    color: theme.palette.links.default,
    textDecoration: "underline"
  }
})

const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    buttonStyles,
    toolbarStyles
  }
})

const linkPlugin = createLinkPlugin({
  placeholder: t("reports.editor.inlineToolbar.linkPlaceholder"),
  theme: linkStyles
})

const { LinkButton } = linkPlugin

const TitleButton = createBlockStyleButton({
  blockType: "header-two",
  children: <TitleIcon />
})

function InlineToolbar() {
  return (
    <inlineToolbarPlugin.InlineToolbar>
      {props => (
        <Fragment>
          <BoldButton data-test="bold-button" {...props} />
          <ItalicButton data-test="italic-button" {...props} />
          <TitleButton data-test="title-button" {...props} />
          <UnorderedListButton data-test="ul-button" {...props} />
          <OrderedListButton data-test="ol-button" {...props} />
          <LinkButton data-test="link-button" {...props} />
        </Fragment>
      )}
    </inlineToolbarPlugin.InlineToolbar>
  )
}

export const inlineToolbarPlugins = [inlineToolbarPlugin, linkPlugin]

export default InlineToolbar

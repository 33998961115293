import React from "react"
import Query from "app/common/Query"
import { CurrentUser } from "app/entities"

function QueryUser({ setUser }) {
  return (
    <Query
      query={CurrentUser.meQuery}
      fetchPolicy="cache-first"
      redirectOnError={false}
    >
      {({ data }) => {
        if (data && data.me) {
          setUser(data.me)
        }
        return null
      }}
    </Query>
  )
}

export default QueryUser

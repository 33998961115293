export default {
  fields: {
    avatar: "Avatar",
    name: "Nome",
    role: "Perfil"
  },
  listPage: {
    title: "Autores"
  },
  upsertPages: {
    createTitle: "Criar Autor",
    updateTitle: "Alterar Autor",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este autor"
  }
}

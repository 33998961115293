export default {
  fields: {
    title: "Título"
  },
  listPage: {
    title: "Tipos de Feira"
  },
  upsertPages: {
    createTitle: "Criar Tipo de Feira",
    updateTitle: "Alterar Tipo de Feira",
    duplicateTitleErrorMessage: "Este título já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este tipo de feira"
  }
}

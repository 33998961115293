import { t } from "i18next"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import AppBar from "app/common/AppBar"
import styled from "app/utils/styled"

const LoadingIndicator = styled(CircularProgress, theme => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing.unit * 2
}))

function Layout({ onCancel, onSave, onMirrorHorizontally, children, loading }) {
  return (
    <Fragment>
      <AppBar
        title={t(
          "reports.editor.contentTypes.imageGrid.dialog.cropImage.title"
        )}
        afterTitle={loading && <LoadingIndicator />}
        rightArea={
          <Fragment>
            <Button onClick={onCancel} disabled={!onCancel} color="inherit">
              {t("common.cancel")}
            </Button>
            <Button onClick={onMirrorHorizontally} color="inherit">
              {t(
                "reports.editor.contentTypes.imageGrid.dialog.cropImage.mirrorHorizontally"
              )}
            </Button>
            <Button onClick={onSave} disabled={!onSave} color="inherit">
              {t("common.save")}
            </Button>
          </Fragment>
        }
      />
      {children}
    </Fragment>
  )
}

export default Layout

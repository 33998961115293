import React from "react"

function TechPackPreview({ data }) {
  return (
    <div>
      <a
        href={data.source}
        style={{
          padding: "8px 16px",
          fontSize: 14,
          fontWeight: "bold"
        }}
      >
        {data.description}
      </a>
    </div>
  )
}

export default TechPackPreview

import React from "react"
import { mapValues } from "lodash/fp"
import coerceToNumber from "app/utils/coerceToNumber"
import { Route, Redirect } from "react-router-dom"
import { AuthConsumer } from "app/common/AuthContext"
import PrivateLayout from "app/common/PrivateLayout"

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ match, ...rest }) => {
        const paramsWithNumberCoercion = mapValues(coerceToNumber)(match.params)

        return (
          <AuthConsumer>
            {({ loggedIn }) =>
              loggedIn ? (
                <PrivateLayout>
                  <Component
                    data-test="component"
                    match={{
                      ...match,
                      params: paramsWithNumberCoercion
                    }}
                    {...rest}
                  />
                </PrivateLayout>
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: rest.location }
                  }}
                />
              )
            }
          </AuthConsumer>
        )
      }}
    />
  )
}

export default PrivateRoute

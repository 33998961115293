export const galleryType = {
  TRADESHOW: "enums.galleryType.tradeshow",
  CATWALK: "enums.galleryType.catwalk",
  CLIPPING: "enums.galleryType.clipping",
  SHOWCASE: "enums.galleryType.showcase",
  VISUAL_MERCHANDISING: "enums.galleryType.visualMerchandising",
  STREET_STYLE: "enums.galleryType.streetStyle",
  PRINT: "enums.galleryType.print",
  TRIMMING: "enums.galleryType.trimming"
}

export const trendAnalysisType = {
  MEGA_TRENDS: "enums.trendAnalysisType.megaTrends",
  GENERAL_TRENDS: "enums.trendAnalysisType.generalTrends",
  TREND_ALERTS: "enums.trendAnalysisType.trendAlerts"
}

export const marketType = {
  INNOVATIVE: "enums.marketType.innovative",
  COMERCIAL: "enums.marketType.comercial"
}

export const clientVersion = {
  v1: "enums.clientVersion.v1",
  v2: "enums.clientVersion.v2",
  all: "enums.clientVersion.all"
}
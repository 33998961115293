export default {
  accounts: require("./accounts").default,
  administrators: require("./administrators").default,
  authors: require("./authors").default,
  booths: require("./booths").default,
  brands: require("./brands").default,
  categories: require("./categories").default,
  catwalkEvents: require("./catwalkEvents").default,
  catwalkReports: require("./catwalkReports").default,
  cities: require("./cities").default,
  colors: require("./colors").default,
  common: require("./common").default,
  countries: require("./countries").default,
  createPage: require("./createPage").default,
  dailyContent: require("./dailyContent").default,
  dailyImages: require("./dailyImages").default,
  dashboard: require("./dashboard").default,
  enums: require("./enums").default,
  errorPage: require("./errorPage").default,
  galleries: require("./galleries").default,
  genders: require("./genders").default,
  regions: require("./regions").default,
  generalTrendTypes: require("./generalTrendTypes").default,
  imageClassifications: require("./imageClassifications").default,
  imageFolders: require("./imageFolders").default,
  techPackClassifications: require("./techPackClassifications").default,
  techPackDetail: require("./techPackDetail").default,
  techPackFolders: require("./techPackFolders").default,
  index: require("./index").default,
  listPage: require("./listPage").default,
  login: require("./login").default,
  materials: require("./materials").default,
  megaTrendTypes: require("./megaTrendTypes").default,
  mergeTags: require("./mergeTags").default,
  niches: require("./niches").default,
  printTypes: require("./printTypes").default,
  products: require("./products").default,
  recommendedTags: require("./recommendedTags").default,
  reports: require("./reports").default,
  retailReports: require("./retailReports").default,
  seasons: require("./seasons").default,
  segments: require("./segments").default,
  storeTypes: require("./storeTypes").default,
  stylists: require("./stylists").default,
  technicalAreaReports: require("./technicalAreaReports").default,
  technicalAreaType: require("./technicalAreaType").default,
  topics: require("./topics").default,
  tradeShowEvents: require("./tradeShowEvents").default,
  tradeShowReports: require("./tradeShowReports").default,
  tradeShowTypes: require("./tradeShowTypes").default,
  trendReports: require("./trendReports").default,
  trends: require("./trends").default,
  trendsV2: require("./trendsV2").default,
  trimmingTypes: require("./trimmingTypes").default,
  updatePage: require("./updatePage").default,
  users: require("./users").default,
  wgsnFolders: require("./wgsnFolders").default
}

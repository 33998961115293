import * as graphqlUtils from "app/utils/graphql"
import gql from "graphql-tag"

const getImageFileId = gql`
  query GetImage($id: Int!) {
    image(id: $id) {
      id
      imageFileId
      title
    }
  }
`

const fields = [
  "id",
  "title",
  "imageFileId",
  "imageFile { source sizes { small } }",
  `imageClassifications {
    id
    market
    product {
      id
      name
    }
    gender {
      id
      name
    }
    regions {
      id
      name
    }
    brand {
      id
      name
    }
    segment {
      id
      name
    }
    color {
      id
      name
    }
    material {
      id
      name
    }
    printType {
      id
      name
    }
    trimmingType {
      id
      name
    }
  }`
]

export default {
  name: "Image",
  getQuery: graphqlUtils.getQuery("image", fields),
  getImageFileId: getImageFileId,
  listQuery: gql`
    query Images($page: Int!, $pageSize: Int, $imageFolderId: Int) {
      images(page: $page, pageSize: $pageSize, imageFolderId: $imageFolderId) {
        totalCount
        pageInfo {
          pageCount
        }
        edges {
          node {
            ${graphqlUtils.parseFields(fields)}
          }
        }
      }
    }
  `,
  createMutation: graphqlUtils.createMutation(
    "createImage",
    "ImageInput",
    fields
  ),
  updateMutation: graphqlUtils.updateMutation("updateImage", "ImageInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteImage"),
  bulkDestroyMutation: gql`
    mutation BulkDeleteImage($ids: [Int!]!) {
      bulkDeleteImage(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `
}

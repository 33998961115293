import { t } from "i18next"
import React, { Fragment } from "react"
import SetField from "app/common/formFields/Set"
import TextField from "app/common/formFields/Text"
import SwitchField from "app/common/formFields/Switch"
import * as validations from "app/utils/validations"

function Form({ create }) {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("administrators.fields.name")}
        type="name"
        validate={validations.composeValidators(validations.required())}
      />
      <TextField
        name="email"
        label={t("administrators.fields.email")}
        type="email"
        validate={validations.composeValidators(
          validations.required(),
          validations.isEmail()
        )}
      />
      <TextField
        name="password"
        label={t("administrators.fields.password")}
        type="password"
        nullify={false}
        validate={validations.composeValidators(
          create ? validations.required() : null,
          validations.minLength(6)
        )}
      />
      <SwitchField name="active" label={t("administrators.fields.active")} />
      <SetField name="accountId" value={1} />
    </Fragment>
  )
}

export default Form

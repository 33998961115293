import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import ImageFileField from "app/common/formFields/ImageFile"
import DateTimeField from "app/common/formFields/DateTime"

function Form() {
  return (
    <Fragment>
      <DateTimeField
        name="publicationDate"
        label={t("dailyImages.fields.publicationDate")}
        validate={validations.required()}
      />
      <TextField
        name="title"
        label={t("dailyImages.fields.title")}
        validate={validations.required()}
        maxLength={300}
      />
      <TextField
        name="reportId"
        label={t("dailyImages.fields.reportId")}
        validate={validations.required()}
        maxLength={10}
      />
      <ImageFileField
        aspectRatio={630 / 233}
        initialSourceFieldName="imageFile.source"
        name="imageFileId"
        label={t("dailyImages.fields.image")}
        validate={validations.required()}
      />
    </Fragment>
  )
}

export default Form

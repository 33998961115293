import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Gender",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("gender", [
    "id",
    "name",
    "order",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("genders", ["id", "name", "order"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createGender", "GenderInput"),
  updateMutation: graphqlUtils.updateMutation("updateGender", "GenderInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteGender"),
  mergeMutation: graphqlUtils.mergeMutation("mergeGenders")
}

import React from "react"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => {
  return {
    wrapper: {
      fontFamily: theme.typography.fontFamily,
      textAlign: "center",
      margin: "0 12px"
    },
    count: {
      fontSize: 40,
      color: theme.palette.primary.dark,
      fontWeight: 600,
      display: "block"
    },
    label: {
      fontSize: 24,
      textTransform: "lowercase",
      color: theme.palette.grey[500]
    }
  }
}

function Total({ totalCount, label, classes }) {
  return (
    <div className={classes.wrapper}>
      <span className={classes.count}>{totalCount}</span>
      <span className={classes.label}>{label}</span>
    </div>
  )
}

export default withStyles(styles)(Total)

import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "Gallery",
  labelKey: "title",
  getQuery: graphqlUtils.getQuery("gallery", ["id", "title", "order", "type"]),
  listQuery: graphqlUtils.listQuery(
    "galleries",
    ["id", "title", "order", "type"],
    { search: "String" }
  ),
  createMutation: graphqlUtils.createMutation("createGallery", "GalleryInput"),
  updateMutation: graphqlUtils.updateMutation("updateGallery", "GalleryInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteGallery")
}

import React from "react"
import cx from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

const styles = theme => ({
  iconButton: {
    minWidth: "unset",
    "& svg": {
      fontSize: 16
    }
  },
  activeButton: {
    color: theme.palette.primary.main
  },
  inactiveButton: {
    color: theme.palette.grey[600]
  }
})

function ToolbarButton({
  classes,
  text,
  icon: Icon,
  isActive,
  onClick,
  onMouseDown
}) {
  return (
    <Button
      size="small"
      className={cx({
        [classes.iconButton]: Boolean(Icon),
        [classes.activeButton]: isActive,
        [classes.inactiveButton]: !isActive
      })}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {Icon ? <Icon /> : text}
    </Button>
  )
}

const StyledToolbarButton = withStyles(styles)(ToolbarButton)

export function InlineStyleButton({
  isActive,
  toggleInlineStyle,
  onMouseDown,
  ...props
}) {
  return (
    <StyledToolbarButton
      {...props}
      isActive={isActive}
      onClick={toggleInlineStyle}
      onMouseDown={onMouseDown}
    />
  )
}

export function BlockButton({ isActive, toggleBlockType, ...props }) {
  return (
    <StyledToolbarButton
      {...props}
      isActive={isActive}
      onClick={toggleBlockType}
    />
  )
}

export function PluginButton({ onClick, ...props }) {
  return <StyledToolbarButton {...props} onClick={onClick} />
}

import { t } from "i18next"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"

const styles = {
  root: {
    margin: 10
  },
  imageContainer: {
    width: 200,
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "100%"
    }
  }
}

function GridType({ classes, image, onClick }) {
  return (
    <Card classes={{ root: classes.root }}>
      <CardContent>
        <div className={classes.imageContainer}>
          <img src={image} alt="no description" />
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={onClick}>
          {t("common.select")}
        </Button>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(GridType)

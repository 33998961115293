import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Topic",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("topic", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("topics", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createTopic", "TopicInput"),
  updateMutation: graphqlUtils.updateMutation("updateTopic", "TopicInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteTopic"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTopics")
}

import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "TrimmingType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("trimmingType", ["id", "name"]),
  listQuery: graphqlUtils.listQuery("trimmingTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createTrimmingType",
    "TrimmingTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTrimmingType",
    "TrimmingTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTrimmingType"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTrimmingTypes")
}

import React from "react"
import MUITabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => {
  return {
    tabsRoot: {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey[100]
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main
    },
    tabRoot: {
      textTransform: "initial",
      fontSize: 16,
      padding: theme.spacing.unit,
      minWidth: 72,
      marginRight: theme.spacing.unit * 4,
      "&:hover": {
        color: theme.palette.primary.main
      },
      "&:focus": {
        color: theme.palette.primary.main
      }
    },
    tabSelected: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}

function Tabs({ tabIndex, classes, onChange, labels }) {
  return (
    <MUITabs
      value={tabIndex}
      onChange={onChange}
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
    >
      {labels.map(label => (
        <Tab
          key={label}
          label={label}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      ))}
    </MUITabs>
  )
}
export default withStyles(styles)(Tabs)

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Product",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("product", [
    "id",
    "name",
    "order",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("products", ["id", "name", "order"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createProduct", "ProductInput"),
  updateMutation: graphqlUtils.updateMutation("updateProduct", "ProductInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteProduct"),
  mergeMutation: graphqlUtils.mergeMutation("mergeProducts")
}

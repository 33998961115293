import React, { Fragment } from "react"
import { t } from "i18next"
import { Redirect } from "react-router-dom"
import Button from "@material-ui/core/Button"
import Mutation from "app/common/Mutation"
import AppBar from "app/common/AppBar"
import { getQueryKey } from "app/utils/graphql"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import MergeModal from "./MergeModal"
import Content from "./MergeModal/Content"

function MergeTagsButton({ mutation, buildUpdateHref, onError, labelKey }) {
  return (
    <MultiSelectConsumer>
      {({ items }) =>
        items.length >= 2 ? (
          <MergeModal>
            {closeModal => (
              <Fragment>
                <AppBar
                  title={t("mergeTags.modal.title")}
                  rightArea={
                    <Button onClick={closeModal} color="inherit">
                      {t("common.close")}
                    </Button>
                  }
                />
                <Mutation redirectOnError={false} mutation={mutation}>
                  {(merge, { data, error }) => {
                    const queryKey = getQueryKey(mutation)
                    const mergedTag = data && data[queryKey]

                    return mergedTag ? (
                      <Redirect push to={buildUpdateHref(mergedTag)} />
                    ) : (
                      <Content
                        items={items}
                        mutation={merge}
                        errorMessage={error && onError(error)}
                        labelKey={labelKey}
                      />
                    )
                  }}
                </Mutation>
              </Fragment>
            )}
          </MergeModal>
        ) : null
      }
    </MultiSelectConsumer>
  )
}

export default MergeTagsButton

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Países"
  },
  upsertPages: {
    createTitle: "Criar País",
    updateTitle: "Alterar País",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este país"
  }
}

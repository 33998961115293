import { t } from "i18next"
import React from "react"
import { withProps } from "recompose"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import { makeField } from "app/utils/forms"

function renderSwitchField({ input: { value, onChange }, label }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "24px",
        marginBottom: "8px"
      }}
    >
      <FormLabel>{label}</FormLabel>
      <FormControlLabel
        control={<Switch checked={value} onChange={onChange} color="primary" />}
        label={
          value
            ? t("common.formFields.switchOn")
            : t("common.formFields.switchOff")
        }
      />
    </div>
  )
}

export default withProps({ type: "checkbox" })(
  makeField(renderSwitchField, "SwitchField")
)

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import TranslationsFields from "app/common/TranslationsFields"

function Form() {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("regions.fields.name")}
        validate={validations.required()}
      />
      <TranslationsFields i18nBaseKey="regions.fields.name" />
    </Fragment>
  )
}

export default Form

import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import withImageItem from "app/common/ImageList/withImageItem"
import { compose } from "lodash/fp"
import CircularProgress from "@material-ui/core/CircularProgress"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  root: {
    userSelect: "none",
    padding: 10
  },
  image: {
    width: "100%"
  },
  tags: {
    padding: 10,
    wordWrap: "break-word",
    borderBottom: "1px solid #eee"
  },
  loadingWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
})

const DEFAULT_HEIGHT = 100

function Item({ classes, height, width, item }) {
  if (height === 0) {
    return (
      <div className={classes.root} style={{ height: DEFAULT_HEIGHT }}>
        <div className={classes.loadingWrapper}>
          <CircularProgress />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Card>
        <div style={{ width }}>
          <img
            src={imageSrcToNew(item.imageFile.source)}
            alt="alt"
            className={classes.image}
          />
          {item.tags.map((tag, index) => {
            return (
              <div key={index} className={classes.tags}>
                {tag}
              </div>
            )
          })}
        </div>
      </Card>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withImageItem
)(Item)

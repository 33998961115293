import { t } from "i18next"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ActionButton from "app/common/ActionButton"
import TechPackClassification from "../TechPackClassification"
import Form from "./Form"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    padding: "40px 80px"
  },
  addButton: {
    marginTop: 56,
    marginRight: 38
  },
  classificationsHeader: {
    fontSize: 12,
    fontWeight: 600,
    color: "#000000",
    marginTop: 41,
    textTransform: "uppercase"
  },
  classifications: {
    margin: "15px 0 78px"
  },
  form: {
    flexGrow: 1,
    padding: 10,
    marginRight: 57,
    maxWidth: 400
  },
  techPackWrapper: {
    minWidth: 640
  },
  techPack: {
    width: "100%"
  },
  techPackButtons: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: 20
  },
  changeTechPackButton: {
    borderRadius: 0,
    backgroundColor: "#575757",
    color: "#ffffff !important", // important is necessary because MUI add an disabled dark color
    minWidth: 40,
    "&:hover": {
      backgroundColor: "#575757D0"
    }
  },
  currentTechPack: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "36px",
    color: "#2d2d2d",
    width: 90
  }
}

function TechPackDialog({
  classes,
  items,
  item,
  classification,
  onClassificationSelected,
  unselectedClassification,
  onSubmit,
  selectItem
}) {
  const index = items.indexOf(item)

  return (
    <div className={classes.wrapper}>
      <div className={classes.form}>
        <div className={classes.classificationsHeader}>
          {t("techPackClassifications.addedClassifications")}
        </div>
        <div className={classes.classifications}>
          {item.techPackClassifications.map((techPackClassification, index) => (
            <TechPackClassification
              key={techPackClassification.id}
              index={index}
              classification={techPackClassification}
              onClassificationSelected={onClassificationSelected}
              selected={techPackClassification === classification}
            />
          ))}
        </div>
        <Form
          item={{ ...classification, techPackId: item.id }}
          onCancel={unselectedClassification}
          onSubmit={onSubmit}
        />
      </div>
      <ActionButton
        className={classes.addButton}
        onClick={unselectedClassification}
        disabled={!classification}
      >
        {t("techPackClassifications.addClassification")}
      </ActionButton>
      <div className={classes.techPackWrapper}>
        <div className={classes.techPackButtons}>
          <Button
            className={classes.changeTechPackButton}
            disabled={index === 0}
            onClick={() => selectItem(index - 1)}
          >
            <ChevronLeft />
          </Button>
          <div className={classes.currentTechPack}>{`(${index + 1}/${
            items.length
          })`}</div>
          <Button
            className={classes.changeTechPackButton}
            disabled={index === items.length - 1}
            onClick={() => selectItem(index + 1)}
          >
            <ChevronRight />
          </Button>
        </div>
        <img
          alt="no description"
          srcSet={imageSrcToNew(item.techPackFile.source, "large")}
          className={classes.techPack}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(TechPackDialog)

import React from "react"
import { compose } from "recompose"
import { withRouter } from "react-router-dom"
import { AppBar, Toolbar, withStyles } from "@material-ui/core"
import { t } from "i18next"
import Button from "app/common/Button"

const styles = theme => ({
  appBar: {
    bottom: 0,
    top: "auto",
    backgroundColor: "rgba(255, 255, 255, .9)"
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end"
  }
})

function PageFooter({ classes, children, returnHref, history }) {
  function handleBack() {
    history.push(returnHref())
  }

  return (
    <AppBar className={classes.appBar} component="div">
      <Toolbar className={classes.toolbar}>
        {returnHref && (
          <Button onClick={handleBack} text={t("common.cancel")} />
        )}
        {children}
      </Toolbar>
    </AppBar>
  )
}

export default compose(
  withStyles(styles),
  withRouter
)(PageFooter)

import { t } from "i18next"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Form from "app/common/Form"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import VideoPlayer from "../VideoPlayer"

function VideoDialog({ open, initialValues, onSubmit, onCancel }) {
  return (
    <Dialog open={open} disableEscapeKeyDown>
      <Form data-test="form" initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, form }) => {
          return (
            <Fragment>
              <DialogTitle>
                {t("reports.editor.contentTypes.video.dialog.title")}
              </DialogTitle>
              <DialogContent>
                <TextField
                  name="url"
                  label={t("reports.editor.contentTypes.video.fields.url")}
                  validate={validations.required()}
                />
                <VideoPlayer
                  url={values.url}
                  getDuration={duration => form.change("duration", duration)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel}>{t("common.cancel")}</Button>
                <Button type="submit" disabled={!values.duration}>
                  {t("common.save")}
                </Button>
              </DialogActions>
            </Fragment>
          )
        }}
      </Form>
    </Dialog>
  )
}

export default VideoDialog

import React from "react"
import { compose } from "recompose"
import TechPackDialog from "./TechPackDialog"
import { withProvider } from "./context"
import TechPackList from "./TechPackList"
import UploadDropzone from "./UploadDropzone"
import { withMultiSelectProvider } from "app/common/MultiSelectProvider"

function TechPacks({ match }) {
  return (
    <UploadDropzone techPackFolderId={match.params.id}>
      <TechPackList />
      <TechPackDialog />
    </UploadDropzone>
  )
}

export default compose(
  withMultiSelectProvider,
  withProvider
)(TechPacks)

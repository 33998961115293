/* eslint-disable camelcase */

export const GRID_1x1 = { imageNumber: 1, imageWidth: 1696, imageHeight: 1002 }

export const GRID_1x2 = { imageNumber: 2, imageWidth: 832, imageHeight: 1264 }

export const GRID_1x2_LANDSCAPE = {
  imageNumber: 2,
  imageWidth: 832,
  imageHeight: 492
}

export const GRID_1x3 = { imageNumber: 3, imageWidth: 544, imageHeight: 816 }

export const GRID_1x4 = { imageNumber: 4, imageWidth: 400, imageHeight: 400 }

export const GRID_1x4_PORTRAIT = {
  imageNumber: 4,
  imageWidth: 400,
  imageHeight: 802
}

export const SPECIAL_GRID_1 = [
  { gridArea: "left", imageHeight: 1144, imageWidth: 752 },
  { gridArea: "top-right", imageHeight: 536, imageWidth: 908 },
  { gridArea: "bottom-right", imageHeight: 536, imageWidth: 908 }
]

export const SPECIAL_GRID_2 = [
  { gridArea: "left", imageHeight: 1144, imageWidth: 752 },
  { gridArea: "top-center", imageHeight: 536, imageWidth: 438 },
  { gridArea: "top-right", imageHeight: 536, imageWidth: 438 },
  { gridArea: "bottom-right", imageHeight: 536, imageWidth: 908 }
]

import React, { Component, Fragment } from "react"
import { map, pipe, get, compose, filter } from "lodash/fp"
import { withApollo } from "react-apollo"
import Select from "app/common/formFields/Select"
import SetField from "app/common/formFields/Set"
import { getQueryKey } from "app/utils/graphql"
import { withField, relationIdField } from "app/utils/forms"
import { DEFAULT_FETCH_POLICY } from "app/utils/consts"
import * as entities from "app/entities"

function createParser(labelKey) {
  return {
    input: item =>
      item && {
        label: item[labelKey],
        value: item.id,
        originalItem: item
      },
    output: item => item && item.originalItem
  }
}

class EntitySelect extends Component {
  getEntity() {
    return entities[this.props.entity]
  }

  getParser() {
    return createParser(this.getEntity().labelKey)
  }

  loadOptions = search => {
    const { listQuery, entitySelectQueryVariables = {} } = this.getEntity()
    const queryKey = getQueryKey(listQuery)
    const parser = this.getParser()

    return this.props.client
      .query({
        query: listQuery,
        fetchPolicy: DEFAULT_FETCH_POLICY,
        variables: {
          page: 1,
          pageSize: 300,
          search,
          ...entitySelectQueryVariables
        }
      })
      .then(get(`data.${queryKey}.edges`))
      .then(filter(({ node: { id } }) => id !== this.props.idToHide))
      .then(
        map(
          pipe(
            get("node"),
            parser.input
          )
        )
      )
  }

  render() {
    const { entity, variables, field, isMulti, name, ...props } = this.props
    const parser = this.getParser()

    return (
      <Fragment>
        <Select
          {...props}
          name={name}
          isMulti={isMulti}
          type="async"
          format={parser.input}
          parse={parser.output}
          loadOptions={this.loadOptions}
        />
        <SetField
          name={relationIdField(name, isMulti)}
          value={isMulti ? map("id", field.value) : get("id", field.value)}
        />
      </Fragment>
    )
  }
}

export default compose(
  withApollo,
  withField(p => p.name, { prop: "field" })
)(EntitySelect)

import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { ImageClassification } from "app/entities"

function DestroyFolderClassificationsButton({
  imageFolderId,
  children,
  onDestroy
}) {
  return (
    <Mutation mutation={ImageClassification.folderDeleteImageClassification}>
      {destroy => {
        return (
          <Fragment>
            <ConfirmationDialog
              onConfirm={async () => {
                await destroy({ variables: { imageFolderId } })
                onDestroy()
              }}
              message={t(
                "imageClassifications.destroyFolderClassificationsMessage"
              )}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default DestroyFolderClassificationsButton

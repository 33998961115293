import React from "react"
import MUIModal from "@material-ui/core/Modal"
import { withStyles } from "@material-ui/core/styles"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "80vh"
  }
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column"
  }
})

function Modal({ isOpen, classes, children }) {
  return (
    <MUIModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
    >
      <div style={getModalStyle()} className={classes.paper}>
        {children}
      </div>
    </MUIModal>
  )
}

export default withStyles(styles)(Modal)

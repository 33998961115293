export function parseOutput({ maxUsers, unlimitedUsersAllowed, ...rest }) {
  return {
    ...rest,
    maxUsers: unlimitedUsersAllowed ? null : maxUsers
  }
}

export function parseInput({ maxUsers, ...rest }) {
  return {
    ...rest,
    maxUsers,
    unlimitedUsersAllowed: !Number.isInteger(maxUsers)
  }
}

import { t } from "i18next"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Mutation from "app/common/Mutation"
import { WgsnFolder } from "app/entities"
import { Link } from "react-router-dom"
import PreviewImagesModal from "./PreviewImagesModal"
import styled from "app/utils/styled"
import PreviewContent from "./PreviewImagesModal/PreviewContent"
import AppBar from "app/common/AppBar"

export default function ImportState({ value }) {
  return {
    NOT_IMPORTED: <ImportComponent value={value} />,
    IMPORTING: <Chip label={t("wgsnFolders.fields.importState.importing")} />,
    IMPORTED: <ImportedComponent value={value} />
  }[value.importState]
}

const Wrapper = styled("div", theme => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}))

function ImportComponent({ value }) {
  return (
    <Wrapper>
      <PreviewImagesModal>
        {({ closeModal }) => (
          <Fragment>
            <AppBar
              title={t("wgsnFolders.previewModal.title")}
              rightArea={
                <Button onClick={closeModal} color="inherit">
                  {t("common.close")}
                </Button>
              }
            />
            <PreviewContent wgsnFolder={value} />
          </Fragment>
        )}
      </PreviewImagesModal>
      <ImportButton id={value.id} />
    </Wrapper>
  )
}

function ImportButton({ id }) {
  return (
    <Mutation mutation={WgsnFolder.importFolderMutation}>
      {(importWgsnFolder, { loading }) => (
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => importWgsnFolder({ variables: { id } })}
        >
          {t("wgsnFolders.listPage.importLabelButton")}
        </Button>
      )}
    </Mutation>
  )
}

function ImportedComponent({ value }) {
  return value.imageFolder ? (
    <Button
      color="primary"
      component={Link}
      to={`/imageFolders/${value.imageFolder.id}/images`}
    >
      {t("wgsnFolders.listPage.categorize")}
    </Button>
  ) : (
    <Chip label={t("wgsnFolders.fields.importState.imported")} />
  )
}

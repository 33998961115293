export default {
  listPage: {
    title: "Publicações de Nas Lojas"
  },
  upsertPages: {
    createTitle: "Criar Publicação de Nas Lojas",
    updateTitle: "Alterar Publicação de Nas Lojas",
    genericErrorMessage: "Houve um problema ao salvar este publicação"
  }
}

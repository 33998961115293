import React from "react"
import { t } from "i18next"
import MUITable from "@material-ui/core/Table"
import Head from "@material-ui/core/TableHead"
import Body from "@material-ui/core/TableBody"
import MUICell from "@material-ui/core/TableCell"
import Row from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"

function Circle({ color }) {
  return (
    <div
      style={{
        display: "inline-block",
        borderRadius: "50%",
        backgroundColor: color,
        width: 20,
        height: 20
      }}
    />
  )
}
const Cell = withStyles(theme => ({
  root: {
    padding: "26px 100px !important"
  }
}))(MUICell)

const HeaderCell = withStyles(theme => ({
  root: {
    fontSize: "initial",
    textTransform: "uppercase"
  }
}))(Cell)

function Table({ rows }) {
  return (
    <MUITable>
      <Head>
        <Row>
          <HeaderCell />
          <HeaderCell align="left">
            {t("dashboard.columns.category")}
          </HeaderCell>
          <HeaderCell align="right">
            {t("dashboard.columns.quantity")}
          </HeaderCell>
          <HeaderCell align="right">%</HeaderCell>
        </Row>
      </Head>
      <Body>
        {rows.map(row => (
          <Row key={row.label}>
            <Cell align="center">
              <Circle color={row.color} />
            </Cell>
            <Cell>{row.label}</Cell>
            <Cell align="right">{row.count}</Cell>
            <Cell align="right">{row.percentage}</Cell>
          </Row>
        ))}
      </Body>
    </MUITable>
  )
}

export default Table

export default {
  fields: {
    name: "Nome",
    users: "Usuários",
    status: "Status",
    ips: "IPs permitidos",
    maxUsers: "Número máximo de logins simultâneos",
    unlimitedUsers: "Sem limite de logins simultâneos",
    accountStatus: "Status da conta",
    statusValues: {
      active: "Ativo",
      inactive: "Inativo"
    }
  },
  listPage: {
    title: "Contas"
  },
  upsertPages: {
    createTitle: "Criar Conta",
    updateTitle: "Alterar Conta",
    duplicateNameErrorMessage: "Esta conta já está cadastrada",
    genericErrorMessage: "Houve um problema ao salvar esta conta"
  }
}

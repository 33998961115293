import gql from "graphql-tag"

const getQuery = gql`
  query GetTechPackBackFile($id: Int!) {
    techPackBackFile(id: $id) {
      id
      source
    }
  }
`

const uploadUrl = gql`
  mutation TechPackBackFileUploadUrl($input: TechPackBackFileUploadUrlInput!) {
    techPackBackFileUploadUrl(input: $input) {
      key
      url
      headers {
        key
        value
      }
    }
  }
`

const createTechPackBackFile = gql`
  mutation CreateTechPackBackFile($input: CreateTechPackBackFileInput!) {
    createTechPackBackFile(input: $input) {
      id
      source
    }
  }
`

export default {
  name: "TechPackBackFile",
  getQuery,
  uploadUrl,
  createTechPackBackFile
}

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import NumericField from "app/common/formFields/Numeric"
import EnumSelectField from "app/common/formFields/EnumSelect"
import * as validations from "app/utils/validations"

function Form() {
  return (
    <Fragment>
      <TextField
        name="title"
        label={t("galleries.fields.title")}
        validate={validations.required()}
      />
      <NumericField
        name="order"
        label={t("galleries.fields.order")}
        validate={validations.required()}
      />
      <EnumSelectField
        name="type"
        enum="galleryType"
        label={t("galleries.fields.type")}
        validate={validations.required()}
      />
    </Fragment>
  )
}

export default Form

import { pipe, omit } from "lodash/fp"
import transformContentIntoContentInput from "app/utils/transformContentIntoContentInput"

export default pipe(
  omit([
    "publicationType",
    "cardImage",
    "season",
    "genders",
    "segments",
    "categories",
    "niches",
    "topics",
    "trends",
    "cities"
  ]),
  transformContentIntoContentInput
)

export default {
  from: "De",
  until: "Até",
  images: "Imagens",
  publications: "Publicações",
  tabsTitles: {
    images: "Dashboard Imagens",
    publications: "Dashboard Publicações",
    taxonomy: "Relatório de Taxonomia"
  },
  columns: {
    category: "Categoria",
    quantity: "Quantidade"
  },
  taxonomy: {
    description: "Planilha com informações das tags: tipo, nome e traduções.",
    action: "Extrair",
    downloadError: "Houve um erro ao fazer o download"
  },
  imagesRows: {
    clipping: "Clipping",
    catwalk: "Desfile",
    tradeshow: "Feiras",
    showcase: "Nas Lojas",
    street_style: "Uso nas Ruas",
    visual_merchandising: "Visual Merchandising",
    others: "Outras"
  },
  publicationsRows: {
    general_trends: "Direcionamento de Estilos",
    mega_trends: "Previsões de Tendências",
    trend_alerts: "Alerta de Tendências",
    catwalk_report: "Desfile",
    retail_report: "Nas Lojas",
    technical_area_report: "Área Técnica",
    trade_show_report: "Feiras"
  }
}

export default {
  addClassification: "Adicionar Classificação",
  addedClassifications: "Classificações adicionadas",
  applyToAllFolder: "Aplicar para todas as imagens desta pasta",
  brandStore: "Marca/Loja",
  cancelButtonLabel: "Cancelar",
  cannotDeleteImageGenericError: "Houve um erro ao excluir a imagem",
  cannotDeleteImageInUse: "Não é possível excluir, a imagem está em uso",
  cannotDeleteImagesGenericError: "Houve um erro ao excluir as imagens",
  cannotDeleteImagesInUse:
    "Não foi possível excluir as imagens, uma ou mais estão em uso",
  category: "Categoria",
  classify: "Classificar selecionadas",
  classifyImageFormTitle: "Classificar Imagem",
  classifyWholeFolder: "Classificar todas as imagens da pasta",
  color: "Cor",
  destroyButtonLabel: "Excluir",
  destroyClassificationsMessage:
    "Você tem certeza que deseja excluir as classificações? Esta ação não pode ser desfeita",
  destroyConfirmMessage:
    "Você tem certeza que deseja excluir esta classificação? Esta ação não pode ser desfeita",
  destroyFolderClassificationsMessage:
    "Você tem certeza que deseja excluir as classificações da pasta toda? Esta ação não pode ser desfeita",
  destroyImageButtonLabel: "Excluir Imagem",
  destroyImageConfirmMessage:
    "Você tem certeza que deseja excluir esta imagem? Esta ação não pode ser desfeita",
  destroyImagesButtonLabel: "Excluir Imagens",
  cleanRegionsButtonLabel: "Excluir Regiões",
  cleanRegionsConfirmMessage:
    "Você tem certeza que deseja excluir as regiões? Esta ação não pode ser desfeita",
  gender: "Gênero",
  regions: "Regiões",
  material: "Material",
  printType: "Tipo de estamparia",
  product: "Produto",
  removeFromWholeFolder: "Remover categorização de todas as imagens da pasta",
  removeSelected: "Remover categorização",
  saveButtonLabel: "Salvar",
  segment: "Segmento",
  selectAllImages: "Selecionar toda página ({{length}} imagens)",
  successMessage: "Salvo com sucesso",
  title: "Classificar imagem",
  topic: "Tópico",
  trend: "Em Alta",
  trendsV2: "Em Alta (V2)",
  trimmingType: "Tipo de aviamentos",
  unselectAllImages: "Deselecionar imagens ({{length}})",
  market: "Market"
}

import { get, pipe, omit } from "lodash/fp"
import { normalizeObject } from "app/utils/forms"

export const parseInput = normalizeObject({
  tag: ({ tagId, tagName, tagType }) => ({
    id: tagId,
    name: tagName,
    tagType
  }),
  cardImageId: get("cardImage.id")
})

export const parseOutput = pipe(
  normalizeObject({
    tagId: pipe(
      get("tag.id"),
      String
    ),
    tagName: get("tag.name"),
    tagType: get("tag.tagType")
  }),
  omit(["cardImage", "tag"])
)

export default {
  addClassification: "Adicionar Classificação",
  addedClassifications: "Classificações adicionadas",
  applyToAllFolder: "Aplicar para todas as tech packs desta pasta",
  brandStore: "Marca/Loja",
  cancelButtonLabel: "Cancelar",
  cannotDeleteTechPackGenericError: "Houve um erro ao excluir a tech pack",
  cannotDeleteTechPackInUse: "Não é possível excluir, a tech pack está em uso",
  cannotDeleteTechPacksGenericError: "Houve um erro ao excluir as tech packs",
  cannotDeleteTechPacksInUse:
    "Não foi possível excluir as tech packs, uma ou mais estão em uso",
  category: "Categoria",
  classify: "Classificar selecionadas",
  classifyTechPackFormTitle: "Classificar Tech Pack",
  classifyWholeFolder: "Classificar todas as tech packs da pasta",
  color: "Cor",
  destroyButtonLabel: "Excluir",
  editButtonLabel: "Editar",
  destroyClassificationsMessage:
    "Você tem certeza que deseja excluir as classificações? Esta ação não pode ser desfeita",
  destroyConfirmMessage:
    "Você tem certeza que deseja excluir esta classificação? Esta ação não pode ser desfeita",
  destroyFolderClassificationsMessage:
    "Você tem certeza que deseja excluir as classificações da pasta toda? Esta ação não pode ser desfeita",
  destroyTechPackButtonLabel: "Excluir Tech Pack",
  destroyTechPackConfirmMessage:
    "Você tem certeza que deseja excluir esta tech pack? Esta ação não pode ser desfeita",
  destroyTechPacksButtonLabel: "Excluir Tech Packs",
  cleanRegionsButtonLabel: "Excluir Regiões",
  cleanRegionsConfirmMessage:
    "Você tem certeza que deseja excluir as regiões? Esta ação não pode ser desfeita",
  gender: "Gênero",
  regions: "Regiões",
  material: "Material",
  printType: "Tipo de estamparia",
  product: "Produto",
  removeFromWholeFolder:
    "Remover categorização de todas as tech packs da pasta",
  removeSelected: "Remover categorização",
  saveButtonLabel: "Salvar",
  segment: "Segmento",
  selectAllTechPacks: "Selecionar toda página ({{length}} tech packs)",
  successMessage: "Salvo com sucesso",
  title: "Classificar tech pack",
  topic: "Tópico",
  trend: "Em Alta",
  trimmingType: "Tipo de aviamentos",
  unselectAllTechPacks: "Deselecionar tech packs ({{length}})",
  market: "Market"
}

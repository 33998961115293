import React from "react"
import imageSrcToNew from "app/utils/imageSrcToNew"

function RoundImage({ classes, value, size = 72 }) {
  return (
    <img
      srcSet={imageSrcToNew(value, "small")}
      alt="no description"
      style={{
        borderRadius: "50%",
        width: size,
        height: size
      }}
    />
  )
}

export default RoundImage

import { get } from "lodash/fp"
import React from "react"
import { t } from "i18next"
import SelectField from "app/common/formFields/Select"

const format = tag => ({
  label: tag,
  value: tag
})

function TagField({ placeholder = t("common.tagFieldPlaceholder"), ...props }) {
  return (
    <SelectField
      {...props}
      type="creatable"
      format={format}
      parse={get("value")}
    />
  )
}

export default TagField

import { t } from "i18next"
import React, { Component } from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import { withIndependentReactTree } from "app/common/IndependentReactTree"

function makeBlockComponent({ type, dialog: Dialog, preview: Preview }) {
  /*
   * We need to render the dialog in another
   * react tree to avoid problems with draftjs
   * and other react-final-form instances
   */

  const IndependentTreeDialog = withIndependentReactTree(Dialog)

  return class BlockComponent extends Component {
    state = {
      dialogOpen: this.props.block.getData().get("empty")
    }

    componentDidMount() {
      /*
       * Our custom block renderer requires mouse interaction,
       * it is necessary to temporarily set your Editor to
       * readOnly mode during this interaction. In this way,
       * the user does not trigger any selection changes within
       * the editor while interacting with our custom block.
       *
       * More info here: http://bit.ly/2KEc8pH
       */
      this.props.blockProps.setReadOnly(this.state.dialogOpen)
    }

    openDialog = () => {
      this.props.blockProps.setReadOnly(true)
      this.setState({ dialogOpen: true })
    }

    closeDialog = () => {
      this.props.blockProps.setReadOnly(false)
      this.setState({ dialogOpen: false })
    }

    onSubmit = values => {
      this.props.blockProps.change({
        ...values,
        empty: false
      })

      this.closeDialog()
    }

    onCancel = () => {
      this.closeDialog()

      if (this.props.block.getData().get("empty")) {
        this.props.blockProps.remove()
      }
    }

    onRemove = event => {
      /*
       * Note: important to avoid a content edit change
       */

      event.stopPropagation()
      event.preventDefault()
      this.props.blockProps.remove()
    }

    render() {
      const { dialogOpen } = this.state
      const { block } = this.props
      const data = block.getData().toJS()

      return (
        <div>
          {data.empty !== true ? (
            <Card>
              <Preview data-test="preview" data={data} />
              <CardActions>
                <Button size="small" color="primary" onClick={this.openDialog}>
                  {t("common.edit")}
                </Button>
                <Button size="small" color="primary" onClick={this.onRemove}>
                  {t("common.remove")}
                </Button>
              </CardActions>
            </Card>
          ) : (
            <div />
          )}
          {dialogOpen && (
            <IndependentTreeDialog
              open
              data-test="dialog"
              initialValues={data}
              onSubmit={this.onSubmit}
              onCancel={this.onCancel}
            />
          )}
        </div>
      )
    }
  }
}

export default makeBlockComponent

import { t } from "i18next"
import React, { createRef } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import CropImage from "app/common/CropImage"

function CropDialog({
  file,
  onCrop,
  onClose,
  aspectRatio,
  cropImageRef = createRef()
}) {
  return (
    <Dialog
      data-test="dialog"
      onClose={onClose}
      open={Boolean(file)}
      fullWidth
      disableBackdropClick
    >
      <DialogTitle>
        {t("common.formFields.imageFile.cropDialog.title")}
      </DialogTitle>
      <DialogContent data-test="content">
        <div style={{ height: "60vh" }}>
          <CropImage
            data-test="crop-image"
            file={file}
            aspectRatio={aspectRatio}
            ref={cropImageRef}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" data-test="cancel-button">
          {t("common.cancel")}
        </Button>
        <Button
          data-test="crop-button"
          onClick={async () => {
            const file = await cropImageRef.current.getFile()
            onCrop(file)
          }}
          color="primary"
          disabled={false}
        >
          {t("common.formFields.imageFile.cropDialog.crop")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CropDialog

import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { TechPackClassification } from "app/entities"

function DestroyTechPackClassificationButton({ id, children }) {
  return (
    <Mutation mutation={TechPackClassification.destroyMutation}>
      {destroy => {
        return (
          <Fragment>
            <ConfirmationDialog
              onConfirm={() => destroy({ variables: { id } })}
              message={t("techPackClassifications.destroyConfirmMessage")}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default DestroyTechPackClassificationButton

import { t } from "i18next"
import React from "react"
import * as entities from "app/entities"
import ListPage from "app/common/ListPage"
import CreatePage from "app/common/CreatePage"
import UpdatePage from "app/common/UpdatePage"
import MergeableListPage from "app/common/MergeableListPage"

function createCRUD({
  entity: entityName,
  defaultCellStyle,
  pathPreffix,
  i18nKey,
  listColumns,
  upsertForm: UpsertForm,
  create,
  update,
  upsert,
  hasSearch,
  hasMerge
}) {
  const path = path => `/${pathPreffix}${path || ""}`

  const {
    getQuery,
    listQuery,
    createMutation,
    updateMutation,
    destroyMutation,
    mergeMutation,
    labelKey,
    listQueryVariables = {}
  } = entities[entityName]

  const listPageProps = {
    title: t(`${i18nKey}.listPage.title`),
    query: listQuery,
    createHref: path("/create"),
    variables: listQueryVariables,
    buildUpdateHref: node => `/${pathPreffix}/${node.id}/update`,
    columns: listColumns,
    defaultCellStyle,
    hasSearch
  }

  const createPageProps = {
    title: t(`${i18nKey}.upsertPages.createTitle`),
    mutation: createMutation,
    ...create
  }

  const updatePageProps = {
    title: t(`${i18nKey}.upsertPages.updateTitle`),
    query: getQuery,
    updateMutation: updateMutation,
    destroyMutation: destroyMutation,
    ...update
  }

  const upsertPageProps = {
    returnHref: () => path(),
    errorMessage: t(`${i18nKey}.upsertPages.genericErrorMessage`),
    ...upsert
  }

  function GeneratedListPage(props) {
    if (hasMerge) {
      return (
        <MergeableListPage
          mergeMutation={mergeMutation}
          onError={upsert.errorMessage}
          labelKey={labelKey}
          {...listPageProps}
          {...props}
        />
      )
    }

    return <ListPage {...listPageProps} {...props} />
  }

  function GeneratedCreatePage(props) {
    return (
      <CreatePage {...upsertPageProps} {...createPageProps} {...props}>
        <UpsertForm {...props} create />
      </CreatePage>
    )
  }

  function GeneratedUpdatePage(props) {
    const { id } = props.match.params

    return (
      <UpdatePage id={id} {...upsertPageProps} {...updatePageProps} {...props}>
        <UpsertForm {...props} update />
      </UpdatePage>
    )
  }

  GeneratedListPage.displayName = `${entityName}List
  `
  GeneratedCreatePage.displayName = `${entityName}Update`

  GeneratedUpdatePage.displayName = `${entityName}Update`

  return {
    ListPage: GeneratedListPage,
    CreatePage: GeneratedCreatePage,
    UpdatePage: GeneratedUpdatePage
  }
}

export default createCRUD

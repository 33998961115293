import { compose } from "lodash/fp"
import React, { Component } from "react"
import Query from "app/common/Query"
import UploadImage from "app/common/UploadImage"
import { ImageFile } from "app/entities"
import { withField } from "app/utils/forms"
import * as allGridSettings from "../../gridSettings"
import Layout from "./Layout"
import Content from "./Content"
import imageSrcToNew from "app/utils/imageSrcToNew"

class CropSelectedImage extends Component {
  state = {
    selectedImageBlob: null
  }

  componentDidMount() {
    fetch(imageSrcToNew(this.props.selectedImageFile.source))
      .then(response => response.blob())
      .then(selectedImageBlob => {
        if (!this.unmounted) {
          this.setState({ selectedImageBlob })
        }
      })
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  onSave = upload => async () => {
    const {
      selectedImage,
      setView,
      source,
      imageId,
      imageFileId,
      caption
    } = this.props

    const file = await this.cropImageRef.getFile()

    const uploadImageFile = await upload(file)

    imageId.setValue(selectedImage.id)
    source.setValue(uploadImageFile.source)
    imageFileId.setValue(uploadImageFile.id)

    caption.setValue(selectedImage.title)

    setView("ImageGrid")
  }

  onMirrorHorizontally = () => {
    const { scaleX, scaleY } = this.cropImageRef.cropper.getImageData()
    this.cropImageRef.cropper.scale(scaleX * -1, scaleY)
  }

  onCancel = () => {
    this.props.setView("ImageGrid")
  }

  render() {
    const { gridType, imageIndex } = this.props
    const { selectedImageBlob } = this.state
    let gridSettings = allGridSettings[gridType.value]

    if (Array.isArray(gridSettings)) {
      gridSettings = gridSettings[imageIndex]
    }

    const cropInfo = {
      aspectRatio: gridSettings.imageWidth / gridSettings.imageHeight
    }

    return (
      <UploadImage>
        {(upload, { loading }) => (
          <Layout
            onSave={this.onSave(upload)}
            onMirrorHorizontally={this.onMirrorHorizontally}
            onCancel={this.onCancel}
            loading={loading}
          >
            <Content
              imageFile={selectedImageBlob}
              cropInfo={cropInfo}
              cropImageRef={ref => {
                this.cropImageRef = ref
              }}
            />
          </Layout>
        )}
      </UploadImage>
    )
  }
}

function withImageFile(Component) {
  return props => (
    <Query
      query={ImageFile.getQuery}
      variables={{ id: props.selectedImage.imageFileId }}
    >
      {({ data }) =>
        data && data.imageFile ? (
          <Component {...props} selectedImageFile={data.imageFile} />
        ) : (
          <Layout loading />
        )
      }
    </Query>
  )
}

export default compose(
  withField("gridType"),
  withField(p => `images[${p.imageIndex}].imageFile.source`, {
    prop: "source"
  }),
  withField(p => `images[${p.imageIndex}].imageId`, { prop: "imageId" }),
  withField(p => `images[${p.imageIndex}].imageFileId`, {
    prop: "imageFileId"
  }),
  withField(p => `images[${p.imageIndex}].caption`, {
    prop: "caption"
  }),
  withImageFile
)(CropSelectedImage)

import gql from "graphql-tag"

const getQuery = gql`
  query GetTechPackTechnicalFile($id: Int!) {
    techPackTechnicalFile(id: $id) {
      id
      source
    }
  }
`

const uploadUrl = gql`
  mutation TechPackTechnicalFileUploadUrl(
    $input: TechPackTechnicalFileUploadUrlInput!
  ) {
    techPackTechnicalFileUploadUrl(input: $input) {
      key
      url
      headers {
        key
        value
      }
    }
  }
`

const createTechPackTechnicalFile = gql`
  mutation CreateTechPackTechnicalFile(
    $input: CreateTechPackTechnicalFileInput!
  ) {
    createTechPackTechnicalFile(input: $input) {
      id
      source
    }
  }
`

export default {
  name: "TechPackTechnicalFile",
  getQuery,
  uploadUrl,
  createTechPackTechnicalFile
}

import { t } from "i18next"
import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { InlineDatePicker } from "material-ui-pickers"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  chevron: {
    color: theme.palette.grey[500],
    fontSize: 24,
    margin: "0 30px"
  },
  input: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: 12,
    borderRadius: 3,
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing.unit}px 0`,
    marginTop: theme.spacing.unit / 2,
    textAlign: "center"
  },
  label: {
    textTransform: "uppercase",
    fontSize: 16
  }
})

function DateRangePicker({ defaultRange, onChange, classes }) {
  const [startDate, handleStartDateChange] = useState(defaultRange[0])
  const [endDate, handleEndDateChange] = useState(defaultRange[1])

  useEffect(() => {
    onChange([startDate, endDate])
  }, [startDate, endDate])

  return (
    <Grid container alignItems="flex-end">
      <Grid item>
        <InlineDatePicker
          onlyCalendar
          label={t("dashboard.from")}
          format={t("common.dateFormat")}
          onChange={handleStartDateChange}
          value={startDate}
          InputProps={{
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label
            }
          }}
        />
      </Grid>

      <Grid item>
        <ChevronRightIcon className={classes.chevron} />
      </Grid>

      <Grid item>
        <InlineDatePicker
          onlyCalendar
          label={t("dashboard.until")}
          format={t("common.dateFormat")}
          onChange={handleEndDateChange}
          value={endDate}
          InputProps={{
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label
            }
          }}
        />
      </Grid>
    </Grid>
  )
}

export function withDateRangeFromSearch(Component) {
  return props => {
    const { search, setSearch } = props

    const endDate = search.endDate ? new Date(search.endDate) : new Date()

    const startDate = search.startDate
      ? new Date(search.startDate)
      : (() => {
          const date = new Date(endDate)
          date.setMonth(date.getMonth() - 1)
          return date
        })()

    function setDateRange([startDate, endDate]) {
      setSearch({ startDate, endDate })
    }

    return (
      <Component
        {...props}
        setDateRange={setDateRange}
        dateRange={[startDate, endDate]}
      />
    )
  }
}

export default withStyles(styles)(DateRangePicker)

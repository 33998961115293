export default {
  back: "Voltar",
  cancel: "Cancelar",
  clearLabel: "Limpar",
  close: "Fechar",
  dateFormat: "dd/MM/yyyy",
  dateTimeFormat: "dd/MM/yyyy - HH:mm",
  edit: "Editar",
  logout: "Logout",
  name: "Nome",
  ok: "Ok",
  remove: "Remover",
  save: "Salvar",
  searchFieldPlaceholder: "Digite aqui para pesquisar",
  select: "Selecionar",
  selectFieldLoading: "Carregando opções",
  selectFieldNoOptionsMessage: "Nenhuma opção encontrada",
  selectFieldPlaceholder: "Selecione...",
  selectFieldStartTyping: "Comece a digitar para ver as opcões disponíveis",
  siteTitle: "START by WGSN - Admin",
  tagFieldPlaceholder: "Digite para criar um novo item",
  upload: "Enviar",
  selectValidFile: "Selecione um arquivo válido",
  languages: {
    inEnglish: "em inglês",
    inSpanish: "em espanhol"
  },
  menu: {
    publications: "Publicações",
    upsertTags: "Criação/Edição de Tags",
    accounts: "Contas",
    administrators: "Administradores",
    authors: "Autores",
    booths: "Estandes",
    brands: "Marcas/Lojas",
    categories: "Categorias",
    niches: "Nichos",
    catwalkEvents: "Eventos de Desfile",
    catwalkReports: "Publicações de Desfiles",
    cities: "Cidades",
    colors: "Cores",
    countries: "Países",
    dailyContents: "O que você precisa saber",
    dailyImages: "Banner Home",
    galleries: "Galerias",
    genders: "Gêneros",
    regions: "Regiões",
    generalTrendTypes: "Tipos de Direcionamento de Estilo",
    imageFolders: "Imagens",
    techPackFolders: "Tech Packs",
    images: "Imagens",
    materials: "Materiais",
    megaTrendTypes: "Tipos de Previsões de Tendências",
    printTypes: "Estampas",
    products: "Produtos",
    recommendedTags: "Tags Recomendadas",
    retailReports: "Publicações de Nas Lojas",
    seasons: "Temporadas",
    segments: "Segmentos",
    storeTypes: "Tipos de Loja",
    stylists: "Estilistas",
    technicalAreaReports: "Publicações de Área Técnica",
    technicalAreaType: "Tipos de Áreas Técnicas",
    topics: "Tópicos",
    tradeShowEvents: "Eventos de Feira",
    tradeShowReports: "Publicações de Feiras",
    tradeShowTypes: "Tipos de Feira",
    trendReports: "Publicações de Tendências",
    trends: "Em Alta",
    trendsV2: "Em Alta (V2)",
    trimmingTypes: "Aviamentos",
    users: "Usuários",
    wgsnFolders: "Importação WGSN"
  },
  validations: {
    required: "Requerido",
    isEmail: "Endereço de e-mail inválido",
    minLength: "Este campo deve ter no mínimo {{length}} caracteres",
    color: "Código de cor inválido",
    duplicatedName: "Este nome já está cadastrado"
  },
  tablePagination: {
    displayedRows: "{{from}}-{{to}} de {{count}}",
    rowsPerPage: "Itens por página"
  },
  formFields: {
    switchOn: "Ativado",
    switchOff: "Desativado",
    imageFile: {
      cropDialog: {
        title: "Cortar imagem",
        crop: "Cortar"
      }
    },
    file: {
      choose: "Escolher arquivo",
      loading: "Salvando...",
      loadingValidationErrorMessage: "Aguarde o upload terminar"
    },
    wgsnNames: "Nomes em inglês na WGSN"
  }
}

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Estandes"
  },
  upsertPages: {
    createTitle: "Criar Estande",
    updateTitle: "Alterar Estande",
    duplicateNameErrorMessage: "Estande já cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este estande"
  }
}

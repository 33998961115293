import { t } from "i18next"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ActionButton from "app/common/ActionButton"
import ImageClassification from "../ImageClassification"
import Form from "./Form"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    padding: "40px 80px"
  },
  addButton: {
    marginTop: 56,
    marginRight: 38
  },
  classificationsHeader: {
    fontSize: 12,
    fontWeight: 600,
    color: "#000000",
    marginTop: 41,
    textTransform: "uppercase"
  },
  classifications: {
    margin: "15px 0 78px"
  },
  form: {
    flexGrow: 1,
    padding: 10,
    marginRight: 57,
    maxWidth: 400
  },
  imageWrapper: {
    minWidth: 640
  },
  image: {
    width: "100%"
  },
  imageButtons: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: 20
  },
  changeImageButton: {
    borderRadius: 0,
    backgroundColor: "#575757",
    color: "#ffffff !important", // important is necessary because MUI add an disabled dark color
    minWidth: 40,
    "&:hover": {
      backgroundColor: "#575757D0"
    }
  },
  currentImage: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    lineHeight: "36px",
    color: "#2d2d2d",
    width: 90
  }
}

function ImageDialog({
  classes,
  items,
  item,
  classification,
  onClassificationSelected,
  unselectedClassification,
  onSubmit,
  selectItem
}) {
  const index = items.indexOf(item)

  return (
    <div className={classes.wrapper}>
      <div className={classes.form}>
        <div className={classes.classificationsHeader}>
          {t("imageClassifications.addedClassifications")}
        </div>
        <div className={classes.classifications}>
          {item.imageClassifications.map((imageClassification, index) => (
            <ImageClassification
              key={imageClassification.id}
              index={index}
              classification={imageClassification}
              onClassificationSelected={onClassificationSelected}
              selected={imageClassification === classification}
            />
          ))}
        </div>
        <Form
          item={{ ...classification, imageId: item.id }}
          onCancel={unselectedClassification}
          onSubmit={onSubmit}
        />
      </div>
      <ActionButton
        className={classes.addButton}
        onClick={unselectedClassification}
        disabled={!classification}
      >
        {t("imageClassifications.addClassification")}
      </ActionButton>
      <div className={classes.imageWrapper}>
        <div className={classes.imageButtons}>
          <Button
            className={classes.changeImageButton}
            disabled={index === 0}
            onClick={() => selectItem(index - 1)}
          >
            <ChevronLeft />
          </Button>
          <div className={classes.currentImage}>{`(${index + 1}/${
            items.length
          })`}</div>
          <Button
            className={classes.changeImageButton}
            disabled={index === items.length - 1}
            onClick={() => selectItem(index + 1)}
          >
            <ChevronRight />
          </Button>
        </div>
        <img
          alt="no description"
          srcSet={imageSrcToNew(item.imageFile.source, "large")}
          className={classes.image}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(ImageDialog)

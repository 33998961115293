export default function(src, size) {
  const urlsToIgnore = new RegExp("usefashion|localhost|d2iodbcwmvquww")

  if (!src || urlsToIgnore.test(src)) {
    return src
  }

  const { pathname } = new URL(src)
  const cloudFrontStart = "https://d3jr3q7acx4e44.cloudfront.net/storage"

  const newSrc = new URL(cloudFrontStart + pathname)
  size && newSrc.searchParams.set("size", size)

  return newSrc
}

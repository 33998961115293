import gql from "graphql-tag"

export default gql`
  query techPacks($page: Int!, $pageSize: Int, $techPackFolderId: Int) {
    techPacks(
      page: $page
      pageSize: $pageSize
      techPackFolderId: $techPackFolderId
    ) {
      totalCount
      pageInfo {
        pageCount
      }
      edges {
        node {
          id
          title
          complete
          techPackFileId
          techPackFile {
            source
            sizes {
              small
            }
          }
          techPackClassifications {
            id
            market
            product {
              id
              name
            }
            gender {
              id
              name
            }
            regions {
              id
              name
            }
            segment {
              id
              name
            }
            category {
              id
              name
            }
            color {
              id
              name
            }
            material {
              id
              name
            }
            printType {
              id
              name
            }
            trimmingType {
              id
              name
            }
            brand {
              id
              name
            }
            topic {
              id
              name
            }
            trend {
              id
              name
            }
          }
        }
      }
    }
  }
`

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import ImageFileField from "app/common/formFields/ImageFile"

function Form() {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("authors.fields.name")}
        validate={validations.required()}
      />
      <TextField
        name="role"
        label={t("authors.fields.role")}
        validate={validations.required()}
      />
      <ImageFileField
        aspectRatio={1}
        initialSourceFieldName="avatar.source"
        name="avatarId"
        label={t("authors.fields.avatar")}
        validate={validations.required()}
      />
    </Fragment>
  )
}

export default Form

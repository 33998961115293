import gql from "graphql-tag"
import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "WgsnFolder",
  getQuery: graphqlUtils.getQuery("wgsnFolder", [
    "id",
    "publicationDate",
    "importState",
    "category",
    "season",
    "city",
    "event",
    "brand",
    "retailer",
    "classification"
  ]),
  listQuery: graphqlUtils.listQuery(
    "wgsnFolders",
    [
      "id",
      "importState",
      "category",
      "season",
      "event",
      "city",
      "brand",
      "imageCount",
      "imageUrls",
      "imageUrlsWithTags { url, tags }",
      ["imageFolder", ["id"]],
      ["importedBy", ["name"]]
    ],
    {
      search: "String"
    }
  ),
  importFolderMutation: gql`
    mutation ImportWgsnFolder($id: Int!) {
      importWgsnFolder(id: $id) {
        id
        importState
        importedBy {
          name
        }
      }
    }
  `
}

import { compose, get } from "lodash/fp"
import React, { createRef, Component } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry
} from "react-virtualized"
import { withStyles } from "@material-ui/core/styles"
import { withImageCache } from "app/common/ImageCache"

const COLUMN_WIDTH = 320
const DEFAULT_HEIGHT = 250

const styles = {
  root: {
    margin: "0 auto",
    outline: "none"
  }
}

class ImageList extends Component {
  masonryRef = createRef()

  constructor() {
    super()

    this.cache = new CellMeasurerCache({
      defaultHeight: DEFAULT_HEIGHT,
      defaultWidth: COLUMN_WIDTH,
      fixedWidth: true,
      keyMapper: this.keyMapper
    })
    this.cellPositioner = createMasonryCellPositioner({
      cellMeasurerCache: this.cache,
      columnCount: 1,
      columnWidth: COLUMN_WIDTH,
      spacer: 0
    })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.width !== this.props.width ||
      prevProps.items !== this.props.items
    ) {
      this.updateLayout()
    }
  }

  updateLayout = () => {
    this.cellPositioner.reset({
      columnCount: Math.floor(this.props.width / COLUMN_WIDTH),
      columnWidth: COLUMN_WIDTH,
      spacer: 0
    })

    this.masonryRef.current.recomputeCellPositions()
  }

  keyMapper = index => {
    /*
     * Sometimes the masonry calls the key mapper with an index bigger than the current count
     * I couldn't find out why this was happening, the code below prevent the
     * exception from accessing an invalid index and return an unique new identifier
     */

    const { items } = this.props
    return index < items.length ? items[index].id : index * -1
  }

  cellRenderer = ({ index, key, parent, style }) => {
    const { items, itemComponent: Item, ...restProps } = this.props
    /*
     * Sometimes the masonry calls the key mapper with an index bigger than the current count
     * I couldn't find out why this was happening, but the code below prevent the
     * render of invalid items
     */

    if (index >= items.length) {
      return null
    }

    const item = items[index]

    return (
      <CellMeasurer cache={this.cache} index={index} key={key} parent={parent}>
        {({ measure }) => (
          <div style={style}>
            <Item
              item={item}
              imageCache={this.props.imageCache}
              columnWidth={COLUMN_WIDTH}
              defaultHeight={DEFAULT_HEIGHT}
              measure={measure}
              updateLayout={this.updateLayout}
              {...restProps}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  render() {
    const { items, width, height, classes, headerHeight } = this.props
    const columnCount = Math.floor(width / COLUMN_WIDTH)

    return (
      <Masonry
        ref={this.masonryRef}
        className={classes.root}
        cellCount={items.length}
        cellMeasurerCache={this.cache}
        cellPositioner={this.cellPositioner}
        cellRenderer={this.cellRenderer}
        height={height - (headerHeight || 0)}
        width={columnCount * COLUMN_WIDTH}
        keyMapper={this.keyMapper}
      />
    )
  }
}

function withAutoSizer(Component) {
  return props => (
    <AutoSizer style={{ width: "100%" }}>
      {({ height, width }) => (
        <Component {...props} height={height} width={width} />
      )}
    </AutoSizer>
  )
}

export default compose(
  withStyles(styles),
  withAutoSizer,
  withImageCache({
    getKey: get("id"),
    getSrcset: get("imageFile.source")
  })
)(ImageList)

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import SetField from "app/common/formFields/Set"
import EntitySelectField from "app/common/formFields/EntitySelect"
import EnumSelectField from "app/common/formFields/EnumSelect"
import SwitchField from "app/common/formFields/Switch"
import * as validations from "app/utils/validations"

function Form({ create, accountId }) {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("users.fields.name")}
        type="name"
        validate={validations.composeValidators(validations.required())}
      />
      <TextField
        name="email"
        label={t("users.fields.email")}
        type="email"
        validate={validations.composeValidators(
          validations.required(),
          validations.isEmail()
        )}
      />
      <TextField
        name="password"
        label={t("users.fields.password")}
        type="password"
        nullify={false}
        validate={validations.composeValidators(
          create ? validations.required() : null,
          validations.minLength(6)
        )}
      />
      <SwitchField name="active" label={t("users.fields.active")} />
      {create && accountId ? (
        <SetField name="accountId" value={accountId} />
      ) : (
        <EntitySelectField
          name="account"
          entity="Account"
          label={t("users.fields.account")}
          validate={validations.required()}
        />
      )}
      <EnumSelectField
        isMulti={false}
        name="clientVersion"
        enum="clientVersion"
        label={t("users.fields.clientVersion")}
        validate={validations.required()}
      />
    </Fragment>
  )
}

export default Form

import { EditorState, SelectionState } from "draft-js"

function remove(editorState, block) {
  const blockKey = block.getKey()
  const selection = editorState.getSelection()
  const content = editorState.getCurrentContent()
  const keyAfter = content.getKeyAfter(blockKey)

  /*
   * We are interacting with the Immutable JS api,
   * this is not recommended, we should use the draft-js
   * public api.
   *
   * This code was adapted from http://bit.ly/2KHVW6V
   */
  const blockMap = content.getBlockMap().delete(blockKey)
  const withoutAtomicBlock = content.merge({
    blockMap,
    selectionAfter: selection
  })

  const newState = EditorState.push(
    editorState,
    withoutAtomicBlock,
    "remove-range"
  )

  // if this is not the last block
  if (keyAfter) {
    const newSelection = new SelectionState({
      anchorKey: keyAfter,
      anchorOffset: 0,
      focusKey: keyAfter,
      focusOffset: block.getLength()
    })
    const newEditorState = EditorState.forceSelection(newState, newSelection)
    return newEditorState
  }

  return newState
}

export default remove

import gql from "graphql-tag"

const getQuery = gql`
  query GetImageFile($id: Int!) {
    imageFile(id: $id) {
      id
      source
    }
  }
`

const uploadUrl = gql`
  mutation ImageFileUploadUrl($input: ImageFileUploadUrlInput!) {
    imageFileUploadUrl(input: $input) {
      key
      url
      headers {
        key
        value
      }
    }
  }
`

const createImageFile = gql`
  mutation CreateImageFile($input: CreateImageFileInput!) {
    createImageFile(input: $input) {
      id
      source
    }
  }
`

export default {
  name: "ImageFile",
  getQuery,
  uploadUrl,
  createImageFile
}

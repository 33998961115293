import { t } from "i18next"
import DatePrinter from "app/common/DatePrinter"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Season",
  pathPreffix: "seasons",
  i18nKey: "seasons",
  listColumns: [
    { header: t("seasons.fields.name"), path: "name" },
    {
      header: t("seasons.fields.publicationDate"),
      path: "publicationDate",
      component: DatePrinter
    }
  ],
  upsertForm: Form,
  upsert: {
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("seasons.upsertPages.duplicateNameErrorMessage")
        : t("seasons.upsertPages.genericErrorMessage")
  },
  hasSearch: true,
  hasMerge: true
})

export { ListPage, CreatePage, UpdatePage }

import { assoc, chunk } from "lodash/fp"
import React, { createContext, Component } from "react"
import UploadImage from "app/common/UploadImage"
import Mutation from "app/common/Mutation"
import { Image } from "app/entities"

const { Provider, Consumer } = createContext({})

function parallelLimit(limit, tasks) {
  const chunks = chunk(limit, tasks)

  return chunks.reduce(async (previous, chunk) => {
    await previous
    return Promise.all(chunk.map(f => f()))
  }, Promise.resolve())
}

class UploadProvider extends Component {
  state = {
    loading: false,
    loadingMap: {},
    complete: false
  }

  makeUploadFile = (file, index) => async () => {
    this.setState(({ loadingMap }) => ({
      loadingMap: assoc(index, true, loadingMap)
    }))

    const createImageFile = await this.props.uploadImage(file)

    this.props
      .createImage({
        variables: {
          input: {
            imageFileId: createImageFile.id,
            imageFolderId: Number(this.props.imageFolderId)
          }
        }
      })
      .then(() => {
        this.setState(({ loadingMap }) => ({
          loadingMap: assoc(index, false, loadingMap)
        }))
      })
  }

  uploadFiles = async () => {
    this.setState({ loading: true })

    await parallelLimit(5, this.props.files.map(this.makeUploadFile))

    this.setState({
      loading: false,
      complete: true
    })

    this.props.onAllImagesUpload()
  }

  render() {
    const { children, files } = this.props

    const value = {
      ...this.state,
      upload: this.uploadFiles,
      files
    }

    return <Provider value={value}>{children(value)}</Provider>
  }
}

function withMutations(Component) {
  return props => (
    <UploadImage>
      {uploadImage => (
        <Mutation mutation={Image.createMutation} update={props.onImageAdded}>
          {createImage => (
            <Component
              uploadImage={uploadImage}
              createImage={createImage}
              {...props}
            />
          )}
        </Mutation>
      )}
    </UploadImage>
  )
}

const UploadProviderWithMutations = withMutations(UploadProvider)

export {
  UploadProviderWithMutations as UploadProvider,
  Consumer as UploadConsumer
}

import { useCallback, useState } from "react"
import gql from "graphql-tag"

import { DEFAULT_FETCH_POLICY } from "app/utils/consts"

const QUERY = gql`
  query File($id: Int!) {
    techPackTechnicalFile(id: $id) {
      id
      source
    }
  }
`

const useTechPackTechnicalFile = client => {
  const [data, setData] = useState(null)

  const get = useCallback(
    async ({ variables }) => {
      const response = await client.query({
        query: QUERY,
        fetchPolicy: DEFAULT_FETCH_POLICY,
        variables
      })

      setData(response.data.techPackTechnicalFile)
    },
    [client, setData]
  )

  return {
    data,
    get
  }
}

export default useTechPackTechnicalFile

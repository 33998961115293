import gql from "graphql-tag"

const publishedPublicationsCountQuery = gql`
  query PublishedPublicationsCount($startDate: String!, $endDate: String!) {
    publishedPublicationsCount(startDate: $startDate, endDate: $endDate) {
      totalCount
      MEGA_TRENDS
      GENERAL_TRENDS
      TREND_ALERTS
      CATWALK_REPORT
      RETAIL_REPORT
      TRADE_SHOW_REPORT
      TECHNICAL_AREA_REPORT
    }
  }
`

export default publishedPublicationsCountQuery

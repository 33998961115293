import { t } from "i18next"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { PluginButton } from "../Buttons"
import createBlockBreakoutPlugin from "draft-js-block-breakout-plugin"
import createColorChipPlugin from "../ColorChipPlugin"
import createImageGridPlugin from "../ImageGridPlugin"
import createTechPackPlugin from "../TechPackPlugin"
import createVideoPlugin from "../VideoPlugin"

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    textAlign: "center",
    padding: `${theme.spacing.unit}px 0`
  }
})

const blockBreakoutPlugin = createBlockBreakoutPlugin()
const colorChipPlugin = createColorChipPlugin()
const techPackPlugin = createTechPackPlugin()
const imageGridPlugin = createImageGridPlugin()
const videoPlugin = createVideoPlugin()

function TopToolbar({ classes }) {
  return (
    <div className={classes.root}>
      <colorChipPlugin.button>
        <PluginButton
          data-test="color-chip-button"
          text={t("reports.editor.topToolbar.colorChip")}
        />
      </colorChipPlugin.button>
      <imageGridPlugin.button>
        <PluginButton
          data-test="image-grid-button"
          text={t("reports.editor.topToolbar.imageGrid")}
        />
      </imageGridPlugin.button>
      <techPackPlugin.button>
        <PluginButton
          data-test="color-chip-button"
          text={t("reports.editor.topToolbar.techPack")}
        />
      </techPackPlugin.button>
      <videoPlugin.button>
        <PluginButton
          data-test="video-button"
          text={t("reports.editor.topToolbar.video")}
        />
      </videoPlugin.button>
    </div>
  )
}

export const topToolbarPlugins = [
  blockBreakoutPlugin,
  colorChipPlugin,
  imageGridPlugin,
  techPackPlugin,
  videoPlugin
]

export default withStyles(styles)(TopToolbar)

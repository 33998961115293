import React from "react"
import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { parseInput, parseOutput } from "./parsers"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Account",
  pathPreffix: "accounts",
  i18nKey: "accounts",
  listColumns: [
    { header: t("accounts.fields.name"), path: "name" },
    {
      header: t("accounts.fields.status"),
      path: "active",
      component: ({ value: active }) => {
        if (active) {
          return t("accounts.fields.statusValues.active")
        }
        return t("accounts.fields.statusValues.inactive")
      }
    },
    {
      header: "",
      path: "id",
      component: ({ value: id }) => (
        <Button color="primary" component={Link} to={`/accounts/${id}/users`}>
          {t("accounts.fields.users")}
        </Button>
      )
    }
  ],
  hasSearch: true,
  upsertForm: Form,
  create: {
    initialValues: {
      active: true,
      unlimitedUsersAllowed: true
    }
  },
  upsert: {
    parseOutput,
    parseInput,
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("accounts.upsertPages.duplicateNameErrorMessage")
        : t("accounts.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

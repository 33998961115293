import React from "react"

const gridComponents = {
  GRID_1x1: require("./DefaultGrid").default,
  GRID_1x2: require("./DefaultGrid").default,
  GRID_1x2_LANDSCAPE: require("./DefaultGrid").default,
  GRID_1x3: require("./DefaultGrid").default,
  GRID_1x4: require("./DefaultGrid").default,
  GRID_1x4_PORTRAIT: require("./DefaultGrid").default,
  SPECIAL_GRID_1: require("./SpecialGrids").SpecialGrid1,
  SPECIAL_GRID_2: require("./SpecialGrids").SpecialGrid2
}

function ImageGridPreview({ data }) {
  const GridComponent = gridComponents[data.gridType]
  return <GridComponent data={data} />
}

export default ImageGridPreview

import { t } from "i18next"
import cx from "classnames"
import React from "react"
import Dropzone from "react-dropzone"
import BaseField from "app/common/formFields/Base"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloudUploadIcon from "@material-ui/icons/CloudUpload"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  root: {
    marginBottom: 16
  },
  dropzone: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit,
    border: `1px dashed rgba(0, 0, 0, 0.42)`
  },
  dropzoneAccept: {
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}09`
  },
  dropzoneReject: {
    borderColor: theme.palette.error.main,
    backgroundColor: `${theme.palette.error.main}09`
  },
  previewImage: {
    display: "block",
    margin: "0 auto",
    maxWidth: 200,
    maxHeight: 200
  },
  previewImageLoading: {
    opacity: 0.65
  },
  buttonWrapper: {
    display: "block",
    position: "relative",
    textAlign: "center"
  },
  button: {
    margin: ` ${theme.spacing.unit}px auto 0`
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -12
  },
  placeholder: {
    textAlign: "center"
  },
  placeholderIcon: {
    color: theme.palette.primary.light,
    fontSize: 80
  }
})

function ImageDropzone({
  initialSourceFieldName,
  newImageSource,
  onDrop,
  meta,
  label,
  loading,
  classes
}) {
  const error = meta.touched && meta.error
  let dropzoneRef

  return (
    <FormControl error={Boolean(error)} className={classes.root}>
      <InputLabel shrink>{label}</InputLabel>
      <Dropzone
        disableClick
        accept="image/*"
        multiple={false}
        className={classes.dropzone}
        acceptClassName={classes.dropzoneAccept}
        rejectClassName={classes.dropzoneReject}
        onDrop={onDrop}
        ref={ref => {
          dropzoneRef = ref
        }}
      >
        <BaseField
          data-test="img-field"
          name={initialSourceFieldName}
          render={({ input: { value } }) => {
            const sourceSet = newImageSource || value

            if (sourceSet) {
              return (
                <img
                  data-test="img"
                  alt="no description"
                  srcSet={imageSrcToNew(sourceSet, "small")}
                  className={cx({
                    [classes.previewImage]: true,
                    [classes.previewImageLoading]: loading
                  })}
                />
              )
            }

            return (
              <div className={classes.placeholder}>
                <CloudUploadIcon
                  data-test="cloud-upload"
                  className={classes.placeholderIcon}
                />
              </div>
            )
          }}
        />
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => dropzoneRef.open()}
            color="primary"
            className={classes.button}
            disabled={loading}
          >
            {loading
              ? t("common.formFields.file.loading")
              : t("common.formFields.file.choose")}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Dropzone>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default withStyles(styles)(ImageDropzone)

import React, { Component, Fragment } from "react"
import { compose, map } from "lodash/fp"
import { withProps } from "recompose"
import Drawer from "@material-ui/core/Drawer"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import { withURLSearch } from "app/common/URLSearch"
import Pagination from "app/common/Pagination"
import { withConsumer as withImagesConsumer } from "../context"
import Topbar from "./Topbar"
import Form from "./AddImageClassificationForm"
import Gallery from "./Gallery"
import UploadPlaceholder from "./UploadPlaceholder"

const TOP_BAR_HEIGHT = 49
const TOP_BAR_PADDING = 20

class ImageList extends Component {
  state = {
    openDrawer: false
  }

  onClassificationSelected = (itemId, classification) => {
    this.props.setSearch({
      itemId,
      classificationId: classification.id
    })
  }

  toggleDrawer = (state, { classifyingAllFolder = false } = {}) => () => {
    this.setState({ openDrawer: state, classifyingAllFolder })
  }

  render() {
    const {
      images,
      queryVariables: { page, imageFolderId },
      pageCount,
      onPageChange,
      refetchImages
    } = this.props
    const { openDrawer, classifyingAllFolder } = this.state

    return images.length ? (
      <MultiSelectConsumer>
        {({ items: selectedItems }) => (
          <Fragment>
            <Topbar
              images={images}
              toggleDrawer={this.toggleDrawer}
              height={TOP_BAR_HEIGHT}
              padding={TOP_BAR_PADDING}
            />
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={this.toggleDrawer(false)}
            >
              <Form
                selectedIds={map("id", selectedItems)}
                closeAction={this.toggleDrawer(false)}
                imageFolderId={imageFolderId}
                refetchImages={refetchImages}
                applyToAllFolder={classifyingAllFolder}
              />
            </Drawer>
            <Pagination
              currentPage={page}
              onChange={onPageChange}
              pageCount={pageCount}
            />
            <Gallery
              items={images}
              onClassificationSelected={this.onClassificationSelected}
            />
          </Fragment>
        )}
      </MultiSelectConsumer>
    ) : (
      <UploadPlaceholder />
    )
  }
}

export default compose(
  withImagesConsumer,
  withProps(props => ({
    ...props.context,
    itemCount: props.context.images.length
  })),
  withURLSearch,
  withProps(props => ({ ...props.context }))
)(ImageList)

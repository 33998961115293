export default {
  fields: {
    name: "Nome",
    order: "Ordem"
  },
  listPage: {
    title: "Estilistas"
  },
  upsertPages: {
    createTitle: "Criar Estilista",
    updateTitle: "Alterar Estilista",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este estilista"
  }
}

import { t } from "i18next"
import { compose } from "lodash/fp"
import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button"
import { withField } from "app/utils/forms"
import AppBar from "app/common/AppBar"
import GridType from "./GridType"

const styles = {
  grid: {
    margin: -10,
    paddingTop: 24,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  }
}

const gridTypes = {
  GRID_1x1: require("./grid1x1.svg"),
  GRID_1x2: require("./grid1x2.svg"),
  GRID_1x2_LANDSCAPE: require("./grid1x2-landscape.svg"),
  GRID_1x3: require("./grid1x3.svg"),
  GRID_1x4: require("./grid1x4.svg"),
  GRID_1x4_PORTRAIT: require("./grid1x4-portrait.svg"),
  SPECIAL_GRID_1: require("./specialGrid1.svg"),
  SPECIAL_GRID_2: require("./specialGrid2.svg")
}

function SelectGridType({ classes, setView, gridType, onCancel }) {
  return (
    <Fragment>
      <AppBar
        title={t(
          "reports.editor.contentTypes.imageGrid.dialog.selectGridType.title"
        )}
        rightArea={
          <Button onClick={onCancel} color="inherit">
            {t("common.cancel")}
          </Button>
        }
      />
      <DialogContent>
        <div className={classes.grid}>
          {Object.keys(gridTypes).map(key => (
            <GridType
              key={key}
              image={gridTypes[key]}
              onClick={() => {
                gridType.setValue(key)
                setView("ImageGrid")
              }}
            />
          ))}
        </div>
      </DialogContent>
    </Fragment>
  )
}

export default compose(
  withStyles(styles),
  withField("gridType")
)(SelectGridType)

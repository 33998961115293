import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"
import parseOutput from "./parseOutput"
import BooleanDisplay from "app/common/BooleanDisplay"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Trend",
  pathPreffix: "trends",
  i18nKey: "trends",
  listColumns: [
    { header: t("trends.fields.name"), path: "name" },
    {
      header: t("trends.fields.archived"),
      path: "archived",
      component: BooleanDisplay
    },
    {
      header: t("trends.fields.regions"),
      path: "regions",
      component: ({ value: regions = [] }) => {
        return regions.map(r => r.name).join(", ")
      }
    }
  ],
  upsertForm: Form,
  upsert: {
    parseOutput,
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("trends.upsertPages.duplicateNameErrorMessage")
        : t("trends.upsertPages.genericErrorMessage")
  },
  hasMerge: true,
  hasSearch: true
})

export { ListPage, CreatePage, UpdatePage }

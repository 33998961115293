import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "Author",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("author", [
    "id",
    "name",
    "role",
    "avatarId",
    "avatar { source sizes { small } }"
  ]),
  listQuery: graphqlUtils.listQuery(
    "authors",
    ["id", "name", "role", "avatar { source sizes { small } }"],
    { search: "String" }
  ),
  createMutation: graphqlUtils.createMutation("createAuthor", "AuthorInput"),
  updateMutation: graphqlUtils.updateMutation("updateAuthor", "AuthorInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteAuthor")
}

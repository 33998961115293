import React, { Component } from "react"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { ApolloProvider } from "react-apollo"
import { Router } from "react-router-dom"
import createBrowserHistory from "history/createBrowserHistory"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "material-ui-pickers"
import ptDateFnLocale from "date-fns/locale/pt-BR"
import { AuthProvider } from "app/common/AuthContext"
import Layout from "app/common/Layout"
import { SnackbarProvider } from "app/common/Snackbar"
import QueryUser from "app/common/QueryUser"
import { LoadingIndicatorProvider } from "app/common/LoadingIndicator"
import { URLSearchProvider } from "app/common/URLSearch"
import localStorage from "app/utils/localStorage"
import createApolloClient from "app/utils/createApolloClient"
import routes from "./routes"
import theme from "./theme"

class App extends Component {
  state = {
    token: localStorage.get("token"),
    error: false
  }

  constructor() {
    super()

    this.history = createBrowserHistory()

    this.apolloClient = createApolloClient({
      getToken: this.getToken,
      onUnauthorized: this.onUnauthorized
    })
  }

  componentDidCatch() {
    if (process.env.NODE_ENV !== "production") {
      return null
    }
    /*
     * We need to set the error state to true
     * to stop the rendering of the broken
     * tree, after that we can redirect the user
     * to the internal error page and then resume
     * the rendering
     */
    this.setState({ error: true }, () => {
      this.history.push("/internalError")
      this.setState({ error: false })
    })
  }

  componentDidMount() {
    if (typeof window !== "undefined" && !window.rollbar) {
      window.rollbar = require("rollbar")
      // eslint-disable-next-line
      window.Rollbar = new window.rollbar({
        accessToken: process.env.ROLLBAR_TOKEN_CLIENT,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          client: {
            javascript: {
              environment: process.env.NODE_ENV,
              guess_uncaught_frames: true,
              code_version: process.env.SOURCE_VERSION
            }
          }
        }
      })
    }
  }

  setRollbarUser = ({ id, email }) => {
    if (window.rollbar) {
      window.Rollbar.configure({
        payload: {
          person: {
            id,
            email
          }
        }
      })
    }
  }

  getToken = () => {
    return this.state.token
  }

  setToken = token => {
    localStorage.set("token", token)
    this.setState({ token })
  }

  onUnauthorized = () => {
    this.setToken(null)
  }

  render() {
    if (this.state.error) {
      return null
    }

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptDateFnLocale}>
          <LoadingIndicatorProvider>
            <ApolloProvider client={this.apolloClient}>
              <AuthProvider
                data-test="auth-provider"
                loggedIn={Boolean(this.state.token)}
                setToken={this.setToken}
              >
                <QueryUser setUser={this.setRollbarUser} />
                <SnackbarProvider>
                  <Router history={this.history}>
                    <URLSearchProvider>
                      <Layout>{routes}</Layout>
                    </URLSearchProvider>
                  </Router>
                </SnackbarProvider>
              </AuthProvider>
            </ApolloProvider>
          </LoadingIndicatorProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    )
  }
}

export default App

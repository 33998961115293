import React from "react"
import CropImage from "app/common/CropImage"
import styled from "app/utils/styled"

const Wrapper = styled("div", theme => ({
  flex: "1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  lineHeight: 0
}))

function Content({ imageFile, cropInfo, cropImageRef }) {
  return (
    <Wrapper>
      <CropImage {...cropInfo} file={imageFile} ref={cropImageRef} />
    </Wrapper>
  )
}

export default Content

import { t } from "i18next"
import React, { Fragment } from "react"
import BaseField from "app/common/formFields/Base"
import TextField from "app/common/formFields/Text"
import NumericField from "app/common/formFields/Numeric"
import SwitchField from "app/common/formFields/Switch"
import TagField from "app/common/formFields/Tag"
import * as validations from "app/utils/validations"

function Form() {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("accounts.fields.name")}
        validate={validations.required()}
      />
      <SwitchField name="active" label={t("accounts.fields.accountStatus")} />
      <TagField name="ips" label={t("accounts.fields.ips")} isMulti />

      <SwitchField
        name="unlimitedUsersAllowed"
        label={t("accounts.fields.unlimitedUsers")}
      />
      <BaseField name="unlimitedUsersAllowed" subscription={{ value: true }}>
        {({ input: { value } }) => {
          return !value ? (
            <NumericField
              type="number"
              name="maxUsers"
              inputProps={{ min: 1 }}
              label={t("accounts.fields.maxUsers")}
            />
          ) : null
        }}
      </BaseField>
    </Fragment>
  )
}

export default Form

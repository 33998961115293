import User from "../user"

export default {
  name: "Administrator",
  getQuery: User.getQuery,
  listQuery: require("./list").default,
  createMutation: User.createMutation,
  updateMutation: User.updateMutation,
  destroyMutation: User.destroyMutation
}

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Season",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("season", [
    "id",
    "name",
    "publicationDate",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery(
    "seasons",
    ["id", "name", "publicationDate"],
    { search: "String" }
  ),
  createMutation: graphqlUtils.createMutation("createSeason", "SeasonInput"),
  updateMutation: graphqlUtils.updateMutation("updateSeason", "SeasonInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteSeason"),
  mergeMutation: graphqlUtils.mergeMutation("mergeSeasons")
}

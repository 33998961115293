import React from "react"
import { compose } from "recompose"
import ImageDialog from "./ImageDialog"
import { withProvider } from "./context"
import ImageList from "./ImageList"
import UploadDropzone from "./UploadDropzone"
import { withMultiSelectProvider } from "app/common/MultiSelectProvider"

function Images({ match }) {
  return (
    <UploadDropzone imageFolderId={match.params.id}>
      <ImageList />
      <ImageDialog />
    </UploadDropzone>
  )
}

export default compose(
  withMultiSelectProvider,
  withProvider
)(Images)

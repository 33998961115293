import { times } from "lodash/fp"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import * as gridSettings from "../../gridSettings"
import Image from "./Image"

const styles = theme => ({
  root: {
    display: "flex",
    padding: `${theme.spacing.unit * 3}px 0`
  },
  image: {
    padding: "0 15px"
  }
})

function DefaultGrid({ classes, gridType, onEdit }) {
  const { imageNumber, imageWidth, imageHeight } = gridSettings[gridType]

  return (
    <div className={classes.root}>
      {times(
        index => (
          <div
            key={index}
            className={classes.image}
            style={{ width: `${100 / imageNumber}%` }}
          >
            <Image
              index={index}
              width={imageWidth}
              height={imageHeight}
              onEdit={onEdit}
            />
          </div>
        ),
        imageNumber
      )}
    </div>
  )
}

export default withStyles(styles)(DefaultGrid)

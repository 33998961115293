import React from "react"
import { t } from "i18next"
import CreateByCopy from "app/common/CreateByCopy"
import { compose } from "lodash/fp"
import Form from "../Form"
import * as entities from "app/entities"
import { withURLSearch } from "app/common/URLSearch"
import { withProps } from "recompose"
import entityInfo from "../entityInfo"
import { parseInput, parseOutput } from "../helpers"

export default compose(
  withURLSearch,
  withProps(props => ({
    id: props.match.params.id,
    ...entities[entityInfo.entity],
    title: t(`${entityInfo.i18nKey}.upsertPages.createTitle`),
    returnHref: () => `/${entityInfo.pathPreffix}`,
    parseOutput,
    parseInput,
    children: <Form />,
    hasSaveAndPreview: true,
    entitySlug: entityInfo.slug
  }))
)(CreateByCopy)

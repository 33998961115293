import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "GeneralTrendType",
  pathPreffix: "generalTrendTypes",
  i18nKey: "generalTrendTypes",
  listColumns: [{ header: t("generalTrendTypes.fields.name"), path: "name" }],
  upsertForm: Form,
  upsert: {
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("generalTrendTypes.upsertPages.duplicateNameErrorMessage")
        : t("generalTrendTypes.upsertPages.genericErrorMessage")
  },
  hasSearch: true,
  hasMerge: true
})

export { ListPage, CreatePage, UpdatePage }

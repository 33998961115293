import { compose } from "lodash/fp"
import { t } from "i18next"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import FormHelperText from "@material-ui/core/FormHelperText"
import TextField from "app/common/formFields/Text"
import FixedRatio from "app/common/FixedRatio"
import { withField } from "app/utils/forms"
import { required } from "app/utils/validations"

const styles = theme => ({
  root: {
    textAlign: "center"
  },
  imageWrapper: {
    marginBottom: theme.spacing.unit * 3
  },
  image: {
    display: "block",
    width: "100%",
    height: "100%"
  },
  placeholderWrapper: {
    overflow: "visible"
  },
  placeholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 8,
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  }
})
function Image({
  classes,
  imageRatio,
  width,
  height,
  index,
  imageSourceSet,
  imageFolderId: { value: imageFolderId },
  onEdit
}) {
  const error = imageSourceSet.meta.touched && imageSourceSet.meta.error

  return imageSourceSet.value ? (
    <div className={classes.root}>
      <FixedRatio ratio={height / width} className={classes.imageWrapper}>
        <img alt="" srcSet={imageSourceSet.value} className={classes.image} />
      </FixedRatio>
      <TextField name={`images[${index}].caption`} label="Legenda" />
      <Button
        onClick={() => onEdit({ imageIndex: index, imageFolderId })}
        color="primary"
      >
        {t("reports.editor.contentTypes.imageGrid.image.changeImage")}
      </Button>
    </div>
  ) : (
    <FixedRatio ratio={height / width} className={classes.placeholderWrapper}>
      <Paper className={classes.placeholder}>
        <Button onClick={() => onEdit({ imageIndex: index })} color="primary">
          {t("reports.editor.contentTypes.imageGrid.image.chooseImage")}
        </Button>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Paper>
    </FixedRatio>
  )
}

export default compose(
  withStyles(styles),
  withField(p => `images[${p.index}].imageFile.source`, {
    prop: "imageSourceSet",
    validate: required()
  }),
  withField(p => `images[${p.index}].image.imageFolder.id`, {
    prop: "imageFolderId"
  })
)(Image)

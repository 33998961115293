export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Aviamentos"
  },
  upsertPages: {
    createTitle: "Criar Aviamento",
    updateTitle: "Alterar Aviamento",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este aviamento"
  }
}

import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import { galleryType } from "app/utils/enums"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Gallery",
  pathPreffix: "galleries",
  i18nKey: "galleries",
  listColumns: [
    { header: t("galleries.fields.order"), path: "order" },
    { header: t("galleries.fields.title"), path: "title" },
    {
      header: t("galleries.fields.type"),
      path: "type",
      component: ({ value }) => t(galleryType[value])
    }
  ],
  upsertForm: Form,
  upsert: {
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("galleries.upsertPages.duplicateNameErrorMessage")
        : t("galleries.upsertPages.genericErrorMessage")
  },
  hasSearch: true
})

export { ListPage, CreatePage, UpdatePage }

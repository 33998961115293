import React from "react"
import ReactPaginate from "react-paginate"
import styled from "app/utils/styled"

const StyledReactPaginate = styled(
  ReactPaginate,
  theme => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 1.2,
    textAlign: "center",
    userSelect: "none",
    "& li": {
      display: "inline-block",
      "& a": {
        borderRadius: "2px",
        textAlign: "center",
        verticalAlign: "top",
        height: "30px",
        display: "inline-block",
        padding: "0 10px",
        lineHeight: "30px",
        cursor: "pointer",
        outline: "none"
      },
      "& a:focus, & a:hover": {
        backgroundColor: theme.palette.grey[300]
      },
      "&.selected a": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    }
  }),
  null,
  "containerClassName"
)

const Pagination = ({
  className,
  pageCount,
  currentPage,
  onChange,
  pageRangeDisplayed = 6,
  marginPagesDisplayed = 2
}) => (
  <StyledReactPaginate
    data-test="react-paginate"
    breakLabel={<span>...</span>}
    pageCount={pageCount}
    marginPagesDisplayed={marginPagesDisplayed}
    pageRangeDisplayed={pageRangeDisplayed}
    forcePage={currentPage - 1}
    onPageChange={({ selected }) => {
      onChange(selected + 1)
    }}
    containerClassName={className}
    previousLabel="<"
    nextLabel=">"
  />
)

export default Pagination

export default {
  fields: {
    reportId: "Id do report",
    title: "Título",
    body: "Descrição",
    publicationDate: "Data de publicação",
    image: "Imagem",
    author: "Autor",
    origin: "Origem"
  },
  listPage: {
    title: "Banner Home"
  },
  upsertPages: {
    createTitle: "Criar Banner Home",
    updateTitle: "Alterar Banner Home",
    genericErrorMessage: "Houve um problema ao salvar Banner Home"
  }
}

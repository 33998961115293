import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "TradeShowReport",
  getQuery: graphqlUtils.getQuery("tradeShowReport", [
    "id",
    "publicationType",
    "publicationDate",
    "title",
    "subtitle",
    "cardImageId",
    "cardImage { source sizes { small } }",
    ["season", ["id", "name"]],
    ["genders", ["id", "name"]],
    ["segments", ["id", "name"]],
    ["categories", ["id", "name"]],
    ["niches", ["id", "name"]],
    ["topics", ["id", "name"]],
    ["trends", ["id", "name"]],
    ["cities", ["id", "name"]],
    ["tradeShowType", ["id", "name"]],
    [
      "contents",
      [
        ["... on ContentInterface", ["contentType"]],
        ["... on TextContent", ["body"]],
        ["... on SectionTitleContent", ["title"]],
        ["... on ColorChipContent", ["color", "description"]],
        ["... on VideoContent", ["url", "duration"]],
        [
          "... on ImageGridContent",
          [
            "gridType",
            [
              "images",
              [
                "imageFileId",
                "imageId",
                "caption",
                "alt",
                "imageFile { source sizes { small } }",
                "image { imageFolder { id } }"
              ]
            ]
          ]
        ]
      ]
    ]
  ]),
  listQuery: graphqlUtils.listQuery(
    "tradeShowReports",
    ["id", "title", "publicationDate", ["user", ["name"]]],
    {
      search: "String"
    }
  ),
  createMutation: graphqlUtils.createMutation(
    "createTradeShowReport",
    "TradeShowReportInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTradeShowReport",
    "TradeShowReportInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTradeShowReport")
}

import React, { Component } from "react"
import Mutation from "app/common/Mutation"
import { TechPackBackFile } from "app/entities"

class UploadTechPackBackFile extends Component {
  state = {
    loading: false
  }

  makeUploadTechPackBackFile = (
    upload,
    createTechPackBackFile
  ) => async file => {
    this.setState({ loading: true })

    const {
      data: {
        techPackBackFileUploadUrl: { key, url, headers }
      }
    } = await upload({
      variables: {
        input: { filename: file.name || "temp", contentType: file.type }
      }
    })

    const fetchHeaders = {}
    headers.forEach(header => (fetchHeaders[header.key] = header.value))

    await fetch(url, {
      method: "PUT",
      body: file,
      headers: fetchHeaders
    })

    const { data } = await createTechPackBackFile({
      variables: { input: { key } }
    })

    this.setState({ loading: false })

    return data.createTechPackBackFile
  }

  render() {
    return (
      <Mutation mutation={TechPackBackFile.uploadUrl}>
        {upload => (
          <Mutation mutation={TechPackBackFile.createTechPackBackFile}>
            {createTechPackBackFile =>
              this.props.children(
                this.makeUploadTechPackBackFile(upload, createTechPackBackFile),
                { loading: this.state.loading }
              )
            }
          </Mutation>
        )}
      </Mutation>
    )
  }
}

export default UploadTechPackBackFile

import React, { createContext, useContext, useState, useEffect } from "react"
import fetch from "isomorphic-unfetch"

const PantoneContext = createContext()

export function PantoneProvider({ children }) {
  const { Provider } = PantoneContext

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    fetch(process.env.PANTONE_LIST_URL)
      .then(data => data.json())
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
  }, [])

  return <Provider value={{ data, loading, error }}>{children}</Provider>
}

export default () => useContext(PantoneContext)

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Color",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("color", [
    "id",
    "name",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("colors", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createColor", "ColorInput"),
  updateMutation: graphqlUtils.updateMutation("updateColor", "ColorInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteColor"),
  mergeMutation: graphqlUtils.mergeMutation("mergeColors")
}

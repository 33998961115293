import { t } from "i18next"
import React from "react"
import BaseField from "app/common/formFields/Base"
import UploadImage from "app/common/UploadImage"
import { composeValidators } from "app/utils/validations"
import FileInput from "./FileInput"

function validateLoading(value) {
  return value === "UPLOADING"
    ? t("common.formFields.file.loadingValidationErrorMessage")
    : undefined
}

function FileField({ validate, name, ...props }) {
  const UploadComponent = props.uploadComponent || UploadImage
  return (
    <BaseField
      name={name}
      validate={
        validate
          ? composeValidators(validateLoading, validate)
          : validateLoading
      }
      render={fieldProps => (
        <UploadComponent>
          {upload => <FileInput {...props} {...fieldProps} upload={upload} />}
        </UploadComponent>
      )}
    />
  )
}

export default FileField

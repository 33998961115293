import routeBuilder from "app/utils/routeBuilder"

routeBuilder
  .redirect("/", "/dashboard")
  .crud("/administrators", "administrators")
  .crud("/users", "users")
  .crud("/accounts", "accounts")
  .crud("/accounts/:accountId/users", "accounts/users")
  .crud("/authors", "authors")
  .crud("/booths", "booths")
  .crud("/brands", "brands")
  .crud("/catwalkEvents", "catwalkEvents")
  .crud("/catwalkReports", "catwalkReports")
  .private(
    "/catwalkReports/createByCopyingOf/:id",
    "catwalkReports/createByCopying"
  )
  .crud("/cities", "cities")
  .crud("/colors", "colors")
  .crud("/countries", "countries")
  .crud("/dailyImages", "dailyImages")
  .private("/dashboard", "dashboard")
  .crud("/galleries", "galleries")
  .crud("/genders", "genders")
  .crud("/regions", "regions")
  .crud("/imageFolders", "imageFolders")
  .private("/imageFolders/:id/images", "imageFolders/images")
  .crud("/techPackFolders", "techPackFolders")
  .private("/techPackFolders/:id/techPacks", "techPackFolders/techPacks")
  .crud("/materials", "materials")
  .crud("/megaTrendTypes", "megaTrendTypes")
  .crud("/generalTrendTypes", "generalTrendTypes")
  .crud("/printTypes", "printTypes")
  .crud("/products", "products")
  .crud("/seasons", "seasons")
  .crud("/storeTypes", "storeTypes")
  .crud("/segments", "segments")
  .crud("/categories", "categories")
  .crud("/niches", "niches")
  .crud("/stylists", "stylists")
  .crud("/topics", "topics")
  .crud("/trends", "trends")
  .crud("/trends-v2", "trendsV2")
  .crud("/tradeShowEvents", "tradeShowEvents")
  .crud("/tradeShowTypes", "tradeShowTypes")
  .crud("/trendReports", "trendReports")
  .private(
    "/trendReports/createByCopyingOf/:id",
    "trendReports/createByCopying"
  )
  .crud("/tradeShowReports", "tradeShowReports")
  .private(
    "/tradeShowReports/createByCopyingOf/:id",
    "tradeShowReports/createByCopying"
  )
  .crud("/trimmingTypes", "trimmingTypes")
  .crud("/dailyContents", "dailyContents")
  .crud("/retailReports", "retailReports")
  .private(
    "/retailReports/createByCopyingOf/:id",
    "retailReports/createByCopying"
  )
  .crud("/recommendedTags", "recommendedTags")
  .public("/login", "login")
  .public("/internalError", "internalError")
  .private("/wgsnFolders", "wgsnFolders")
  .crud("/technicalAreaType", "technicalAreaType")
  .crud("/technicalAreaReports", "technicalAreaReports")
  .private(
    "/technicalAreaReports/createByCopyingOf/:id",
    "technicalAreaReports/createByCopying"
  )
  /*
   * The NotFound page should always come last,
   * because react-router's Switch component
   * will render the first route that matches,
   * and the NotFound page does not have any
   * route set, so it will match any url
   */
  .public(null, "notFound")

export default routeBuilder.get()

import React from "react"
import BaseField from "app/common/formFields/Base"

function Condition({ when, is, children }) {
  return (
    <BaseField name={when} subscription={{ value: true }}>
      {({ input: { value } }) => children(value === is)}
    </BaseField>
  )
}

export default Condition

import { get } from "lodash/fp"

function pickEventBasedOnGalleryType(node) {
  const galleryType = get("gallery.type", node)
  switch (galleryType) {
    case "CATWALK":
    case "TRADESHOW":
      return get("catwalkEvent.name", node) || get("tradeShowEvent.name", node)
    default:
      return null
  }
}

export default function RenderEvent({ value: node }) {
  return pickEventBasedOnGalleryType(node) || null
}

import { t } from "i18next"
import React, { Fragment } from "react"
import { get, omit } from "lodash/fp"
import { normalizeObject } from "app/utils/forms"
import EntitySelectField from "app/common/formFields/EntitySelect"
import EnumSelectField from "app/common/formFields/EnumSelect"

export const normalizeItem = normalizeObject({
  productId: get("product.id"),
  genderId: get("gender.id"),
  regionIds: get("regions.id"),
  segmentId: get("segment.id"),
  categoryId: get("category.id"),
  colorId: get("color.id"),
  materialId: get("material.id"),
  printTypeId: get("printType.id"),
  trimmingTypeId: get("trimmingType.id"),
  brandId: get("brand.id"),
  topicId: get("topic.id"),
  trendId: get("trend.id"),
  market: get("market")
})

export const cleanItem = omit([
  "product",
  "gender",
  "segment",
  "category",
  "color",
  "material",
  "printType",
  "trimmingType",
  "brand",
  "topic",
  "trend",
  "regions",
  "region",
  "regionId",
  "__typename"
])

function ImageProductForm() {
  return (
    <Fragment>
      <EnumSelectField
        name="market"
        enum="marketType"
        label={t("imageClassifications.market")}
      />
      <EntitySelectField
        name="segment"
        label={t("imageClassifications.segment")}
        entity="Segment"
      />
      <EntitySelectField
        name="category"
        label={t("imageClassifications.category")}
        entity="Category"
      />
      <EntitySelectField
        name="gender"
        label={t("imageClassifications.gender")}
        entity="Gender"
      />
      <EntitySelectField
        name="topic"
        label={t("imageClassifications.topic")}
        entity="Topic"
      />
      <EntitySelectField
        name="trend"
        label={t("imageClassifications.trend")}
        entity="Trend"
      />
      <EntitySelectField
        name="product"
        label={t("imageClassifications.product")}
        entity="Product"
      />
      <EntitySelectField
        name="color"
        label={t("imageClassifications.color")}
        entity="Color"
      />
      <EntitySelectField
        name="material"
        label={t("imageClassifications.material")}
        entity="Material"
      />
      <EntitySelectField
        name="printType"
        label={t("imageClassifications.printType")}
        entity="PrintType"
      />
      <EntitySelectField
        name="trimmingType"
        label={t("imageClassifications.trimmingType")}
        entity="TrimmingType"
      />
      <EntitySelectField
        name="brand"
        label={t("imageClassifications.brandStore")}
        entity="Brand"
      />
      <EntitySelectField
        isMulti
        name="regions"
        label={t("imageClassifications.regions")}
        entity="Region"
      />
    </Fragment>
  )
}

export default ImageProductForm

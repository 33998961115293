import React, { Component, createContext } from "react"
import { compose, get, pipe, map } from "lodash/fp"
import { withRouter } from "react-router-dom"
import Query from "app/common/Query"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import imagesQuery from "app/entities/imageFolder/images"
import { withConsumer } from "app/utils/context"

const { Provider, Consumer } = createContext()

const PAGE_SIZE = 20

export class ImagesProvider extends Component {
  state = {
    page: 1
  }

  onPageChange = page => this.setState({ page })

  onImageDeleted = (images, pageCount, refetch) => (
    cache,
    { data: { bulkDeleteImage } }
  ) => {
    this.props.multiSelect.clearItems(bulkDeleteImage)

    if (
      images.length === bulkDeleteImage.length &&
      this.state.page === pageCount &&
      this.state.page > 1
    ) {
      this.setState({ page: this.state.page - 1 })
    } else {
      refetch()
    }
  }

  onImageAdded = refetch => () => {
    refetch()
    this.props.multiSelect.clearAll()
  }

  render() {
    const { page } = this.state
    const variables = {
      page: page,
      pageSize: PAGE_SIZE,
      imageFolderId: Number(this.props.imageFolderId)
    }

    return (
      <Query query={imagesQuery} variables={variables}>
        {({ data, loading, refetch }) => {
          const pageCount = get("images.pageInfo.pageCount", data) || 1
          const images = pipe(
            get("images.edges"),
            map("node")
          )(data)

          return !images ? null : (
            <Provider
              value={{
                images,
                pageCount,
                queryVariables: variables,
                refetchImages: refetch,
                onPageChange: this.onPageChange,
                onImageDeleted: this.onImageDeleted(images, pageCount, refetch),
                onImageAdded: this.onImageAdded(refetch)
              }}
            >
              {this.props.children}
            </Provider>
          )
        }}
      </Query>
    )
  }
}

function withProvider(Component) {
  /*
    This query should be run with fetchPolicy="no-cache" but apollo 2.3 has a bug in no cache
    https://github.com/apollographql/apollo-client/issues/3452
    The bugs were already fixed in apollo 2.4.0.
    When apollo client 2.4.0 is released, this query can be changed
    and the Component could be reverted back to use getDerivedStateFromProps
  */
  return props => (
    <MultiSelectConsumer>
      {context => (
        <ImagesProvider
          imageFolderId={props.match.params.id}
          multiSelect={context}
        >
          <Component {...props} />
        </ImagesProvider>
      )}
    </MultiSelectConsumer>
  )
}

const enhancedWithProvider = compose(
  withRouter,
  withProvider
)

const enhancedWithConsumer = withConsumer(Consumer)

export {
  enhancedWithProvider as withProvider,
  enhancedWithConsumer as withConsumer
}

import React from "react"
import makeBlockComponent from "./makeBlockComponent"
import insertBlock from "./insert"
import removeBlock from "./remove"
import updateBlock from "./update"

function makePlugin({ dialog, preview, type }) {
  return () => {
    let insert
    const component = makeBlockComponent({ type, dialog, preview })

    return {
      initialize: ({ getEditorState, setEditorState }) => {
        insert = () => {
          setEditorState(insertBlock(getEditorState(), { type, empty: true }))
        }
      },
      blockRendererFn: (
        block,
        { getEditorState, setEditorState, setReadOnly }
      ) => {
        if (
          block.getType() === "atomic" &&
          block.getData().get("type") === type
        ) {
          return {
            component,
            editable: false,
            props: {
              setReadOnly,
              remove: () => {
                setEditorState(removeBlock(getEditorState(), block))
              },
              change: data => {
                setEditorState(updateBlock(getEditorState(), block, data))
              }
            }
          }
        }

        return undefined
      },
      button: ({ children, ...props }) => {
        const child = React.Children.only(children)
        return <child.type onClick={insert} {...props} {...child.props} />
      }
    }
  }
}

export default makePlugin

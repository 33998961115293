import { t } from "i18next"
import cx from "classnames"
import React, { Component, Fragment } from "react"
import { compose } from "lodash/fp"
import { withProps } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import Button from "@material-ui/core/Button"
import deleteIcon from "app/icons/delete-icon.svg"
import editIcon from "app/icons/edit-icon.svg"
import eyeIcon from "app/icons/eye-icon.svg"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import Checkbox from "app/common/Checkbox"
import { withConsumer as withTechPacksConsumer } from "../context"
import DestroyTechPackButton from "../DestroyTechPackButton"
import TechPackClassification from "../TechPackClassification"
import DetailDialog from "./DetailDialog"
import imageSrcToNew from "app/utils/imageSrcToNew"

const styles = theme => ({
  root: {
    position: "relative",
    userSelect: "none",
    padding: 10
  },
  card: {
    transition: "transform 120ms linear, background 120ms linear",
    boxShadow: "none",
    borderRadius: 2,
    "&:hover .topButton": {
      opacity: 1,
      pointerEvents: "auto",
      cursor: "pointer",
      backgroundColor: "transparent"
    },
    width: 300,
    "&:hover .expandIcon": {
      opacity: 1,
      pointerEvents: "auto",
      cursor: "pointer"
    },
    "&:hover .overlay": {
      opacity: 0.25,
      transition: "opacity 120ms linear"
    }
  },
  cardActive: {
    boxShadow: "0px 0px 8px 8px #c6e5fc",
    transform: "scale(0.98)"
  },
  checkIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    transition: "color 120ms linear, opacity 200ms linear",
    pointerEvents: "none"
  },
  content: {
    padding:
      "0 !important" /* the card receives an padding-bottom added on the fly */
  },
  topButton: {
    transition: "opacity 200ms linear",
    opacity: 0,
    pointerEvents: "none",
    minWidth: 0,
    padding: 0,
    marginLeft: 20
  },
  topButtonWrapper: {
    position: "absolute",
    top: 10,
    right: 10,
    display: "flex",
    flexDirection: "row",
    pointerEvents: "none"
  },
  expandIcon: {
    color: "#FFFFFF",
    position: "absolute",
    bottom: 10,
    right: 10,
    transition: "opacity 200ms linear",
    opacity: 0,
    pointerEvents: "none"
  },
  techPack: {
    height: "100%",
    width: "100%"
  },
  techPackContent: {
    position: "relative"
  },
  overlay: {
    backgroundColor: "#000000",
    opacity: 0,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%"
  },
  loadingWrapper: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10
  },
  hideLoadingWrapper: {
    display: "none"
  },
  uncompleteOverlay: {
    left: 0,
    right: 0,
    bottom: 3,
    opacity: 0.9,
    position: "absolute",
    backgroundColor: "#333",
    height: 50
  },
  uncompleteText: {
    left: 0,
    right: 0,
    bottom: 3,
    position: "absolute",
    textAlign: "center",
    height: 35,
    color: "#fff",
    fontWeight: "bold"
  }
})

class Item extends Component {
  state = {
    isTechPackLoading: true,
    openDetail: false
  }

  handleEditClick = e => {
    e.stopPropagation()
    this.setState({ openDetail: true })
  }

  closeDetailDialog() {
    this.setState({ openDetail: false })
  }

  handleDetailClose = e => {
    e.stopPropagation()
    this.closeDetailDialog()
  }

  handleDetailSubmit = () => {
    this.closeDetailDialog()
  }

  onClassificationSelected = techPackClassification => {
    this.props.onClassificationSelected(
      this.props.item.id,
      techPackClassification
    )
  }

  onTechPackLoad = () =>
    this.setState({ isTechPackLoading: false }, this.props.updateLayout)

  render() {
    const { classes, item, onTechPackDeleted } = this.props

    return (
      <Fragment>
        {this.state.openDetail && (
          <DetailDialog
            open={this.state.openDetail}
            onClose={this.handleDetailClose}
            onSubmit={this.handleDetailSubmit}
            item={item}
          />
        )}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */
        /* eslint-disable jsx-a11y/mouse-events-have-key-events */
        /* eslint-disable jsx-a11y/no-static-element-interactions */}
        <MultiSelectConsumer>
          {({ items: selectedItems, toggleItem }) => {
            const selected = selectedItems.some(it => it.id === item.id)

            return (
              <div className={classes.root}>
                <Card
                  className={cx({
                    [classes.card]: true,
                    [classes.cardActive]: selected
                  })}
                  onClick={() => toggleItem(item)}
                  onDrop={this.onDrop}
                  onDragOver={this.onDragOver}
                >
                  {/* eslint-enable jsx-a11y/click-events-have-key-events */
                  /* eslint-enable jsx-a11y/mouse-events-have-key-events */
                  /* eslint-enable jsx-a11y/no-static-element-interactions */}
                  <CardContent className={classes.content}>
                    <div
                      className={classes.techPackContent}
                      style={{
                        width: "300px"
                      }}
                    >
                      {!item.complete && (
                        <>
                          <div className={classes.uncompleteOverlay} />
                          <div className={classes.uncompleteText}>
                            {t("techPackFolders.withoutDetail")}
                          </div>
                        </>
                      )}

                      <div
                        className={
                          this.state.isTechPackLoading
                            ? classes.loadingWrapper
                            : classes.hideLoadingWrapper
                        }
                      >
                        <CircularProgress />
                      </div>
                      <img
                        srcSet={imageSrcToNew(item.techPackFile.source, "small")}
                        alt=""
                        className={classes.techPack}
                        onLoad={this.onTechPackLoad}
                      />
                      <div className={cx("overlay", classes.overlay)} />
                      <Checkbox
                        className={classes.checkIcon}
                        checked={selected}
                      />
                      <div className={classes.topButtonWrapper}>
                        <Button
                          className={cx("topButton", classes.topButton)}
                          onClick={this.handleEditClick}
                        >
                          {/* eslint-disable jsx-a11y/click-events-have-key-events */
                          /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                          <img
                            src={editIcon}
                            alt={t("techPackClassifications.editButtonLabel")}
                          />
                        </Button>
                        <DestroyTechPackButton
                          ids={[item.id]}
                          onTechPackDeleted={onTechPackDeleted}
                        >
                          {/* eslint-disable jsx-a11y/click-events-have-key-events */
                          /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
                          {confirm => (
                            <img
                              className={cx("topButton", classes.topButton)}
                              src={deleteIcon}
                              alt={t(
                                "techPackClassifications.destroyButtonLabel"
                              )}
                              onClick={confirm}
                            />
                          )}

                          {/* eslint-enable jsx-a11y/click-events-have-key-events */
                          /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
                        </DestroyTechPackButton>

                        <a
                          className={cx("topButton", classes.topButton)}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${
                            process.env.PORTAL_V2_URL
                          }workspace/techPackPreview/${item.id}`}
                        >
                          <img
                            src={eyeIcon}
                            alt={t("techPackClassifications.editButtonLabel")}
                          />
                        </a>
                      </div>
                      <a
                        href={imageSrcToNew(item.techPackFile.source, "large")}
                        className={cx("expandIcon", classes.expandIcon)}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={e => e.stopPropagation()}
                      >
                        <FullscreenIcon />
                      </a>
                    </div>
                    {item.techPackClassifications.length > 0 ? (
                      <ul>
                        {item.techPackClassifications.map(
                          (classification, index) => (
                            <TechPackClassification
                              key={classification.id}
                              index={index}
                              classification={classification}
                              onClassificationSelected={
                                this.onClassificationSelected
                              }
                              selected
                            />
                          )
                        )}
                      </ul>
                    ) : null}
                  </CardContent>
                </Card>
              </div>
            )
          }}
        </MultiSelectConsumer>
      </Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withTechPacksConsumer,
  withProps(props => ({
    ...props.context,
    itemCount: props.context.techPacks.length
  }))
)(Item)

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Category",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("category", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("categories", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createCategory",
    "CategoryInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateCategory",
    "CategoryInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteCategory"),
  mergeMutation: graphqlUtils.mergeMutation("mergeCategories")
}

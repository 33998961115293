import { t } from "i18next"
import React, { useEffect, useState, Fragment } from "react"
import { withApollo } from "react-apollo"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import Form from "app/common/Form"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import FileField from "app/common/formFields/File"
import UploadTechPackTechnicalFile from "app/common/UploadTechPackTechnicalFile"

import useTechPackTechnicalFile from "app/enhancers/useTechPackTechnicalFile"

function TechPackDialog({ client, open, initialValues, onSubmit, onCancel }) {
  const [values, setValues] = useState(null)
  const { data, get } = useTechPackTechnicalFile(client)

  useEffect(() => {
    if (values !== null) {
      get({
        variables: {
          id: values.techPackTechnicalFileId
        }
      })
    }
  }, [values])

  useEffect(() => {
    if (data !== null) {
      onSubmit({ ...values, ...data, color: data.source })
    }
  }, [data])

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <Form
        data-test="form"
        initialValues={initialValues}
        onSubmit={v => setValues(v)}
      >
        {() => (
          <Fragment>
            <DialogTitle>
              {t("reports.editor.contentTypes.techPack.dialog.title")}
            </DialogTitle>
            <DialogContent>
              <TextField
                name="description"
                label={t(
                  "reports.editor.contentTypes.techPack.fields.description"
                )}
                validate={validations.required()}
              />
              <FileField
                accept=".ai"
                uploadComponent={UploadTechPackTechnicalFile}
                initialSourceFieldName="techPackTechnicalFile.source"
                name="techPackTechnicalFileId"
                label={t("reports.editor.contentTypes.techPack.fields.source")}
              />
            </DialogContent>
            <DialogActions>
              <Button
                data-test="cancel-button"
                onClick={onCancel}
                color="primary"
              >
                {t("common.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common.save")}
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Form>
    </Dialog>
  )
}

export default withApollo(TechPackDialog)

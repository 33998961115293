export default {
  fields: {
    name: "Nome",
    order: "Ordem",
    parent: "Pai",
    isPage: "Mostrar no menu de segmentos"
  },
  listPage: {
    title: "Segmentos"
  },
  upsertPages: {
    createTitle: "Criar Segmento",
    updateTitle: "Alterar Segmento",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este segmento"
  }
}

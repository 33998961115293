import { t } from "i18next"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import Button from "app/common/Button"
import PageHeader from "app/common/PageHeader"
import RemoteTable from "app/common/RemoteTable"
import SearchableRemoteTable from "app/common/SearchableRemoteTable"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"

function ListPage({
  title,
  query,
  variables,
  columns,
  defaultCellStyle,
  createHref,
  buildUpdateHref,
  hasSearch = false
}) {
  const TableComponent = hasSearch ? SearchableRemoteTable : RemoteTable

  return (
    <Fragment>
      <PageHeader
        data-test="page-header"
        title={title}
        buttons={
          <Button
            to={createHref}
            component={Link}
            text={t("listPage.createButtonLabel")}
          />
        }
      />
      <TableComponent
        data-test="base-list-page"
        query={query}
        variables={variables}
        defaultCellStyle={defaultCellStyle}
        columns={[
          ...columns,
          {
            style: {
              width: 40,
              textAlign: "right"
            },
            header: "",
            component: ({ value }) => (
              <IconButton
                component={Link}
                to={buildUpdateHref(value)}
                data-test="update-link"
              >
                <EditIcon />
              </IconButton>
            )
          }
        ]}
      />
    </Fragment>
  )
}

export default ListPage

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import EntitySelectField from "app/common/formFields/EntitySelect"
import ImageFileField from "app/common/formFields/ImageFile"
import DateTimeField from "app/common/formFields/DateTime"
import Editor from "app/common/formFields/RichTextEditors/ReportContentField"
import * as validations from "app/utils/validations"

function Form() {
  return (
    <Fragment>
      <TextField
        name="title"
        label={t("reports.fields.title")}
        validate={validations.required()}
      />
      <TextField name="subtitle" label={t("reports.fields.subtitle")} />
      <ImageFileField
        aspectRatio={720 / 554}
        initialSourceFieldName="cardImage.source"
        name="cardImageId"
        label={t("reports.fields.cardImage")}
        validate={validations.required()}
      />
      <DateTimeField
        name="publicationDate"
        label={t("reports.fields.publicationDate")}
      />
      <EntitySelectField
        name="technicalAreaType"
        label={t("reports.fields.technicalAreaType")}
        entity="TechnicalAreaType"
        validate={validations.required()}
      />
      <EntitySelectField
        name="season"
        label={t("reports.fields.season")}
        entity="Season"
        validate={validations.required()}
      />
      <EntitySelectField
        isMulti
        name="genders"
        label={t("reports.fields.genders")}
        entity="Gender"
      />
      <EntitySelectField
        isMulti
        name="segments"
        label={t("reports.fields.segments")}
        entity="Segment"
      />
      <EntitySelectField
        isMulti
        name="categories"
        label={t("reports.fields.categories")}
        entity="Category"
      />
      <EntitySelectField
        isMulti
        name="niches"
        label={t("reports.fields.niches")}
        entity="Niche"
      />
      <EntitySelectField
        isMulti
        name="topics"
        label={t("reports.fields.topics")}
        entity="Topic"
      />
      <EntitySelectField
        isMulti
        name="trends"
        label={t("reports.fields.trends")}
        entity="Trend"
      />
      <Editor name="contents" />
    </Fragment>
  )
}

export default Form

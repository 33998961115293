import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import Snackbar from "app/common/Snackbar"
import { Image } from "app/entities"

function errorMessage(error, count) {
  if (count > 1) {
    return error.message.includes("violates foreign key")
      ? t("imageClassifications.cannotDeleteImagesInUse")
      : t("imageClassifications.cannotDeleteImagesGenericError")
  }

  return error.message.includes("violates foreign key")
    ? t("imageClassifications.cannotDeleteImageInUse")
    : t("imageClassifications.cannotDeleteImageGenericError")
}

function DestroyImageButton({ ids, children, onImageDeleted }) {
  return (
    <Mutation
      mutation={Image.bulkDestroyMutation}
      redirectOnError={false}
      update={onImageDeleted}
    >
      {(destroy, { error }) => {
        return (
          <Fragment>
            {error && <Snackbar message={errorMessage(error, ids.length)} />}
            <ConfirmationDialog
              onConfirm={() => {
                destroy({ variables: { ids } })
              }}
              message={t("imageClassifications.destroyImageConfirmMessage")}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default DestroyImageButton

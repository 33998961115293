import React from "react"
import { getBestTextColor } from "app/utils/color"

function ColorChipPreview({ data: { color, description } }) {
  return (
    <div
      style={{
        padding: "8px 16px",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: color,
        color: getBestTextColor(color)
      }}
    >
      {description}
    </div>
  )
}

export default ColorChipPreview

import React from "react"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import Checkbox from "app/common/Checkbox"

function CheckToMerge({ item }) {
  return (
    <MultiSelectConsumer>
      {({ toggleItem, items }) => (
        <Checkbox
          checked={items.some(it => it.id === item.id)}
          onChange={() => toggleItem(item)}
        />
      )}
    </MultiSelectConsumer>
  )
}

export default CheckToMerge

import React, { Fragment } from "react"
import { t } from "i18next"
import { Link } from "react-router-dom"
import { omit } from "lodash/fp"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import DateTimePrinter from "app/common/DateTimePrinter"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"
import RenderBrand from "./RenderBrand"
import RenderEvent from "./RenderEvent"

function HeaderWithTooltip({ tooltipText } = {}) {
  return ({ header }) => (
    <Fragment>
      {header}
      <Tooltip title={tooltipText}>
        <InfoIcon
          style={{
            fontSize: "0.75rem",
            marginLeft: "0.1rem",
            marginBottom: "-0.05rem"
          }}
        />
      </Tooltip>
    </Fragment>
  )
}

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "ImageFolder",
  pathPreffix: "imageFolders",
  i18nKey: "imageFolders",
  create: {
    returnHref: data => "/imageFolders"
  },
  defaultCellStyle: { paddingRight: 10 },
  listColumns: [
    {
      header: t("imageFolders.fields.createdAt"),
      path: "createdAt",
      component: DateTimePrinter
    },
    { header: t("imageFolders.fields.id"), path: "id" },
    { header: t("imageFolders.fields.gallery"), path: "gallery.title" },
    { header: t("imageFolders.fields.season"), path: "season.name" },
    {
      header: t("imageFolders.listPage.event"),
      headerComponent: HeaderWithTooltip({
        tooltipText: `${t("imageFolders.fields.catwalkEvent")}
         / ${t("imageFolders.fields.tradeShowEvent")} `
      }),
      component: RenderEvent
    },
    {
      header: t("imageFolders.listPage.dynamicBrandColumn"),
      headerComponent: HeaderWithTooltip({
        tooltipText: `${t("imageFolders.fields.brand")}
         / ${t("imageFolders.fields.stylist")}
         / ${t("imageFolders.fields.booth")}`
      }),
      component: RenderBrand
    },
    { header: t("imageFolders.fields.city"), path: "city.name" },
    { header: t("imageFolders.fields.imageCount"), path: "imageCount" },
    {
      header: t("imageFolders.listPage.source"),
      path: "wgsnFolder.id",
      component: ({ value: id }) => (id ? "WGSN" : "UF")
    },
    {
      header: "",
      path: "id",
      component: ({ value: id }) => (
        <Button
          color="primary"
          component={Link}
          to={`/imageFolders/${id}/images`}
        >
          {t("imageFolders.listPage.categorize")}
        </Button>
      )
    }
  ],
  hasSearch: true,
  upsertForm: Form,
  upsert: {
    parseOutput: omit([
      "galleryThumbnailSource",
      "count",
      "title",
      "gallery",
      "season",
      "city",
      "catwalkEvent",
      "tradeShowEvent",
      "brand",
      "booth",
      "stylist",
      "tradeShowType",
      "store",
      "storeType"
    ]),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("imageFolders.upsertPages.duplicateNameErrorMessage")
        : t("imageFolders.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

import { t } from "i18next"
import React, { Component, Fragment } from "react"
import { compose, omit } from "lodash/fp"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { withProps } from "recompose"
import ActionButton from "app/common/ActionButton"
import FinalForm from "app/common/Form"
import Mutation from "app/common/Mutation"
import { ImageClassification } from "app/entities"
import { withConsumer as withImagesConsumer } from "../context"
import ImageClassificationForm, {
  normalizeItem,
  cleanItem
} from "../ImageClassificationForm"

const { createMutation, updateMutation } = ImageClassification

const styles = {
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 39
  }
}

class Form extends Component {
  submit = mutation => async ({ id, ...input }, { reset }) => {
    try {
      await mutation({ variables: { id, input: cleanItem(input) } })
      this.props.onSubmit()
      reset()
      return null
    } catch (error) {
      return error
    }
  }

  render() {
    const { classes, onCancel, item } = this.props
    const editing = Boolean(item && item.id)

    return (
      <Mutation mutation={editing ? updateMutation : createMutation}>
        {mutation => {
          return (
            <FinalForm
              initialValues={normalizeItem(item)}
              onSubmit={this.submit(mutation)}
            >
              {({ values, form }) => (
                <Fragment>
                  <ImageClassificationForm />
                  <div className={classes.buttons}>
                    <Button
                      color="inherit"
                      onClick={() => {
                        onCancel()
                        form.reset()
                      }}
                    >
                      {t("imageClassifications.cancelButtonLabel")}
                    </Button>
                    <ActionButton
                      type="submit"
                      color="inherit"
                      variant="contained"
                      disabled={
                        Object.values(omit("imageId", values)).filter(Boolean)
                          .length === 0
                      }
                    >
                      {t("imageClassifications.saveButtonLabel")}
                    </ActionButton>
                  </div>
                </Fragment>
              )}
            </FinalForm>
          )
        }}
      </Mutation>
    )
  }
}

export default compose(
  withStyles(styles),
  withImagesConsumer,
  withProps(props => ({ ...props.context }))
)(Form)

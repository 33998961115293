export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Estampas"
  },
  upsertPages: {
    createTitle: "Criar Estampa",
    updateTitle: "Alterar Estampa",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta estampa"
  }
}

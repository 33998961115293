import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "Account",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("account", [
    "id",
    "name",
    "active",
    "ips",
    "maxUsers"
  ]),
  listQuery: graphqlUtils.listQuery("accounts", ["id", "name", "active"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createAccount", "AccountInput"),
  updateMutation: graphqlUtils.updateMutation("updateAccount", "AccountInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteAccount")
}

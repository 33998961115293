import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { SPECIAL_GRID_1, SPECIAL_GRID_2 } from "../gridSettings"
import Image from "./Image"

function makeSpecialGrid(gridSettings, gridRules) {
  const styles = theme => ({
    root: {
      padding: `0 0 ${theme.spacing.unit * 2}px`,
      display: "grid",
      width: "100%",
      ...gridRules
    },
    image: {
      padding: "0 15px"
    }
  })

  function SpecialGrid({ classes, data }) {
    return (
      <div className={classes.root}>
        {gridSettings.map(({ gridArea, imageWidth, imageHeight }, index) => (
          <div key={index} style={{ gridArea }} className={classes.image}>
            <Image
              image={data.images[index]}
              width={imageWidth}
              height={imageHeight}
            />
          </div>
        ))}
      </div>
    )
  }

  return withStyles(styles)(SpecialGrid)
}

export const SpecialGrid1 = makeSpecialGrid(SPECIAL_GRID_1, {
  gridTemplateAreas: '"left top-right" "left bottom-right"',
  gridTemplateColumns: "44% 54%",
  gridColumnGap: "2%"
})

export const SpecialGrid2 = makeSpecialGrid(SPECIAL_GRID_2, {
  gridTemplateAreas:
    '"left top-center top-right" "left bottom-right bottom-right"',
  gridTemplateColumns: "44% 26% 26%",
  gridColumnGap: "2%"
})

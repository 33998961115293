import React, { Fragment } from "react"
import { compose } from "lodash/fp"
import { t } from "i18next"
import { withStyles } from "@material-ui/core/styles"
import { getQueryKey } from "app/utils/graphql"
import { withURLSearch } from "app/common/URLSearch"
import Query from "app/common/Query"
import query from "./publishedImagesCountQuery"
import DateRangePicker, {
  withDateRangeFromSearch
} from "../PublisedContentCommon/DateRangePicker"
import Graph from "../PublisedContentCommon/ColumnsGraph"
import Total from "../PublisedContentCommon/Total"
import Table from "../PublisedContentCommon/Table"
import buildRows from "./buildRows"

const styles = theme => {
  return {
    header: {
      margin: 40,
      display: "flex",
      alignItems: "flex-end"
    },
    DateRangeWrapper: {
      marginLeft: 120
    }
  }
}

function PublicationsTabView({
  classes,
  dateRange: [startDate, endDate],
  setDateRange,
  setSearch
}) {
  const queryKey = getQueryKey(query)

  return (
    <Query query={query} variables={{ startDate, endDate }}>
      {({ data, loading }) => {
        if (loading) {
          return null
        }

        const rows = buildRows(data[queryKey])
        const totalCount = data[queryKey].totalCount

        return (
          <Fragment>
            <div className={classes.header}>
              <Graph columns={rows} totalCount={totalCount} />
              <Total totalCount={totalCount} label={t("dashboard.images")} />
              <div className={classes.DateRangeWrapper}>
                <DateRangePicker
                  onChange={setDateRange}
                  defaultRange={[startDate, endDate]}
                />
              </div>
            </div>

            <Table rows={rows} />
          </Fragment>
        )
      }}
    </Query>
  )
}

export default compose(
  withStyles(styles),
  withURLSearch,
  withDateRangeFromSearch
)(PublicationsTabView)

import { ApolloClient } from "apollo-client"
import { createUploadLink } from "apollo-upload-client"
import { ApolloLink, concat } from "apollo-link"
import { onError as createErrorAfterware } from "apollo-link-error"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject
} from "apollo-cache-inmemory"
import introspectionQueryResultData from "root/fragmentTypes.json"

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

function checkUnauthorizedMessage(graphQLErrors) {
  return graphQLErrors.find(
    ({ message, path }) => message === "Unauthorized" && !path.includes("login")
  )
}

export default function createApolloClient({ getToken, onUnauthorized }) {
  const httpLink = createUploadLink({
    uri: process.env.GRAPHQL_API_URL,
    credentials: "same-origin"
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = getToken()
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`
        }
      })
    }

    return forward(operation)
  })

  const errorAfterware = createErrorAfterware(
    ({ graphQLErrors, networkError }) => {
      let hasUnauthorizedMessage = false

      if (graphQLErrors) {
        if (checkUnauthorizedMessage(graphQLErrors)) {
          hasUnauthorizedMessage = true
        } else {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        }
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
      }

      if (hasUnauthorizedMessage) {
        onUnauthorized()
      }
    }
  )

  const client = new ApolloClient({
    link: errorAfterware.concat(concat(authMiddleware, httpLink)),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: object => {
        switch (object.__typename) {
          /*
           * It is possible to have two objects of `__typename: Tag` with same id but,
           * with different tagType and other properties.
           * Due to how apollo stores cache by default, it will overwrite one when it receiver the other,
           * displaying two different tags as the same
           * By using a more specific rule for caching Tags we are able to avoid this issue
           */
          case "Tag":
            return `${object.__typename}:${object.tagType}:${object.id}`
          default:
            return defaultDataIdFromObject(object)
        }
      }
    })

    /*
     * It seems the we can't set the default
     * fetch policy here because this is broken
     * on the Apollo Client
     *
     * https://github.com/apollographql/apollo-client/issues/3256
     */
  })

  return client
}

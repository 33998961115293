import { t } from "i18next"
import createCRUD from "app/utils/createCRUD"
import BooleanDisplay from "app/common/BooleanDisplay"
import Form from "./Form"
import parseOutput from "./parseOutput"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Segment",
  pathPreffix: "segments",
  i18nKey: "segments",
  listColumns: [
    { header: t("segments.fields.order"), path: "order" },
    { header: t("segments.fields.name"), path: "name" },
    { header: t("segments.fields.parent"), path: "parent.name" },
    {
      header: t("segments.fields.isPage"),
      path: "isPage",
      component: BooleanDisplay
    }
  ],
  upsertForm: Form,
  upsert: {
    parseOutput,
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("segments.upsertPages.duplicateNameErrorMessage")
        : t("segments.upsertPages.genericErrorMessage")
  },
  hasSearch: true,
  hasMerge: true
})

export { ListPage, CreatePage, UpdatePage }

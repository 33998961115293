import * as graphqlUtils from "app/utils/graphql"
import gql from "graphql-tag"

const getTechPackFileId = gql`
  query GetTechPack($id: Int!) {
    techPack(id: $id) {
      id
      techPackFileId
      title
      complete
    }
  }
`

const fields = [
  "id",
  "title",
  "description",
  "styleNumber",
  "sampleSize",
  "sizeRange",
  "colorWays1",
  "colorWays2",
  "colorWays3",
  "fabricSuggestion",
  "price",
  "notes",
  "trims",
  "complete",
  "techPackFolder { id }",
  "techPackFileId",
  "techPackFile { source sizes { small } }",
  "techPackBackFileId",
  "techPackBackFile { source sizes { small } }",
  "techPackTechnicalFileId",
  "techPackTechnicalFile { source }",
  `techPackClassifications {
    id
    market
    product {
      id
      name
    }
    gender {
      id
      name
    }
    regions {
      id
      name
    }
    brand {
      id
      name
    }
    segment {
      id
      name
    }
    color {
      id
      name
    }
    material {
      id
      name
    }
    printType {
      id
      name
    }
    trimmingType {
      id
      name
    }
  }`
]

export default {
  name: "TechPack",
  getQuery: graphqlUtils.getQuery("techPack", fields),
  getTechPackFileId: getTechPackFileId,
  listQuery: gql`
    query TechPacks($page: Int!, $pageSize: Int, $techPackFolderId: Int) {
      techPacks(page: $page, pageSize: $pageSize, techPackFolderId: $techPackFolderId) {
        totalCount
        pageInfo {
          pageCount
        }
        edges {
          node {
            ${graphqlUtils.parseFields(fields)}
          }
        }
      }
    }
  `,
  createMutation: graphqlUtils.createMutation(
    "createTechPack",
    "TechPackInput",
    fields
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTechPack",
    "TechPackInput",
    ["id", "complete"]
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTechPack"),
  bulkDestroyMutation: gql`
    mutation BulkDeleteTechPack($ids: [Int!]!) {
      bulkDeleteTechPack(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `
}

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "CatwalkEvent",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("catwalkEvent", [
    "id",
    "name",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("catwalkEvents", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createCatwalkEvent",
    "CatwalkEventInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateCatwalkEvent",
    "CatwalkEventInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteCatwalkEvent"),
  mergeMutation: graphqlUtils.mergeMutation("mergeCatwalkEvents")
}

export default {
  title: "Detalhes de Tech Pack",
  loadingDetail: "Carregando detalhes de tech pack",
  form: {
    buttons: {
      cancelButtonLabel: "Cancelar",
      saveButtonLabel: "Salvar"
    },
    fields: {
      description: "Description",
      styleNumber: "Style Number",
      sampleSize: "Sample Size",
      sizeRange: "Size Range",
      colorWays1: "Color Ways1",
      colorWays2: "Color Ways2",
      colorWays3: "Color Ways3",
      fabricSuggestion: "Fabric Suggestion",
      trims: "Trims",
      notes: "Notes",
      techPackBackFile: "Back File",
      techPackTechnicalFile: "Technical File",
      price: "Price"
    }
  },
  colorDrawer: {
    title: "Select Color Way {{currentColor}}",
    filter: "Filter",
    close: "Fechar"
  }
}

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import NumericField from "app/common/formFields/Numeric"
import TagField from "app/common/formFields/Tag"
import * as validations from "app/utils/validations"
import TranslationsFields from "app/common/TranslationsFields"

function Form() {
  return (
    <Fragment>
      <TextField
        name="name"
        label={t("stylists.fields.name")}
        validate={validations.required()}
      />
      <NumericField name="order" label={t("stylists.fields.order")} />
      <TagField
        name="wgsnNames"
        label={t("common.formFields.wgsnNames")}
        isMulti
      />
      <TranslationsFields i18nBaseKey="stylists.fields.name" />
    </Fragment>
  )
}

export default Form

export default {
  fields: {
    name: "Nome",
    archived: "Arquivada",
    regions: "Regiões"
  },
  listPage: {
    title: "Em Alta"
  },
  upsertPages: {
    createTitle: "Criar Em Alta",
    updateTitle: "Alterar Em Alta",
    duplicateNameErrorMessage: "Em Alta já cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este Em Alta"
  }
}

import React from "react"
import { t } from "i18next"
import TextField from "app/common/formFields/Text"

export default function TranslationsFields({ i18nBaseKey }) {
  return (
    <>
      <TextField
        name="translations.en"
        label={`${t(i18nBaseKey)} ${t("common.languages.inEnglish")}`}
      />
      <TextField
        name="translations.es"
        label={`${t(i18nBaseKey)} ${t("common.languages.inSpanish")}`}
      />
    </>
  )
}

import React from "react"
import ReactPlayer from "react-player"

class VideoPlayer extends React.Component {
  static defaultProps = {
    getDuration: () => null
  }

  onReady = () => {
    this.props.getDuration(this.player.getDuration())
  }

  componentDidUpdate() {
    if (!ReactPlayer.canPlay(this.props.url)) {
      this.props.getDuration(null)
    }
  }

  ref = player => {
    this.player = player
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          paddingTop: "56.25%",
          minWidth: 500
        }}
      >
        <ReactPlayer
          key={this.props.url}
          ref={this.ref}
          onReady={this.onReady}
          style={{
            position: "absolute",
            top: 0,
            left: 0
          }}
          url={this.props.url}
          width="100%"
          height="100%"
          youtubeConfig={{ playerVars: { controls: 1 } }}
        />
      </div>
    )
  }
}

export default VideoPlayer

import React from "react"
import { compose } from "lodash/fp"
import { t } from "i18next"
import { withURLSearch } from "app/common/URLSearch"
import Paper from "@material-ui/core/Paper"
import { withStyles } from "@material-ui/core/styles"
import Tabs from "app/common/Tabs"
import TaxonomyView from "./TaxonomyView"
import PublicationsView from "./PublicationsView"
import ImagesView from "./ImagesView"

const styles = theme => {
  return {
    header: {
      margin: 40,
      display: "flex",
      alignItems: "flex-end"
    },
    DateRangeWrapper: {
      marginLeft: 120
    }
  }
}

function Dashboard({
  client: apolloClient,
  classes,
  setSearch,
  search: { tabIndex = 0 }
}) {
  function handleTabChange(event, tabIndex) {
    setSearch({ tabIndex })
  }

  const tabsLabels = [
    t("dashboard.tabsTitles.images"),
    t("dashboard.tabsTitles.publications"),
    t("dashboard.tabsTitles.taxonomy")
  ]

  return (
    <Paper>
      <Tabs
        tabIndex={Number(tabIndex)}
        onChange={handleTabChange}
        labels={tabsLabels}
      />
      {/* eslint-disable-next-line react/jsx-key */}
      {[<ImagesView />, <PublicationsView />, <TaxonomyView />][tabIndex]}
    </Paper>
  )
}

export default compose(
  withStyles(styles),
  withURLSearch
)(Dashboard)

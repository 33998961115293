import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "RecommendedTag",
  getQuery: graphqlUtils.getQuery("recommendedTag", [
    "id",
    "tagType",
    "tagId",
    "tagName",
    "cardImage { id source sizes { small } }",
    "publicationDate"
  ]),
  listQuery: graphqlUtils.listQuery(
    "recommendedTags",
    [
      "id",
      "tagType",
      "tagName",
      "cardImage { id source sizes { small } }",
      "publicationDate"
    ],
    { published: "Boolean" }
  ),
  createMutation: graphqlUtils.createMutation(
    "createRecommendedTag",
    "RecommendedTagInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateRecommendedTag",
    "RecommendedTagInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteRecommendedTag")
}

import React from "react"

function Graph({ columns, totalCount }) {
  const GRAPH_HEIGHT = 70
  const BAR_MIN_HEIGHT = 16

  return (
    <div
      style={{
        display: "flex",
        margin: "0 12px",
        alignItems: "flex-end",
        height: GRAPH_HEIGHT
      }}
    >
      {columns.map(column => {
        return (
          <div
            key={column.label}
            style={{
              margin: "0 3px",
              width: 16,
              borderRadius: 8,
              height:
                totalCount > 0
                  ? Math.ceil(
                      BAR_MIN_HEIGHT +
                        (GRAPH_HEIGHT / totalCount) * column.count
                    )
                  : BAR_MIN_HEIGHT,
              backgroundColor: column.color
            }}
          />
        )
      })}
    </div>
  )
}

export default Graph

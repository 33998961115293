export default {
  selectAnotherFolder: "Selecionar outra pasta",
  fields: {
    booth: "Estande",
    brand: "Marca/Loja",
    catwalkEvent: "Evento de desfile",
    city: "Cidade",
    comments: "Comentários",
    createdAt: "Data de criação",
    createdBy: "Criado por",
    gallery: "Galeria",
    id: "ID",
    imageCount: "Quantidade",
    pending: "Pendente",
    publication: "Publicação",
    publicationDate: "Data de publicação",
    published: "Publicado",
    season: "Temporada",
    storeType: "Tipo de loja",
    stylist: "Estilista",
    title: "Título",
    tradeShowEvent: "Evento de feira",
    tradeShowType: "Tipo de feira"
  },
  listPage: {
    title: "Imagens",
    dynamicBrandColumn: "Marca",
    event: "Evento",
    source: "Origem",
    categorize: "Categorizar",
    destroyButtonLabel: "Excluir imagem",
    addImageClassificationButtonLabel: "Adicionar classificação",
    destroyConfirmMessage:
      "Você tem certeza que deseja excluir esta imagem? Esta ação não pode ser desfeita"
  },
  upsertPages: {
    createTitle: "Criar nova pasta",
    genericErrorMessage: "Houve um problema ao salvar esta pasta",
    updateTitle: "Alterar pasta"
  },
  manage: {
    tagsPlaceholder: "Arraste alguma tag para cá",
    uploadPlaceholder: "Arraste uma ou mais imagens para adicionar a esta pasta"
  }
}

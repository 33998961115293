import { t } from "i18next"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Divider from "@material-ui/core/Divider"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { AuthConsumer } from "app/common/AuthContext"
import { sortBy, deburr } from "lodash"

export const menuItems = [
  {
    key: "publications",
    order: false,
    text: t("common.menu.publications"),
    items: [
      { to: "/trendReports", text: t("common.menu.trendReports") },
      { to: "/tradeShowReports", text: t("common.menu.tradeShowReports") },
      { to: "/catwalkReports", text: t("common.menu.catwalkReports") },
      { to: "/retailReports", text: t("common.menu.retailReports") },
      {
        to: "/technicalAreaReports",
        text: t("common.menu.technicalAreaReports")
      }
    ]
  },
  { to: "/imageFolders", text: t("common.menu.imageFolders") },
  { to: "/techPackFolders", text: t("common.menu.techPackFolders") },
  // { to: "/dailyImages", text: t("common.menu.dailyImages") },
  // { to: "/dailyContents", text: t("common.menu.dailyContents") },
  // { to: "/recommendedTags", text: t("common.menu.recommendedTags") },
  { divider: true },
  // { to: "/wgsnFolders", text: t("common.menu.wgsnFolders") },
  {
    key: "tags",
    text: t("common.menu.upsertTags"),
    order: true,
    items: [
      { to: "/authors", text: t("common.menu.authors") },
      { to: "/trimmingTypes", text: t("common.menu.trimmingTypes") },
      { to: "/categories", text: t("common.menu.categories") },
      { to: "/cities", text: t("common.menu.cities") },
      { to: "/colors", text: t("common.menu.colors") },
      { to: "/trends", text: t("common.menu.trends") },
      { to: "/trends-v2", text: t("common.menu.trendsV2") },
      { to: "/printTypes", text: t("common.menu.printTypes") },
      { to: "/booths", text: t("common.menu.booths") },
      { to: "/stylists", text: t("common.menu.stylists") },
      { to: "/catwalkEvents", text: t("common.menu.catwalkEvents") },
      { to: "/tradeShowEvents", text: t("common.menu.tradeShowEvents") },
      { to: "/regions", text: t("common.menu.regions") },
      { to: "/galleries", text: t("common.menu.galleries") },
      { to: "/genders", text: t("common.menu.genders") },
      { to: "/brands", text: t("common.menu.brands") },
      { to: "/materials", text: t("common.menu.materials") },
      { to: "/niches", text: t("common.menu.niches") },
      { to: "/countries", text: t("common.menu.countries") },
      { to: "/products", text: t("common.menu.products") },
      { to: "/segments", text: t("common.menu.segments") },
      { to: "/seasons", text: t("common.menu.seasons") },
      { to: "/technicalAreaType", text: t("common.menu.technicalAreaType") },
      { to: "/generalTrendTypes", text: t("common.menu.generalTrendTypes") },
      { to: "/tradeShowTypes", text: t("common.menu.tradeShowTypes") },
      { to: "/storeTypes", text: t("common.menu.storeTypes") },
      { to: "/megaTrendTypes", text: t("common.menu.megaTrendTypes") },
      { to: "/topics", text: t("common.menu.topics") }
    ]
  },
  { divider: true },
  { to: "/administrators", text: t("common.menu.administrators") },
  { to: "/accounts", text: t("common.menu.accounts") },
  { to: "/users", text: t("common.menu.users") },
  { divider: true }
]

const styles = theme => ({
  drawerPaper: {
    width: 340
  },
  listItemText: {
    fontSize: 14
  },
  nested: {
    paddingLeft: 24
  }
})

class Menu extends Component {
  state = { menuOpen: {} }

  toggleMenuItem(itemKey) {
    let { menuOpen } = this.state
    menuOpen[itemKey] = !menuOpen[itemKey]
    this.setState({ menuOpen })
  }

  renderMenuLink = (
    {
      to,
      text,
      divider,
      items,
      key,
      nested = false,
      testid = "link",
      order = false
    },
    index
  ) => {
    if (divider) {
      return <Divider key={index} data-test="divider" />
    }

    let open = false
    if (key) {
      const { menuOpen } = this.state
      open = Boolean(menuOpen[key])
    }
    const { classes } = this.props
    const orderedItems = order
      ? sortBy(items, item => deburr(item.text))
      : items
    return (
      <React.Fragment key={index}>
        <ListItem
          key={index}
          button
          component={to ? Link : null}
          to={to}
          data-test={items ? "header" : testid}
          onClick={() => items && this.toggleMenuItem(key)}
        >
          <ListItemText
            primary={text}
            classes={{ primary: this.props.classes.listItemText }}
            data-test={`${testid}-text`}
          />
          {items ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItem>
        {items && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.nested}>
              {orderedItems.map((item, index) =>
                this.renderMenuLink({ ...item, testid: "nested-link" }, index)
              )}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { classes, open, onClose } = this.props

    return (
      <Drawer
        open={open}
        onClose={onClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <List>
          {menuItems.map(this.renderMenuLink)}
          <AuthConsumer>
            {({ setToken }) => (
              <ListItem
                button
                data-test="logout"
                onClick={() => setToken(null)}
              >
                <ListItemText
                  primary={t("common.logout")}
                  classes={{ primary: this.props.classes.listItemText }}
                />
              </ListItem>
            )}
          </AuthConsumer>
        </List>
      </Drawer>
    )
  }
}

export default withStyles(styles)(Menu)

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "Niche",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("niche", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("niches", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation("createNiche", "NicheInput"),
  updateMutation: graphqlUtils.updateMutation("updateNiche", "NicheInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteNiche"),
  mergeMutation: graphqlUtils.mergeMutation("mergeNiches")
}

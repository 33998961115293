import * as graphqlUtils from "app/utils/graphql"

export default {
  name: "DailyImage",
  getQuery: graphqlUtils.getQuery("dailyImage", [
    "id",
    "publicationDate",
    "reportId",
    "title",
    "imageFileId",
    "imageFile { source sizes { small } }"
  ]),
  listQuery: graphqlUtils.listQuery("dailyImages", [
    "id",
    "reportId",
    "title",
    "publicationDate"
  ]),
  createMutation: graphqlUtils.createMutation(
    "createDailyImage",
    "DailyImageInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateDailyImage",
    "DailyImageInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteDailyImage")
}

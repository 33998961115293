import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { ImageClassification } from "app/entities"

function DestroyImageClassificationButton({ id, children }) {
  return (
    <Mutation mutation={ImageClassification.destroyMutation}>
      {destroy => {
        return (
          <Fragment>
            <ConfirmationDialog
              onConfirm={() => destroy({ variables: { id } })}
              message={t("imageClassifications.destroyConfirmMessage")}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default DestroyImageClassificationButton

import { SelectionState, Modifier, EditorState } from "draft-js"

function update(editorState, block, data) {
  const content = editorState.getCurrentContent()
  const selection = new SelectionState({
    anchorKey: block.getKey(),
    anchorOffset: 0,
    focusKey: block.getKey(),
    focusOffset: block.getLength()
  })

  const newContentState = Modifier.mergeBlockData(content, selection, data)

  return EditorState.push(editorState, newContentState, "change-block-data")
}

export default update

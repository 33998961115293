import React from "react"

function withImageItem(Component) {
  return class Item extends React.Component {
    state = {
      imageWidth: 0,
      imageHeight: 0
    }

    componentDidMount() {
      this.updateDimensions()
    }

    componentDidUpdate(prevProps) {
      if (prevProps.item !== this.props.item) {
        this.updateLayout()
        this.updateDimensions()
      }
    }

    componentWillUnmount() {
      this.unmounted = true
    }

    updateLayout = () => {
      this.props.measure()
      this.props.updateLayout()
    }

    updateDimensions = () => {
      const { imageCache, item } = this.props

      imageCache[item.id].then(image => {
        if (!this.unmounted) {
          this.setState(
            {
              imageWidth: image.naturalWidth,
              imageHeight: image.naturalHeight
            },
            this.updateLayout
          )
        }
      })
    }

    render() {
      const { imageWidth, imageHeight } = this.state
      const { columnWidth } = this.props
      const width = columnWidth - 20
      const height = width * (imageHeight / imageWidth) || 0

      return <Component {...this.props} height={height} width={width} />
    }
  }
}

export default withImageItem

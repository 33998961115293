import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "TradeShowType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("tradeShowType", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("tradeShowTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createTradeShowType",
    "TradeShowTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTradeShowType",
    "TradeShowTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTradeShowType"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTradeShowTypes")
}

import { createMuiTheme } from "@material-ui/core/styles"
import grey from "@material-ui/core/colors/grey"
import blue from "@material-ui/core/colors/blue"

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    useNextVariants: true,
    h3: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "2.8125rem",
      fontWeight: 400,
      lineHeight: "1.13333em",
      marginLeft: "-.02em"
    }
  },
  palette: {
    primary: {
      activeOnDarkBg: blue[500],
      contrastText: "#fff",
      dark: "#1a1a1a",
      light: "#626262",
      main: "#2c2c2c"
    },
    links: {
      default: "#2996da"
    },
    background: {
      dark: grey[900],
      darkHover: grey[800]
    },
    grey: {
      A300: "#eaeaea",
      A800: "#818181"
    }
  },
  zIndex: {
    reactSelect: 2000
  }
})

export default theme

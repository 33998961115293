import React, { Component } from "react"
import cx from "classnames"
import { withStyles } from "@material-ui/core/styles"
import BaseEditor from "draft-js-plugins-editor"
import { EditorState } from "draft-js"
import createRichButtonsPlugin from "draft-js-richbuttons-plugin"
import transformContentIntoEditorState from "./transformContentIntoEditorState"
import transformEditorStateIntoContent from "./transformEditorStateIntoContent"
import InlineToolbar, { inlineToolbarPlugins } from "./InlineToolbar"
import TopToolbar, { topToolbarPlugins } from "./TopToolbar"

const richButtonsPlugin = createRichButtonsPlugin()

const styles = theme => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    width: "100%",
    maxWidth: "95vw", // prevents editor getting too wide when accidentally pasting a base64
    margin: "0 auto",
    background: theme.palette.common.white,
    position: "relative"
  },
  editorWrapper: {
    marginTop: 15
  },
  topToolbarWrapper: {
    position: "sticky",
    top: 60,
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    background: "white",
    zIndex: theme.zIndex.appBar
  },
  editor: {
    width: "90%",
    margin: "auto",
    minHeight: "calc(100vh - 160px)",
    padding: theme.spacing.unit * 2,
    fontFamily: theme.typography.fontFamily,
    '& [data-block="true"]:not(h2)': {
      marginBottom: theme.spacing.unit * 2
    },
    "& h2": {
      fontSize: 28,
      marginBottom: theme.spacing.unit * 4
    },
    "& ul": {
      listStyle: "circle",
      marginLeft: 15
    },
    "& ol": {
      listStyle: "decimal",
      marginLeft: 15
    }
  }
})

class Editor extends Component {
  state = {
    editorState: this.props.initialValue
      ? transformContentIntoEditorState(this.props.initialValue)
      : EditorState.createEmpty()
  }

  static defaultProps = {
    onChange: () => null
  }

  onChange = editorState => {
    this.setState({ editorState })
    this.props.onChange(transformEditorStateIntoContent(editorState))
  }

  focusEditor = () => {
    this.editorRef.focus()
  }

  getValue = () => {
    return transformEditorStateIntoContent(this.state.editorState)
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        {!this.props.hideTopToolbar && (
          <div className={this.props.classes.topToolbarWrapper}>
            <TopToolbar />
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={this.props.classes.editorWrapper}
          onClick={this.focusEditor}
        >
          <div
            className={cx(
              this.props.classes.editor,
              this.props.editorClassName
            )}
          >
            <BaseEditor
              spellCheck
              plugins={[
                ...inlineToolbarPlugins,
                ...topToolbarPlugins,
                richButtonsPlugin
              ]}
              editorState={this.state.editorState}
              onChange={this.onChange}
              ref={ref => {
                this.editorRef = ref
              }}
            />
          </div>
          <InlineToolbar />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Editor)

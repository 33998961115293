import React, { Component } from "react"

class ImageCache extends Component {
  state = { cache: {}, items: null }

  static getDerivedStateFromProps(props, prevState) {
    if (props.items !== prevState.items) {
      return {
        items: props.items,
        cache: props.items
          ? props.items.reduce((acc, item) => {
              const key = props.getKey(item)
              const srcset = props.getSrcset(item)

              const promise =
                prevState.cache[key] ||
                new Promise(resolve => {
                  const image = new Image()
                  image.onload = () => resolve(image)
                  image.srcset = srcset
                })

              return {
                ...acc,
                [key]: promise
              }
            }, {})
          : {}
      }
    }

    return null
  }

  render() {
    return this.props.children(this.state.cache)
  }
}

function withImageCache(config) {
  return WrappedComponent => props => (
    <ImageCache {...config} items={props.items}>
      {cache => <WrappedComponent {...props} imageCache={cache} />}
    </ImageCache>
  )
}

export default ImageCache
export { withImageCache }

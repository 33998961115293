import React, { Fragment, Component } from "react"
import { t } from "i18next"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import Modal from "app/common/Modal"

const styles = theme => ({
  paper: {
    width: "50%"
  }
})

const StyledModal = withStyles(styles)(Modal)

class MergeModal extends Component {
  state = {
    isOpen: false
  }

  handleOpen = () => this.setState({ isOpen: true })
  handleClose = () => this.setState({ isOpen: false })

  render() {
    return (
      <Fragment>
        <Button data-test="open-button" onClick={this.handleOpen}>
          {t("mergeTags.openModalButton")}
        </Button>
        <StyledModal isOpen={this.state.isOpen}>
          {this.props.children(this.handleClose)}
        </StyledModal>
      </Fragment>
    )
  }
}

export default MergeModal

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "MegaTrendType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("megaTrendType", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("megaTrendTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createMegaTrendType",
    "MegaTrendTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateMegaTrendType",
    "MegaTrendTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteMegaTrendType"),
  mergeMutation: graphqlUtils.mergeMutation("mergeMegaTrendTypes")
}

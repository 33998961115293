import gql from "graphql-tag"

const getQuery = gql`
  query GetTechPackFile($id: Int!) {
    techPackFile(id: $id) {
      id
      source
    }
  }
`

const uploadUrl = gql`
  mutation TechPackFileUploadUrl($input: TechPackFileUploadUrlInput!) {
    techPackFileUploadUrl(input: $input) {
      key
      url
      headers {
        key
        value
      }
    }
  }
`

const createTechPackFile = gql`
  mutation CreateTechPackFile($input: CreateTechPackFileInput!) {
    createTechPackFile(input: $input) {
      id
      source
    }
  }
`

export default {
  name: "TechPackFile",
  getQuery,
  uploadUrl,
  createTechPackFile
}

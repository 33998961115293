import React from "react"
import cx from "classnames"
import { Button, withStyles } from "@material-ui/core"

const styles = theme => ({
  button: {
    boxShadow: `0 2px 2px rgba(226, 226, 226, 0.5)`,
    margin: "0 15px",
    fontSize: 13,
    fontWeight: 600,
    padding: "8px 26px 9px",
    borderRadius: 3,
    color: theme.palette.primary.main,
    "&:last-child": {
      marginRight: 0
    }
  },
  buttonPrimary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 0 2px rgba(226, 226, 226, 0)`
    }
  },
  buttonSecondary: {
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: theme.palette.grey.A300,
      boxShadow: `0 0 2px rgba(226, 226, 226, 0)`
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  }
})

function StyledButton({ classes, text, primary, ...props }) {
  return (
    <Button
      disableRipple
      className={cx(
        classes.button,
        primary ? classes.buttonPrimary : classes.buttonSecondary
      )}
      {...props}
    >
      {text}
    </Button>
  )
}

export default withStyles(styles)(StyledButton)

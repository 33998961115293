import { t } from "i18next"
import React from "react"
import Button from "app/common/Button"

function SaveButton({ disabled, onClick }) {
  return (
    <Button
      type="submit"
      text={t("createPage.saveButtonLabel")}
      disabled={disabled}
      primary
      onClick={onClick}
    />
  )
}

function PreviewButton({ disabled, onClick }) {
  return (
    <Button
      data-testid="preview-button"
      type="submit"
      text={t("createPage.previewButtonLabel")}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

function Buttons({ disabled, formRef, hasSaveAndPreview = false }) {
  return (
    <>
      {hasSaveAndPreview && (
        <PreviewButton
          disabled={disabled}
          onClick={() => formRef.change("preview", true)}
        />
      )}
      <SaveButton
        disabled={disabled}
        onClick={() => formRef.change("preview", false)}
      />
    </>
  )
}

export default Buttons

import { compact } from "lodash/fp"
import { get, omit } from "lodash"
import React, { Fragment } from "react"
import { Redirect } from "react-router-dom"
import { Paper, withStyles } from "@material-ui/core"
import Mutation from "app/common/Mutation"
import Snackbar from "app/common/Snackbar"
import Form from "app/common/Form"
import PageHeader from "app/common/PageHeader"
import PageFooter from "app/common/PageFooter"
import { openReportWindow } from "app/utils/reportWindowOpener"

const styles = theme => ({
  panel: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 64
  })
})

function renderButtons(
  buttons,
  submitting,
  formRef,
  hasSaveAndPreview = false
) {
  if (Array.isArray(buttons)) {
    return compact(buttons).map((button, index) => (
      <button.type
        key={index}
        disabled={submitting}
        {...button.props}
        formRef={formRef}
        hasSaveAndPreview={hasSaveAndPreview}
      />
    ))
  }

  return (
    <buttons.type
      {...buttons.props}
      disabled={submitting}
      formRef={formRef}
      hasSaveAndPreview={hasSaveAndPreview}
    />
  )
}

function FormPage({
  classes,
  title,
  mutation,
  children,
  initialValues,
  returnHref,
  parseVariables,
  successMessage,
  errorMessage,
  paperWrapper,
  buttons,
  entitySlug,
  hasSaveAndPreview = false
}) {
  return (
    <Mutation redirectOnError={false} mutation={mutation} data-test="mutation">
      {(mutate, { data } = {}) => (
        <Form
          data-test="form"
          initialValues={initialValues}
          onSubmit={values =>
            mutate({
              variables: parseVariables(omit(values, ["preview"]))
            })
              .then(() => {
                const previewValue = get(values, "preview", false)
                const isPreview = Boolean(previewValue)

                if (isPreview) {
                  openReportWindow(entitySlug, previewValue)
                }

                return null
              })
              .catch(error => error)
          }
        >
          {({ submitting, submitErrors, submitSucceeded, ...props }) => {
            const previewValue = get(props, "values.preview", false)
            const isPreview = Boolean(previewValue)

            return submitSucceeded && Boolean(data) && !isPreview ? (
              <Fragment>
                <Snackbar
                  data-test="success-message"
                  message={successMessage}
                />
                <Redirect data-test="redirect" push to={returnHref(data)} />
              </Fragment>
            ) : (
              <Fragment>
                {!submitting && submitErrors && (
                  <Snackbar
                    data-test="error-message"
                    message={
                      typeof errorMessage === "function"
                        ? errorMessage(submitErrors)
                        : errorMessage
                    }
                  />
                )}
                <PageHeader data-test="page-header" title={title} />
                <Paper className={classes.panel}>{children}</Paper>
                <PageFooter returnHref={returnHref}>
                  {buttons &&
                    renderButtons(
                      buttons,
                      submitting,
                      props.form,
                      hasSaveAndPreview
                    )}
                </PageFooter>
              </Fragment>
            )
          }}
        </Form>
      )}
    </Mutation>
  )
}

export default withStyles(styles)(FormPage)

import { t } from "i18next"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { ChromePicker } from "react-color"
import Form from "app/common/Form"
import BaseField from "app/common/formFields/Base"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"

function ColorChipDialog({ open, initialValues, onSubmit, onCancel }) {
  return (
    <Dialog open={open} disableEscapeKeyDown>
      <Form data-test="form" initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Fragment>
            <DialogTitle>
              {t("reports.editor.contentTypes.colorChip.dialog.title")}
            </DialogTitle>
            <DialogContent>
              <TextField
                name="description"
                label={t(
                  "reports.editor.contentTypes.colorChip.fields.description"
                )}
                validate={validations.required()}
              />
              <TextField
                name="color"
                label={t("reports.editor.contentTypes.colorChip.fields.color")}
                validate={validations.composeValidators(
                  validations.required(),
                  validations.color()
                )}
              />
              <BaseField name="color">
                {({ input: { value, onChange } }) => (
                  <ChromePicker
                    className="tete"
                    color={value}
                    onChange={({ hex }) => onChange(hex)}
                    disableAlpha
                  />
                )}
              </BaseField>
            </DialogContent>
            <DialogActions>
              <Button
                data-test="cancel-button"
                onClick={onCancel}
                color="primary"
              >
                {t("common.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common.save")}
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Form>
    </Dialog>
  )
}

export default ColorChipDialog

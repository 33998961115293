export default {
  fields: {
    name: "Nome",
    order: "Ordem"
  },
  listPage: {
    title: "Gêneros"
  },
  upsertPages: {
    createTitle: "Criar Gênero",
    updateTitle: "Alterar Gênero",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este gênero"
  }
}

import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "City",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("city", [
    "id",
    "name",
    "wgsnNames",
    ["country", ["id", "name"]],
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery(
    "cities",
    ["id", "name", ["country", ["id", "name"]]],
    { search: "String" }
  ),
  createMutation: graphqlUtils.createMutation("createCity", "CityInput"),
  updateMutation: graphqlUtils.updateMutation("updateCity", "CityInput"),
  destroyMutation: graphqlUtils.destroyMutation("deleteCity"),
  mergeMutation: graphqlUtils.mergeMutation("mergeCities")
}

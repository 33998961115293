import React, { Component, createContext } from "react"
import { differenceBy, unionBy } from "lodash/fp"

const { Provider, Consumer: MultiSelectConsumer } = createContext({})

export function toggleItemUpdater(item) {
  return ({ items: currentStateItems }) => {
    const hasItem = currentStateItems.some(it => it.id === item.id)
    if (hasItem) {
      return { items: currentStateItems.filter(it => it.id !== item.id) }
    }
    return { items: [...currentStateItems, item] }
  }
}

class MultiSelectProvider extends Component {
  state = {
    items: []
  }

  toggleItem = item => this.setState(toggleItemUpdater(item))

  clearAll = () => this.setState({ items: [] })

  addMany = list =>
    this.setState(prevState => ({
      items: unionBy("id", prevState.items, list)
    }))

  clearItems = list => {
    this.setState(prevState => ({
      items: differenceBy("id", prevState.items, list)
    }))
  }

  render() {
    return (
      <Provider
        value={{
          items: this.state.items,
          toggleItem: this.toggleItem,
          clearItems: this.clearItems,
          addMany: this.addMany,
          clearAll: this.clearAll
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

function withProvider(Component) {
  return props => (
    <MultiSelectProvider>
      <Component {...props} />
    </MultiSelectProvider>
  )
}

export default MultiSelectProvider
export { withProvider as withMultiSelectProvider, MultiSelectConsumer }

import React from "react"
import imageSrcToNew from "app/utils/imageSrcToNew"

function RoundedBorderImage({
  classes,
  value,
  borderRadius = 10,
  width = 72,
  height = 55
}) {
  return (
    <img
      srcSet={imageSrcToNew(value, "small")}
      alt="no description"
      style={{
        borderRadius,
        width,
        height
      }}
    />
  )
}

export default RoundedBorderImage

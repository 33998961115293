import React from "react"
import Editor from "./Editor"
import BaseField from "app/common/formFields/Base"

export default function({ name }) {
  return (
    <BaseField name={name}>
      {({ input: { value, onChange } }) => (
        <Editor initialValue={value} onChange={onChange} />
      )}
    </BaseField>
  )
}

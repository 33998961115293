export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Tipos de Áreas Técnicas"
  },
  upsertPages: {
    createTitle: "Criar novo Tipo",
    updateTitle: "Alterar Tipo",
    genericErrorMessage: "Houve um problema ao salvar este tipo"
  }
}

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Cores"
  },
  upsertPages: {
    createTitle: "Criar Cor",
    updateTitle: "Alterar Cor",
    duplicateNameErrorMessage: "Cor já cadastrada",
    genericErrorMessage: "Houve um problema ao salvar esta cor"
  }
}

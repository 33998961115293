export default {
  fields: {
    title: "Título",
    order: "Ordem",
    type: "Tipo"
  },
  listPage: {
    title: "Galerias"
  },
  upsertPages: {
    createTitle: "Criar Galeria",
    updateTitle: "Alterar Galeria",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta galeria"
  }
}

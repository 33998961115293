export function htmlToDOM(html) {
  const wrapper = document.createElement("div")
  wrapper.innerHTML = html
  return wrapper.children
}

export function isImageLoaded(image) {
  return new Promise(resolve => {
    if (image.complete && image.naturalWidth > 0) {
      return resolve()
    }

    function onLoad() {
      image.removeEventListener("load", onLoad)
      resolve()
    }

    return image.addEventListener("load", onLoad)
  })
}

import React from "react"
import ImageList from "app/common/ImageList"
import Item from "./Item"

const TOP_BAR_HEIGHT = 64

function PreviewContent({ wgsnFolder }) {
  const items = wgsnFolder.imageUrlsWithTags.map(
    ({ url: source, tags }, index) => ({
      id: index,
      imageFile: {
        sizes: { small: source }
      },
      tags
    })
  )

  return (
    <ImageList
      itemComponent={Item}
      items={items}
      headerHeight={TOP_BAR_HEIGHT}
    />
  )
}

export default PreviewContent

import gql from "graphql-tag"
import * as graphqlUtils from "app/utils/graphql"

const fields = [
  "id",
  [
    "image",
    [
      "id",
      [
        "imageClassifications",
        [
          "id",
          "market",
          ["product", ["id", "name"]],
          ["gender", ["id", "name"]],
          ["regions", ["id", "name"]],
          ["segment", ["id", "name"]],
          ["category", ["id", "name"]],
          ["color", ["id", "name"]],
          ["material", ["id", "name"]],
          ["printType", ["id", "name"]],
          ["trimmingType", ["id", "name"]],
          ["brand", ["id", "name"]],
          ["topic", ["id", "name"]],
          ["trend", ["id", "name"]]
        ]
      ]
    ]
  ]
]

export default {
  name: "ImageClassification",
  createMutation: graphqlUtils.createMutation(
    "createImageClassification",
    "ImageClassificationInput",
    fields
  ),
  bulkCreateMutation: gql`
    mutation BulkCreateImageClassification($ids: [Int!]!, $input: BulkImageClassificationInput!) {
      bulkCreateImageClassification(ids: $ids, input: $input) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  updateMutation: graphqlUtils.updateMutation(
    "updateImageClassification",
    "ImageClassificationInput",
    fields
  ),
  destroyMutation: graphqlUtils.destroyMutation(
    "deleteImageClassification",
    fields
  ),
  cleanRegionsMutation: graphqlUtils.destroyMutation(
    "cleanImageClassificationRegions",
    fields
  ),
  bulkDestroyMutation: gql`
    mutation BulkDeleteImageClassification($ids: [Int!]!) {
      bulkDeleteImageClassification(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  bulkCleanRegionsMutation: gql`
    mutation BulkCleanImageClassificationRegions($ids: [Int!]!) {
      bulkCleanImageClassificationRegions(ids: $ids) {
        ${graphqlUtils.parseFields(fields)}
      }
    }
  `,
  folderDeleteImageClassification: gql`
    mutation FolderDeleteImageClassification($imageFolderId: Int!) {
      folderDeleteImageClassification(imageFolderId: $imageFolderId)
    }
  `,
  folderCreateImageClassification: gql`
    mutation FolderCreateImageClassification(
      $imageFolderId: Int!
      $input: BulkImageClassificationInput!
    ) {
      folderCreateImageClassification(
        imageFolderId: $imageFolderId
        input: $input
      )
    }
  `
}

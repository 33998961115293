import { times } from "lodash/fp"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import * as gridSettings from "../gridSettings"
import Image from "./Image"

const styles = theme => ({
  root: {
    display: "flex",
    padding: `0 0 ${theme.spacing.unit * 2}px`
  },
  image: {
    padding: "0 15px"
  }
})

function DefaultGrid({ classes, data }) {
  const { imageNumber, imageWidth, imageHeight } = gridSettings[data.gridType]

  return (
    <div className={classes.root}>
      {times(
        index => (
          <div
            key={index}
            className={classes.image}
            style={{ width: `${100 / imageNumber}%` }}
          >
            <Image
              image={data.images[index]}
              width={imageWidth}
              height={imageHeight}
            />
          </div>
        ),
        imageNumber
      )}
    </div>
  )
}

export default withStyles(styles)(DefaultGrid)

export default {
  listPage: {
    title: "Publicações de Tendência"
  },
  upsertPages: {
    createTitle: "Criar Publicação de Tendência",
    updateTitle: "Alterar Publicação de Tendência",
    genericErrorMessage: "Houve um problema ao salvar este publicação"
  }
}

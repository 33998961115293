import { t } from "i18next"
import { omit } from "lodash/fp"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "Administrator",
  pathPreffix: "administrators",
  i18nKey: "administrators",
  listColumns: [
    { header: t("administrators.fields.name"), path: "name" },
    { header: t("administrators.fields.email"), path: "email" }
  ],
  upsertForm: Form,
  create: {
    initialValues: {
      admin: true
    }
  },
  upsert: {
    parseOutput: omit(["account"]),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("administrators.upsertPages.duplicateEmailErrorMessage")
        : t("administrators.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

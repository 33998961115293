import { Base64 } from "js-base64"
import { noop, pipe, map, compact, join } from "lodash/fp"
import { EditorState } from "draft-js"
import { stateFromHTML } from "draft-js-import-html"
import serializeSpecialContent from "./serializeSpecialContent"

function handleText({ body }) {
  if (body.substr(0, 3) !== "<p>") {
    return `<p>${body}</p>`
  }

  return body
}

function handleSectionTitle({ title }) {
  return `<h2>${title}</h2>`
}

function handleSpecialContent({ contentType, ...data }) {
  return serializeSpecialContent({
    type: contentType,
    ...data
  })
}

function handleContent(content) {
  const handler =
    {
      TEXT: handleText,
      SECTION_TITLE: handleSectionTitle,
      TECH_PACK: handleSpecialContent,
      COLOR_CHIP: handleSpecialContent,
      IMAGE_GRID: handleSpecialContent,
      VIDEO: handleSpecialContent
    }[content.contentType] || noop

  return handler(content)
}

const transformContentIntoEditorState = pipe(
  map(handleContent),
  compact,
  join(""),
  html =>
    stateFromHTML(html, {
      includeEmptyBlock: true,
      customBlockFn: element => {
        if (element.getAttribute("data-custom-block") === "true") {
          const data = JSON.parse(Base64.decode(element.innerHTML))
          return { type: "atomic", data }
        }

        return null
      }
    }),
  EditorState.createWithContent
)

export default transformContentIntoEditorState

import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { ImageClassification } from "app/entities"

function CleanImageClassificationRegionsButton({ ids, children, onUpdate }) {
  return (
    <Mutation
      mutation={ImageClassification.bulkCleanRegionsMutation}
      update={onUpdate}
    >
      {destroy => {
        return ids.length ? (
          <Fragment>
            <ConfirmationDialog
              onConfirm={() => destroy({ variables: { ids } })}
              message={t("imageClassifications.cleanRegionsConfirmMessage")}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        ) : null
      }}
    </Mutation>
  )
}

export default CleanImageClassificationRegionsButton

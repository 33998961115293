import { t } from "i18next"
import cx from "classnames"
import React, { Component } from "react"
import { compact, get } from "lodash/fp"
import { withStyles } from "@material-ui/core/styles"
import * as enums from "app/utils/enums"
import DestroyImageClassificationButton from "./DestroyImageClassificationButton"

const styles = theme => ({
  destroyButton: {
    background: "transparent",
    border: 0,
    cursor: "pointer",
    fontSize: 13,
    color: "#2f2f2f",
    textDecoration: "underline"
  },
  item: {
    display: "flex",
    flexWrap: "nowrap",
    margin: 5,
    maxWidth: "100%"
  },
  text: {
    background: "transparent",
    border: 0,
    cursor: "pointer",
    fontSize: 13,
    textAlign: "left",
    color: "#000000",
    textDecoration: "underline",
    flexGrow: 1,
    marginRight: 5,
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  selectedText: {
    color: "#4285f4"
  }
})

class ImageClassification extends Component {
  showConfirmDialog = confirm => event => {
    event.preventDefault()
    event.stopPropagation()
    confirm()
  }

  onClassificationSelected = classification => event => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClassificationSelected(classification)
  }

  showRegions(classification) {
    const regions = classification.regions || []
    return regions.map(r => r.name).join(", ")
  }

  render() {
    const { classes, classification, index, selected } = this.props

    return (
      <li className={classes.item}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button
          onClick={this.onClassificationSelected(classification)}
          className={cx({
            [classes.text]: true,
            [classes.selectedText]: selected
          })}
        >
          {`${index + 1}: ${compact([
            get("segment.name", classification),
            get("category.name", classification),
            get("gender.name", classification),
            get("topic.name", classification),
            get("trend.name", classification),
            get("product.name", classification),
            get("color.name", classification),
            get("material.name", classification),
            get("printType.name", classification),
            get("trimmingType.name", classification),
            this.showRegions(classification),
            get("brand.name", classification),
            t(enums.marketType[get("market", classification)])
          ]).join(", ")}`}
        </button>
        <DestroyImageClassificationButton id={classification.id}>
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          {confirm => (
            <button
              onClick={this.showConfirmDialog(confirm)}
              className={classes.destroyButton}
            >
              {t("imageClassifications.destroyButtonLabel")}
            </button>
          )}
          {/* eslint-enable jsx-a11y/anchor-is-valid */}
        </DestroyImageClassificationButton>
      </li>
    )
  }
}

export default withStyles(styles)(ImageClassification)

import gql from "graphql-tag"

export default gql`
  query ListUsers(
    $page: Int!
    $pageSize: Int
    $accountId: Int
    $search: String
  ) {
    users(
      page: $page
      pageSize: $pageSize
      admin: false
      accountId: $accountId
      search: $search
    ) {
      totalCount
      edges {
        node {
          id
          name
          email
          admin
          active
          clientVersion
          account {
            id
            name
          }
        }
      }
    }
  }
`

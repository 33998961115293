export default {
  fields: {
    name: "Nome",
    order: "Ordem"
  },
  listPage: {
    title: "Marcas/Lojas"
  },
  upsertPages: {
    createTitle: "Criar Marca/Loja",
    updateTitle: "Alterar Marca/Loja",
    duplicateNameErrorMessage: "Este nome já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar esta marca"
  }
}

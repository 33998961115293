import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "GeneralTrendType",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("generalTrendType", [
    "id",
    "name",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("generalTrendTypes", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createGeneralTrendType",
    "GeneralTrendTypeInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateGeneralTrendType",
    "GeneralTrendTypeInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteGeneralTrendType"),
  mergeMutation: graphqlUtils.mergeMutation("mergeGeneralTrendTypes")
}

import { t } from "i18next"
import { isAfter } from "date-fns"

function Publication({ value, currentDate = new Date() }) {
  if (!value || isAfter(new Date(value), new Date(currentDate))) {
    return t("imageFolders.fields.pending")
  }

  return t("imageFolders.fields.published")
}

export default Publication

import { t } from "i18next"
import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "app/common/AppBar"
import RemoteTable from "app/common/RemoteTable"
import { ImageFolder } from "app/entities"
import DateTimePrinter from "app/common/DateTimePrinter"
import Publication from "app/pages/imageFolders/Publication"
import ImageIdField from "./ImageIdField"

const styles = theme => ({
  table: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    minWidth: 0 // So the Typography noWrap works.
  }
})

const columns = [
  {
    header: t("imageFolders.fields.createdAt"),
    path: "createdAt",
    component: DateTimePrinter
  },
  { header: t("imageFolders.fields.id"), path: "id" },
  { header: t("imageFolders.fields.gallery"), path: "gallery.title" },
  { header: t("imageFolders.fields.createdBy"), path: "createdBy.name" },
  {
    header: t("imageFolders.fields.publication"),
    path: "publicationDate",
    component: Publication
  },
  {
    header: t("imageFolders.fields.publicationDate"),
    path: "publicationDate",
    component: DateTimePrinter
  }
]

function SelectImageFolder({ setView, imageIndex, classes }) {
  return (
    <Fragment>
      <AppBar
        title={t(
          "reports.editor.contentTypes.imageGrid.dialog.selectImageFolder.title"
        )}
        rightArea={
          <Button onClick={() => setView("ImageGrid")} color="inherit">
            {t("common.back")}
          </Button>
        }
      />
      <ImageIdField imageIndex={imageIndex} setView={setView} />
      <main className={classes.table}>
        <RemoteTable
          data-test="base-list-page"
          query={ImageFolder.listQuery}
          columns={[
            ...columns,
            {
              columnProps: { numeric: true },
              header: "",
              path: "id",
              component: ({ value: imageFolderId }) => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setView("SelectImage", { imageIndex, imageFolderId })
                  }
                >
                  {t(
                    "reports.editor.contentTypes.imageGrid.dialog.selectImageFolder.showImagesButton"
                  )}
                </Button>
              )
            }
          ]}
        />
      </main>
    </Fragment>
  )
}

export default withStyles(styles)(SelectImageFolder)

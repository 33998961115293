import { t } from "i18next"
import React, { Fragment } from "react"
import PageHeader from "app/common/PageHeader"
import SearchableRemoteTable from "app/common/SearchableRemoteTable"
import { WgsnFolder } from "app/entities"
import ImportState from "./ImportState"

function Index() {
  return (
    <Fragment>
      <PageHeader
        data-test="page-header"
        title={t("wgsnFolders.listPage.title")}
      />
      <SearchableRemoteTable
        query={WgsnFolder.listQuery}
        queryProps={{ pollInterval: 10000 }}
        columns={[
          { header: "ID", path: "id" },
          { header: t("wgsnFolders.fields.category"), path: "category" },
          { header: t("wgsnFolders.fields.city"), path: "city" },
          { header: t("wgsnFolders.fields.season"), path: "season" },
          { header: t("wgsnFolders.fields.brand"), path: "brand" },
          { header: t("wgsnFolders.fields.event"), path: "event" },
          {
            header: t("wgsnFolders.fields.quantity"),
            path: "imageCount",
            columnProps: { numeric: true }
          },
          {
            header: t("wgsnFolders.fields.importedBy"),
            path: "importedBy.name"
          },
          {
            header: null,
            component: ImportState
          }
        ]}
      />
    </Fragment>
  )
}

export default Index

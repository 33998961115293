import React, { Component } from "react"
import Mutation from "app/common/Mutation"
import { TechPackFile } from "app/entities"

class UploadTechPack extends Component {
  state = {
    loading: false
  }

  makeUploadTechPack = (upload, createTechPackFile) => async file => {
    this.setState({ loading: true })

    const {
      data: {
        techPackFileUploadUrl: { key, url, headers }
      }
    } = await upload({
      variables: {
        input: { filename: file.name || "temp", contentType: file.type }
      }
    })

    const fetchHeaders = {}
    headers.forEach(header => (fetchHeaders[header.key] = header.value))
    await fetch(url, {
      method: "PUT",
      body: file,
      headers: fetchHeaders
    })

    const { data } = await createTechPackFile({
      variables: { input: { key } }
    })

    this.setState({ loading: false })

    return data.createTechPackFile
  }

  render() {
    return (
      <Mutation mutation={TechPackFile.uploadUrl}>
        {upload => (
          <Mutation mutation={TechPackFile.createTechPackFile}>
            {createTechPackFile =>
              this.props.children(
                this.makeUploadTechPack(upload, createTechPackFile),
                { loading: this.state.loading }
              )
            }
          </Mutation>
        )}
      </Mutation>
    )
  }
}

export default UploadTechPack

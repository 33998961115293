export default {
  fields: {
    id: "ID",
    name: "Nome",
    email: "Email",
    password: "Senha",
    account: "Conta",
    active: "Ativo",
    clientVersion: "Versão do Site (Acesso)"
  },
  listPage: {
    title: "Usuários"
  },
  upsertPages: {
    createTitle: "Criar Usuário",
    updateTitle: "Alterar Usuário",
    duplicateEmailErrorMessage: "Este email já está cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este usuário"
  }
}

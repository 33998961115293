import { map, pick } from "lodash/fp"

function parseTextContent({ body }) {
  return { text: { body } }
}

function parseSectionTitleContent({ title }) {
  return { sectionTitle: { title } }
}

function parseColorChipContent({ description, color }) {
  return { colorChip: { description, color } }
}

function parseTechPackContent({ description, source, body }) {
  if (typeof source === "string") {
    return { text: { body: `<a href="${source}">${description}</a>` } }
  }
  return { text: { body } }
}

function parseVideoContent({ url, duration }) {
  return { video: { url, duration } }
}

function parseImageGridContent({ gridType, images }) {
  return {
    imageGrid: {
      gridType,
      images: images.map(pick(["imageFileId", "imageId", "caption"]))
    }
  }
}

function parseContent(content) {
  return {
    TEXT: parseTextContent,
    SECTION_TITLE: parseSectionTitleContent,
    COLOR_CHIP: parseColorChipContent,
    TECH_PACK: parseTechPackContent,
    IMAGE_GRID: parseImageGridContent,
    VIDEO: parseVideoContent
  }[content.contentType](content)
}

function transformContentIntoContentInput(values, fieldName = "contents") {
  return {
    ...values,
    [fieldName]: map(parseContent, values[fieldName]).filter(Boolean)
  }
}

export default transformContentIntoContentInput

import gql from "graphql-tag"

export default gql`
  query ListAdministrators($page: Int!, $pageSize: Int) {
    users(page: $page, pageSize: $pageSize, admin: true) {
      totalCount
      edges {
        node {
          id
          name
          email
          admin
        }
      }
    }
  }
`

import React, { Component, Fragment } from "react"
import { t } from "i18next"
import { debounce } from "lodash/fp"
import { withURLSearch } from "app/common/URLSearch"
import SearchInput from "app/common/SearchInput"
import RemoteTable from "app/common/RemoteTable"

export class SearchableRemoteTable extends Component {
  onSearchChange = debounce(100, value => {
    this.props.setSearch({ search: value || null })
  })

  render() {
    const {
      search: { search: value },
      setSearch,
      variables,
      ...restProps
    } = this.props

    return (
      <Fragment>
        <SearchInput
          defaultValue={value}
          onChange={this.onSearchChange}
          placeholder={t("common.searchFieldPlaceholder")}
        />
        <RemoteTable
          data-test="table"
          {...restProps}
          variables={{ ...variables, search: value }}
        />
      </Fragment>
    )
  }
}

export default withURLSearch(SearchableRemoteTable)

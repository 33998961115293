import * as graphqlUtils from "app/utils/graphql"
import translationsFragment from "../translationsFragment"

export default {
  name: "TradeShowEvent",
  labelKey: "name",
  getQuery: graphqlUtils.getQuery("tradeShowEvent", [
    "id",
    "name",
    "wgsnNames",
    translationsFragment
  ]),
  listQuery: graphqlUtils.listQuery("tradeShowEvents", ["id", "name"], {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createTradeShowEvent",
    "TradeShowEventInput"
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTradeShowEvent",
    "TradeShowEventInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTradeShowEvent"),
  mergeMutation: graphqlUtils.mergeMutation("mergeTradeShowEvents")
}

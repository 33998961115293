import { t } from "i18next"
import React, { Fragment, Component, createRef } from "react"
import StackGrid from "react-stack-grid"
import { get, pipe, map } from "lodash/fp"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Query from "app/common/Query"
import AppBar from "app/common/AppBar"
import Pagination from "app/common/Pagination"
import styled from "app/utils/styled"
import { Image } from "app/entities"
import Item from "./Item"

const PAGE_SIZE = 20

const LoadingIndicator = styled(CircularProgress, theme => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing.unit * 2
}))

const Content = styled("div", theme => ({
  paddingTop: theme.spacing.unit * 2
}))

const getPageCount = get("images.pageInfo.pageCount")

class SelectImage extends Component {
  gridRef = createRef()

  state = {
    page: 1
  }

  onPageChange = page => this.setState({ page })

  onItemLoad = () => {
    this.gridRef.current.updateLayout()
  }

  onBack = () => {
    const { setView, imageIndex } = this.props

    setView("SelectImageFolder", {
      imageIndex
    })
  }

  onItemSelect = image => {
    const { setView, imageIndex } = this.props

    setView("CropSelectedImage", {
      imageIndex,
      selectedImage: image
    })
  }

  renderItem = item => (
    <Item
      key={item.id}
      item={item}
      onLoad={this.onItemLoad}
      onSelect={this.onItemSelect}
    />
  )

  renderItems = pipe(
    get("images.edges"),
    map("node"),
    map(this.renderItem)
  )

  render() {
    const { imageFolderId } = this.props
    const { page } = this.state

    return (
      <Query
        query={Image.listQuery}
        variables={{
          page,
          pageSize: PAGE_SIZE,
          imageFolderId
        }}
      >
        {({ data, loading }) => (
          <Fragment>
            <AppBar
              title={t(
                "reports.editor.contentTypes.imageGrid.dialog.selectImage.title"
              )}
              afterTitle={
                loading && <LoadingIndicator data-test="loading-indicator" />
              }
              rightArea={
                <Button onClick={this.onBack} color="inherit">
                  {t("imageFolders.selectAnotherFolder")}
                </Button>
              }
            />
            {Boolean(data.images) && (
              <Content>
                <Pagination
                  currentPage={page}
                  onChange={this.onPageChange}
                  pageCount={getPageCount(data)}
                />
                <StackGrid
                  ref={this.gridRef}
                  columnWidth={300}
                  duration={0}
                  gutterWidth={10}
                  gutterheight={10}
                >
                  {this.renderItems(data)}
                </StackGrid>
              </Content>
            )}
          </Fragment>
        )}
      </Query>
    )
  }
}

export default SelectImage

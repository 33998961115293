import { map } from "lodash/fp"
import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { isImageLoaded } from "app/utils/dom"

const styles = {
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}

function resize(node) {
  let cancelled = false

  node.style.position = "absolute"
  node.style.width = "unset"
  node.style.overflow = "hidden"
  node.style.opacity = 0

  node.style.width = `${(node.offsetWidth * node.offsetHeight) /
    node.scrollHeight}px`

  /*
   * Since some values inside the node might be
   * fixed (and not a percentage of the width),
   * simply setting the width proportionally to
   * the height is not enough. The most secure way
   * to ensure that will have no scroll is to keep
   * decrementing the width until there is no
   * vertical scroll
   *
   * This uses setTimeout instead of a while loop
   * so it does not block the thread and the rendering
   * of the rest of the tree
   */
  // eslint-disable-next-line no-unmodified-loop-condition
  function doResize(callback) {
    if (cancelled) {
      return null
    }

    if (node.scrollHeight > node.offsetHeight) {
      node.style.width = `${node.offsetWidth - 1}px`
      return setImmediate(doResize, callback)
    }

    return callback()
  }

  doResize(() => {
    node.style.overflow = "unset"
    node.style.position = "unset"
    node.style.opacity = 1
  })

  return {
    cancel: () => {
      cancelled = true
    }
  }
}

/*
 * This component will ensure that its
 * contents will use all the available space
 * without generating horizontal or verical
 * scrollbars, the requirements for this to work
 * is that the parent node needs to be relative
 * positioned and the children's width must be
 * fluid
 */
class NoScroll extends Component {
  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    this.unmounted = true
    this.resizing.cancel()
    window.removeEventListener("resize", this.resize)
  }

  componentDidUpdate() {
    this.resize()
    this.checkImages()
  }

  checkImages() {
    Promise.all(map(isImageLoaded, this.node.querySelectorAll("img"))).then(
      this.resize
    )
  }

  resize = () => {
    if (this.unmounted) {
      return null
    }

    if (this.resizing) {
      this.resizing.cancel()
    }

    this.resizing = resize(this.node)
  }

  render() {
    return (
      <div
        className={this.props.classes.root}
        ref={ref => {
          this.node = ref
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles)(NoScroll)

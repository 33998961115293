export default {
  listPage: {
    title: "Publicações de Área Técnica"
  },
  upsertPages: {
    createTitle: "Criar Publicação de Área Técnica",
    updateTitle: "Alterar Publicação de Área Técnica",
    genericErrorMessage: "Houve um problema ao salvar este publicação"
  }
}

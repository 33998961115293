import React, { Component, Fragment } from "react"
import { compose } from "lodash/fp"
import { withProps } from "recompose"
import Dropzone from "react-dropzone"
import { withStyles } from "@material-ui/core/styles"
import { withConsumer as withTechPacksConsumer } from "../context"
import UploadDialog from "./Dialog"

const styles = theme => ({
  dropzoneAccept: {
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}09`
  },
  dropzoneReject: {
    borderColor: theme.palette.error.main,
    backgroundColor: `${theme.palette.error.main}09`
  }
})

export class UploadDropzone extends Component {
  state = {
    open: false,
    droppedFile: null,
    dialogInstance: 0
  }

  onDrop = files => {
    if (files && files.length) {
      this.setState({
        open: true,
        droppedFile: files[0],
        dialogInstance: this.state.dialogInstance + 1
      })
    }
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  onExited = () => {
    URL.revokeObjectURL(this.state.droppedFile.preview)

    this.setState({
      droppedFile: null
    })
  }

  render() {
    const { open, droppedFile, dialogInstance } = this.state
    const { techPackFolderId, children, classes, onTechPackAdded } = this.props

    return (
      <Fragment>
        <Dropzone
          data-test="dropzone"
          disableClick
          accept="image/*"
          onDrop={this.onDrop}
          acceptClassName={classes.dropzoneAccept}
          rejectClassName={classes.dropzoneReject}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column"
          }}
        >
          {children}
        </Dropzone>
        <UploadDialog
          data-test="dialog"
          key={
            dialogInstance
            /*
             * If we change the key here we don't need to
             * deal with props changing on the UploadDialog
             * and its children because we will have a new
             * instance every time new files are dropped
             */
          }
          open={open}
          droppedFile={droppedFile}
          techPackFolderId={techPackFolderId}
          onCancel={this.closeDialog}
          onCompleteTechPackUpload={this.closeDialog}
          onExited={this.onExited}
          onTechPackAdded={onTechPackAdded}
        />
      </Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withTechPacksConsumer,
  withProps(props => ({
    ...props.context
  }))
)(UploadDropzone)

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Tópicos"
  },
  upsertPages: {
    createTitle: "Criar Tópico",
    updateTitle: "Alterar Tópico",
    duplicateNameErrorMessage: "Tópico já cadastrado",
    genericErrorMessage: "Houve um problema ao salvar este tópico"
  }
}

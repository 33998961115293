export default {
  fields: {
    title: "Título",
    publicationDate: "Data de publicação",
    image: "Imagem",
    source: "Nome da fonte",
    sourceUrl: "Link da fonte"
  },
  listPage: {
    title: "O que você precisa saber"
  },
  upsertPages: {
    createTitle: "Criar O que você precisa saber",
    updateTitle: "Alterar O que você precisa saber",
    genericErrorMessage: "Houve um problema ao salvar o que você precisa saber"
  }
}

import { t } from "i18next"
import { omit } from "lodash/fp"
import DateTimePrinter from "app/common/DateTimePrinter"
import createCRUD from "app/utils/createCRUD"
import Form from "./Form"

const { ListPage, CreatePage, UpdatePage } = createCRUD({
  entity: "DailyImage",
  pathPreffix: "dailyImages",
  i18nKey: "dailyImages",
  listColumns: [
    {
      header: t("dailyImages.fields.publicationDate"),
      path: "publicationDate",
      component: DateTimePrinter
    },
    { header: t("dailyImages.fields.title"), path: "title" }
  ],
  upsertForm: Form,
  upsert: {
    parseOutput: omit("imageFile"),
    errorMessage: error =>
      error.message && error.message.includes("duplicate key")
        ? t("dailyImages.upsertPages.duplicateNameErrorMessage")
        : t("dailyImages.upsertPages.genericErrorMessage")
  }
})

export { ListPage, CreatePage, UpdatePage }

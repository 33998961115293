import { t } from "i18next"
import { identity } from "lodash/fp"
import React from "react"
import Buttons from "./Buttons"
import FormPage from "app/common/FormPage"

function CreatePage({
  parseOutput = identity,
  successMessage = t("createPage.defaultSuccessMessage"),
  errorMessage = t("createPage.defaultErrorMessage"),
  hasSaveAndPreview = false,
  ...props
}) {
  return (
    <FormPage
      {...props}
      parseVariables={values => ({ input: parseOutput(values) })}
      successMessage={successMessage}
      errorMessage={errorMessage}
      hasSaveAndPreview={hasSaveAndPreview}
      buttons={<Buttons />}
    />
  )
}

export default CreatePage

import React, { Component, createRef } from "react"
import StackGrid from "react-stack-grid"
import { withStyles } from "@material-ui/core/styles"
import Item from "./Item"
import UploadPlaceholder from "./UploadPlaceholder"

function uploadPlaceholderRenderer({ classes }) {
  return (
    <div>
      <div className={classes.uploadPlaceholderWrapper}>
        <UploadPlaceholder className={classes.uploadPlaceholder} />
      </div>
    </div>
  )
}

const styles = theme => ({
  uploadPlaceholderWrapper: {
    padding: 10,
    width: 300
  },
  uploadPlaceholder: {
    height: "100%",
    padding: "0 25px",
    borderRadius: 4,
    border: `1px dashed ${theme.palette.primary.light}`
  }
})

const UploadZone = withStyles(styles)(uploadPlaceholderRenderer)

class Gallery extends Component {
  gridRef = createRef()

  updateLayout = () => {
    this.gridRef.current.updateLayout()
  }

  render() {
    const { items, onClassificationSelected } = this.props
    const itemsToRender = [
      <UploadZone key={0} />,
      items.map(item => (
        <Item
          key={item.id}
          item={item}
          onClassificationSelected={onClassificationSelected}
          updateLayout={this.updateLayout}
        />
      ))
    ]

    return (
      <StackGrid
        ref={this.gridRef}
        columnWidth={300}
        duration={0}
        gutterWidth={10}
        gutterheight={10}
      >
        {itemsToRender}
      </StackGrid>
    )
  }
}

export default Gallery

import { t } from "i18next"

export default function getRows(data) {
  function addPercentage(row) {
    const percentage =
      data.totalCount > 0
        ? ((100 / data.totalCount) * row.count).toFixed(0)
        : null

    return {
      ...row,
      percentage
    }
  }

  function addOthersRow(rows) {
    const othersCount =
      data.totalCount - rows.reduce((acc, curr) => acc + curr.count, 0)

    return [
      ...rows,
      {
        color: "#04BD96",
        label: t("dashboard.imagesRows.others"),
        count: othersCount
      }
    ]
  }

  const rows = [
    {
      color: "#9383DC",
      label: t("dashboard.imagesRows.clipping"),
      count: data.CLIPPING
    },
    {
      color: "#FA6E91",
      label: t("dashboard.imagesRows.catwalk"),
      count: data.CATWALK
    },
    {
      color: "#55E1DE",
      label: t("dashboard.imagesRows.tradeshow"),
      count: data.TRADESHOW
    },
    {
      color: "#FFD16B",
      label: t("dashboard.imagesRows.showcase"),
      count: data.SHOWCASE
    },
    {
      color: "#6474D4",
      label: t("dashboard.imagesRows.street_style"),
      count: data.STREET_STYLE
    },
    {
      color: "#F9886A",
      label: t("dashboard.imagesRows.visual_merchandising"),
      count: data.VISUAL_MERCHANDISING
    }
  ]

  const imageRowsWithOthersRow = addOthersRow(rows)

  return imageRowsWithOthersRow.map(addPercentage)
}

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Eventos de Feira"
  },
  upsertPages: {
    createTitle: "Criar Evento de Feira",
    updateTitle: "Alterar Evento de Feira",
    genericErrorMessage: "Houve um problema ao salvar este evento de feira"
  }
}

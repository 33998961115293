import { t } from "i18next"
import React, { Fragment } from "react"
import Mutation from "app/common/Mutation"
import ConfirmationDialog from "app/common/ConfirmationDialog"
import { TechPackClassification } from "app/entities"

function DestroyFolderClassificationsButton({
  techPackFolderId,
  children,
  onDestroy
}) {
  return (
    <Mutation
      mutation={TechPackClassification.folderDeleteTechPackClassification}
    >
      {destroy => {
        return (
          <Fragment>
            <ConfirmationDialog
              onConfirm={async () => {
                await destroy({ variables: { techPackFolderId } })
                onDestroy()
              }}
              message={t(
                "techPackClassifications.destroyFolderClassificationsMessage"
              )}
            >
              {confirm => children(confirm)}
            </ConfirmationDialog>
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default DestroyFolderClassificationsButton

export default {
  fields: {
    name: "Nome"
  },
  listPage: {
    title: "Eventos de Desfile"
  },
  upsertPages: {
    createTitle: "Criar Evento de Desfile",
    updateTitle: "Alterar Evento de Desfile",
    genericErrorMessage: "Houve um problema ao salvar este evento de desfile"
  }
}

import * as graphqlUtils from "app/utils/graphql"
import techPacksQuery from "./techPacks"

const fields = [
  "createdAt",
  "id",
  "techPackCount",
  "publicationDate",
  ["booth", ["id", "name"]],
  ["brand", ["id", "name"]],
  ["catwalkEvent", ["id", "name"]],
  ["city", ["id", "name"]],
  ["createdBy", ["id", "name"]],
  ["gallery", ["id", "title", "type"]],
  ["season", ["id", "name"]],
  ["store", ["id", "name"]],
  ["storeType", ["id", "name"]],
  ["stylist", ["id", "name"]],
  ["tradeShowEvent", ["id", "name"]]
]

export default {
  name: "TechPackFolder",
  getQuery: graphqlUtils.getQuery("techPackFolder", [
    "id",
    "comments",
    "publicationDate",
    ["booth", ["id", "name"]],
    ["brand", ["id", "name"]],
    ["catwalkEvent", ["id", "name"]],
    ["city", ["id", "name"]],
    ["gallery", ["id", "title", "order", "type"]],
    ["season", ["id", "name"]],
    ["store", ["id", "name"]],
    ["storeType", ["id", "name"]],
    ["stylist", ["id", "name"]],
    ["tradeShowEvent", ["id", "name"]],
    ["tradeShowType", ["id", "name"]]
  ]),
  listQuery: graphqlUtils.listQuery("techPackFolders", fields, {
    search: "String"
  }),
  createMutation: graphqlUtils.createMutation(
    "createTechPackFolder",
    "TechPackFolderInput",
    fields
  ),
  updateMutation: graphqlUtils.updateMutation(
    "updateTechPackFolder",
    "TechPackFolderInput"
  ),
  destroyMutation: graphqlUtils.destroyMutation("deleteTechPackFolder"),
  techPacksQuery
}

import { t } from "i18next"
import React, { Fragment } from "react"
import {
  compose,
  flatten,
  map,
  pipe,
  uniq,
  differenceBy,
  isEmpty
} from "lodash/fp"
import { withProps } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import Button from "app/common/Button"
import Checkbox from "app/common/Checkbox"
import { MultiSelectConsumer } from "app/common/MultiSelectProvider"
import { withConsumer as withTechPacksConsumer } from "../context"
import DestroyTechPackButton from "../DestroyTechPackButton"
import DestroyClassificationsButton from "../DestroyClassificationsButton"
import DestroyFolderClassificationsButton from "../DestroyFolderClassificationsButton"
import CleanTechPackClassificationRegionsButton from "../CleanTechPackClassificationRegionsButton"

const styles = {
  checkAll: {
    color: "#747474"
  },
  deletedClassifications: {
    backgroundColor: "transparent",
    border: 0,
    color: "#747474",
    cursor: "pointer",
    fontSize: 13,
    textDecoration: "underline",
    marginLeft: 55
  },
  uncheckSelected: {
    backgroundColor: "transparent",
    border: 0,
    color: "#747474",
    cursor: "pointer",
    fontSize: 13,
    textDecoration: "underline",
    marginLeft: 55
  },
  topbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  topbarActions: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  }
}

export function collapseTechPackClassificationId(selectedItems) {
  return pipe(
    map("techPackClassifications"),
    flatten,
    map("id"),
    uniq
  )(selectedItems)
}

export function allTechPacksSelect(items, selectedItems) {
  if (isEmpty(items)) {
    return false
  }

  return differenceBy("id", items, selectedItems).length === 0
}

function Topbar({
  techPacks,
  toggleDrawer,
  classes,
  onTechPackDeleted,
  height,
  padding,
  queryVariables: { techPackFolderId },
  refetchTechPacks
}) {
  return (
    <MultiSelectConsumer>
      {({ items: selectedItems, clearItems, clearAll, addMany }) => {
        const allChecked = allTechPacksSelect(techPacks, selectedItems)
        const selectPage = () => addMany(techPacks)
        const uncheckPage = () => clearItems(techPacks)

        return (
          <div className={classes.topbar} style={{ height, padding }}>
            <div className={classes.topbarActions}>
              <Checkbox
                className={classes.checkAll}
                label={t("techPackClassifications.selectAllTechPacks", {
                  length: techPacks.length
                })}
                onChange={allChecked ? uncheckPage : selectPage}
                checked={allChecked}
              />
              {selectedItems.length > 0 ? (
                <Fragment>
                  <button
                    className={classes.uncheckSelected}
                    onClick={clearAll}
                  >
                    {t("techPackClassifications.unselectAllTechPacks", {
                      length: selectedItems.length
                    })}
                  </button>
                  <DestroyClassificationsButton
                    ids={collapseTechPackClassificationId(selectedItems)}
                  >
                    {confirm => (
                      <button
                        className={classes.deletedClassifications}
                        onClick={confirm}
                      >
                        {t("techPackClassifications.removeSelected")}
                      </button>
                    )}
                  </DestroyClassificationsButton>
                </Fragment>
              ) : (
                <Fragment>
                  <DestroyFolderClassificationsButton
                    techPackFolderId={techPackFolderId}
                    onDestroy={refetchTechPacks}
                  >
                    {confirm => (
                      <button
                        className={classes.deletedClassifications}
                        onClick={confirm}
                      >
                        {t("techPackClassifications.removeFromWholeFolder")}
                      </button>
                    )}
                  </DestroyFolderClassificationsButton>
                </Fragment>
              )}
            </div>
            {selectedItems.length > 0 ? (
              <div className={classes.topbarActions}>
                <DestroyTechPackButton
                  ids={map("id", selectedItems)}
                  onTechPackDeleted={onTechPackDeleted}
                >
                  {confirm => (
                    <Button
                      text={
                        selectedItems.length > 1
                          ? t(
                              "techPackClassifications.destroyTechPacksButtonLabel"
                            )
                          : t(
                              "techPackClassifications.destroyTechPackButtonLabel"
                            )
                      }
                      onClick={confirm}
                    />
                  )}
                </DestroyTechPackButton>
                <CleanTechPackClassificationRegionsButton
                  ids={map("id", selectedItems)}
                  onUpdate={refetchTechPacks}
                >
                  {confirm => (
                    <Button
                      text={t(
                        "techPackClassifications.cleanRegionsButtonLabel"
                      )}
                      onClick={confirm}
                    />
                  )}
                </CleanTechPackClassificationRegionsButton>
                <Button
                  text={t("techPackClassifications.classify")}
                  onClick={toggleDrawer(true)}
                />
              </div>
            ) : (
              <Button
                text={t("techPackClassifications.classifyWholeFolder")}
                onClick={toggleDrawer(true, { classifyingAllFolder: true })}
              />
            )}
          </div>
        )
      }}
    </MultiSelectConsumer>
  )
}

export default compose(
  withStyles(styles),
  withTechPacksConsumer,
  withProps(props => ({ ...props.context }))
)(Topbar)

import { t } from "i18next"
import React, { Fragment } from "react"
import TextField from "app/common/formFields/Text"
import * as validations from "app/utils/validations"
import ImageFileField from "app/common/formFields/ImageFile"
import DateField from "app/common/formFields/Date"

function Form() {
  return (
    <Fragment>
      <DateField
        name="publicationDate"
        label={t("dailyContent.fields.publicationDate")}
        validate={validations.required()}
      />
      <TextField
        name="title"
        label={t("dailyContent.fields.title")}
        validate={validations.required()}
      />
      <TextField
        name="source"
        label={t("dailyContent.fields.source")}
        validate={validations.required()}
      />
      <TextField
        name="sourceUrl"
        label={t("dailyContent.fields.sourceUrl")}
        validate={validations.required()}
      />
      <ImageFileField
        aspectRatio={270 / 270}
        initialSourceFieldName="imageFile.source"
        name="imageFileId"
        label={t("dailyContent.fields.image")}
        validate={validations.required()}
      />
    </Fragment>
  )
}

export default Form

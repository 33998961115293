import { isEqual } from "lodash/fp"
import React from "react"
import BaseField from "app/common/formFields/Base"
import ComponentLifecycle from "app/common/ComponentLifecycle"

function SetField({ name, value }) {
  return (
    <BaseField name={name} subscription={{ value: true }}>
      {({ input: { value: currentValue, onChange } }) => {
        const update = () =>
          isEqual(currentValue, value) ? null : onChange(value)

        return (
          <ComponentLifecycle
            componentDidMount={update}
            componentDidUpdate={update}
          />
        )
      }}
    </BaseField>
  )
}

export default SetField
